<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12Z" fill="currentColor" />
<path d="M19.5 19.5V7.625L13.875 2H7C5.61929 2 4.5 3.11929 4.5 4.5V19.5C4.5 20.8807 5.61929 22 7 22H17C18.3807 22 19.5 20.8807 19.5 19.5ZM13.875 5.75C13.875 6.78553 14.7145 7.625 15.75 7.625H18.25V19.1941C18.25 19.1941 17 17 12 17C7 17 5.75 19.1943 5.75 19.1943V4.5C5.75 3.80964 6.30964 3.25 7 3.25H13.875V5.75Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: "KampusMerdeka",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};

</script>