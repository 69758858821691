<template>
<div class="container-modal">
    <div class="content" :style="`width: ${width}`">
        <slot />
    </div>
</div>    
</template>

<script>
export default {
    name: "SideModal",
    props: {
        width: {
            type: String,
            default: "450px"
        }
    }
}
</script>

<style scoped>
.container-modal {
    position: fixed;
    width: 100vw;
    z-index: 4;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: rgb(59, 54, 54, 0.5);
    display: flex;
    justify-content: flex-end;
}
.container-modal .content {
    overflow-y: auto;
    padding: 20px 25px;
    background-color: white;
    border-radius: 28px 0 0 28px;
}
</style>