<template>
  <div
    v-if="show"
    style="
      position: absolute;
      background-color: white;
      top: 80px;
      padding: 30px;
      border-radius: 10px;
      box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.2);
      z-index: 999;
      width: 341px;
    "
  >
    <!-- Content -->
    <div style="display: flex; flex-direction: column; gap: 27px">
      <!-- Filter Type 1 -->
      <div
        style="display: flex; flex-direction: column; gap: 27px"
        v-for="(filterData, index) in filterDatas"
        :key="index"
      >
        <!-- Title -->
        <div
          style="color: #36373f; font-weight: 600; text-transform: capitalize"
        >
          {{ filterData.title }}
        </div>
        <!-- Button -->
        <v-row style="gap: 5px">
          <!-- Hit Banner Button -->
            <Button
              @click="updateFilter(filterData.name, item.value)"
              v-for="(item, index) in filterData.items"
              :class="
                filters[filterData.name] == item.value
                  ? 'active'
                  : '' + 'filter-button'
              "
              :name="item.name"
              :key="index"
            />
        </v-row>
      </div>
      <!-- Apply Button -->
      <Button
        name="Apply"
        width="100%"
        style="
          margin-top: 14px;
          background-color: #3b54d9;
          color: white;
          border-radius: 20px;
        "
        @click="applyFilter"
      />
    </div>
  </div>
</template>

<style scoped>
.filter-button {
  background-color: #fafaf9;
  color: #575966;
  border: 10px;
}

.active {
  background-color: #54b7ff;
  color: #ffffff;
  border-radius: 10px;
}

.active:hover {
  background-color: #54b7ff;
  color: #ffffff;
  border-radius: 10px;
}
</style>

<script>
import Button from "@/components/Button.vue";
export default {
  name: "FilterModal",
  props: {
    show: Boolean,
    filterDatas: [],
    filters: {},
  },
  components: {
    Button,
  },
  methods: {
    applyFilter() {
      this.$emit("apply");
    },
    updateFilter(filterName, value) {
      if (this.filters[filterName] == value) {
        value = null;
      }
      this.$emit("updateFilter", { name: filterName, value });
    },
  },
};
</script>
