<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="40%"
    >
      <h2 class="modal-title" style="font-weight: bolder">
        Detail User Aktif
      </h2>
      <br />

      <div class="modal-body">
        <div class="data-list">
          <div
            class="detail-data-1"
            style="
              width: 100px;
              border: 1px solid #c3c3c6;
              text-align: left;
              border-radius: 10px;
            "
          >
            <p class="detail-label">Title</p>
            <p class="detail-value">{{ userActiveDetail.gender }}</p>
          </div>
          <div
            class="detail-data-2"
            style="
              width: 500px;
              border: 1px solid #c3c3c6;
              text-align: left;
              border-radius: 10px;
            "
          >
            <p class="detail-label">Nama Lengkap</p>
            <p class="detail-value">{{ userActiveDetail.full_name }}</p>
          </div>
        </div>

        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">No.Handphone</p>
            <p class="detail-value">
              (+62) {{ userActiveDetail.phone_number }}
            </p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Email</p>
            <p class="detail-value">{{ userActiveDetail.email }}</p>
          </div>
        </div>

        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">Tanggal Lahir</p>
            <p class="detail-value">(+62) {{ userActiveDetail.birthdate }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Roles</p>
            <p class="detail-value">{{ userActiveDetail.role }}</p>
          </div>
        </div>
      </div>
    </Modal>

    <div class="top-container-box">
      <h1>User aktif</h1>
      <div>
        <Button
          icon="+"
          outline
          name="Tambah User Baru"
          style="background-color: #3b54d9; color: #ffffff; width: 270px"
          @click="() => $router.push('/user/new')"
        />
      </div>
    </div>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1 mt-10 pa-5"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-5">
          <v-col class="d-flex align-center">
            <div class="ctn">
              <div class="f-search">
                <!-- Search Field -->
                <v-text-field
                  v-model="filter.search"
                  placeholder="Cari akun..."
                  clearable
                  dense
                  flat
                  solo
                  prepend-inner-icon="mdi-magnify"
                  style="
                    border-radius: 14px;
                    max-width: 448px;
                    margin-right: 20px;
                  "
                ></v-text-field>
              </div>

              <div class="btn-filter-horizontal">
                <Button
                  name="TERAPKAN FILTER"
                  width="200px"
                  @click="applyFilters"
                />
              </div>
              <div v-if="isFilterActive" class="btn-filter-horizontal">
                <Button
                  class="reset-filter"
                  @click="clearFilter()"
                  name="RESET"
                />
              </div>
            </div>
          </v-col>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="actions">
          <p small @click="editUser(item)">EDIT</p>
          <p small @click="viewUser(item)" class="text">LIHAT</p>
        </div>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getUsers"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NoDataAlert from "@/components/NoDataAlert.vue";
import Button from "@/components/Button.vue";
import Loading from "@/components/ilustration/Loading.vue";
import Modal from "@/components/Modal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isLoading: false,
      filter: {
        search: "",
      },
      date: "",
      isModal: false,
      isModalDetail: false,
      isFilterActive: false,
      message: "",
      isPopup: false,
      // showResetButton: false,
      currentPage: 1,
      total: 0,
      // userTypes: [],
      headers: [
        {
          text: "NO",
          value: "id",
          //   width: "1"
        },
        { text: "Title", value: "gender" },
        { text: "Nama ", value: "full_name" },
        { text: "No. Telp ", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Tgl. Lahir", value: "birthdate" },
        { text: "Roles", value: "role", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ],
      users: [],
      // filteredUsers: []
    };
  },
  components: {
    NoDataAlert,
    Button,
    Loading,
    Modal,
    Popup
  },
  //   created() {
  //   this.filteredUsers = this.users;
  // },
  methods: {
    async getUsers() {
      //   var endpoint =
      //     "/v1/user?is_active=true&per_page=10&page=" + this.currentPage + "&keyword=" + this.filter.search;
      //   // this.spin_load.val = true
      //   await this.$crm_http
      await this.$crm_http
        .get(`v1/user?is_active=true&per_page=10&page=${this.currentPage}&keyword=${this.filter.search}`)
        .then((response) => {
          this.users = response.data.data.users;
          this.total = parseInt(response.data.data.pagination.total_page);
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    editUser(item) {
      this.$router.push("/user/edit/" + item.id);
    },
    viewUser(user) {
      this.userActiveDetail = user;
      this.isModalDetail = true;
    },
    applyFilters() {
      this.currentPage = 1;
      console.log("filter:", this.filter.search);
      // this.checkFilter();
      this.getUsers();
      this.isFilterActive = true;
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        search: "",
      };
      this.getUsers();
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
<style scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-list {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
}

.detail-data {
  width: 100%;
  text-align: left;
  border: 1px solid #c3c3c6;
  border-radius: 10px;
  height: min-content;
}

.detail-label {
  color: #c3c3c6;
  font-size: small;
  margin-left: 10px;
  margin-top: 10px;
}
.detail-value {
  margin-left: 10px;
  font-size: 15px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.ctn {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.f-search {
  border: 1px solid #d3d6e1;
  height: 40px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.f-date {
  border: 1px solid #d3d6e1;
  height: 42px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.actions {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  font-weight: bold;
}

.text {
  color: blue;
}
.reset-filter {
  cursor: pointer;
  color: #acafbf !important;
  background-color: white !important;
  border: 1px solid #acafbf;
}
</style>
