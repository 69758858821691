<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 11C13.0711 11 14.75 9.32107 14.75 7.25C14.75 5.17893 13.0711 3.5 11 3.5C8.92893 3.5 7.25 5.17893 7.25 7.25C7.25 9.32107 8.92893 11 11 11ZM13.5 7.25C13.5 8.63071 12.3807 9.75 11 9.75C9.61929 9.75 8.5 8.63071 8.5 7.25C8.5 5.86929 9.61929 4.75 11 4.75C12.3807 4.75 13.5 5.86929 13.5 7.25Z"
      fill="currentColor"
    />
    <path
      d="M18.5 17.25C18.5 18.5 17.25 18.5 17.25 18.5H4.75C4.75 18.5 3.5 18.5 3.5 17.25C3.5 16 4.75 12.25 11 12.25C17.25 12.25 18.5 16 18.5 17.25ZM17.25 17.2456C17.2482 16.9372 17.0578 16.0131 16.2099 15.1651C15.3945 14.3498 13.8614 13.5 11 13.5C8.1386 13.5 6.60544 14.3498 5.79012 15.1651C4.94219 16.0131 4.75178 16.9372 4.75 17.2456H17.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'InputIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
