<template>
    <div :class="`alert-content ${isError ? 'a-error' : 'a-success'}`">
        <div class="alert-content-message">
            <warning v-if="isError" />
            <success v-else />
            <span style="width: 110%;">{{ message }}</span>
        </div>
        <div style="cursor: pointer;" @click="$emit('close')">
            <Close />
        </div>
    </div>
</template>

<script>
import Warning from '../icons/Warning.vue';
import Close from '../icons/Close.vue';
import Success from '../icons/Success.vue';
export default {
    name: "Popup",
    props: {
        message: {
            type: String,
            default: ""
        },
        isError: {
            type: Boolean,
            default: true
        }
    },  
    components: {
        Warning, Close, Success
    }
}
</script>

<style scoped>
.alert-content {
    display: flex;
    gap: 15px;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;
    padding: 12px 28px;
    top: 10px;
    border-radius: 20px 20px 20px 20px;
    right: 10px;
    z-index: 99;
}
.a-error {
    background-color: #F7E7E6;
    color: #B80F0A;
}
.a-success {
    color: #01A368 !important;
    background-color: #E5F5EF;
}
.alert-content-message {
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 25px;
    gap: 20px;
}
@media screen and (max-width: 800px) {
    .alert-content {
        width: 100%;
        padding: 15px;
    }
    .alert-content-message {
        font-size: 14px;
    }
}
</style>
