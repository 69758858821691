<template>
  <div class="ma-10">
    <h1>Assesment</h1>

    <div class="container-box mt-10 filter-box input">
      <Prakerja />
    </div>

    <!-- <div class="container-box mt-10 filter-box">
      <div class="input-group">
        <span class="label">Cari</span>

        <div class="action-head">
          <div class="f-search">
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
            <input
              v-model="filter.email"
              type="text"
              class="f-input"
              placeholder="Cari berdasarkan email"
            />
          </div>
        </div>
      </div>

      <div class="input-group">
        <span class="label">Urutkan</span>

        <v-select
          label="Urutkan dari"
          v-model="filter.sort_by"
          :items="sortByList"
          :item-text="'name'"
          :item-value="'value'"
          hide-details="auto"
          class="dropdown"
          solo
        ></v-select>
      </div>

      <div class="input-group btn">
        <Button name="Terapkan Filter" width="200px" @click="getAssesments" />
      </div>
    </div> -->

    <v-row>
      <!-- Search  -->
      <v-col>
        <div class="action-head">
          <div class="f-search">
            <!-- Input -->
            <div>
              <v-text-field
                v-model="filter.name"
                type="text"
                class="f-input"
                placeholder="Search..."
                clearable
                dense
                flat
                solo
                style="
                  padding-top: 5px;
                  padding-left: 5px;
                  width: 448px;
                  border-radius: 14px;
                "
                @input="applyFilters"
              ></v-text-field>
            </div>
            <!-- Icon -->
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
          </div>
        </div>
      </v-col>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            margin-top: 7px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="assesments"
      :search="search"
      class="elevation-1 mt-10 table"
      :items-per-page="-1"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="editedItem.email"
                    label="Email"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.full_name"
                    label="Fullname"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.class_name"
                    label="Class Name"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.pre_test_score"
                    type="number"
                    label="Pre Test Score"
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.quiz_score"
                    type="number"
                    label="Quiz Score"
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.post_test_score"
                    type="number"
                    label="Post Test Score"
                  ></v-text-field>

                  <v-textarea
                    v-model="editedItem.notes"
                    label="Notes"
                  ></v-textarea>

                  <v-checkbox
                    v-model="editedItem.is_completed"
                    label="Is Completed"
                  ></v-checkbox>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- <UpdateDeleteAssesments v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <div class="data-count" v-if="total">
      <span
        ><b
          >{{ pageNo(assesments[assesments.length - 1]) || 0 }} dari
          {{ total }} </b
        >data yang tertampil</span
      >
    </div>

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            total-visible="8"
            :length="totalPage"
            @input="getAssesments"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getAuthentification from "@/utils/badrequest.js";
import Prakerja from "@/components/Assesment/Prakerja";
import NoDataAlert from "@/components/NoDataAlert.vue";
import EditTable from "@/components/icons/EditTable.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";

export default {
  components: {
    Prakerja,
    NoDataAlert,
    EditTable,
    FilterIcon,
    FilterModal,
  },
  data() {
    return {
      search: "",
      email: "",
      modal: false,
      currentPage: 1,
      perPage: 10,
      totalPage: 0,
      value: "email",
      total: 0,
      dialog: false,
      filter: {
        email: "",
        sort_by: "",
      },
      headers: [
        {
          text: "No",
          value: "no",
        },
        {text: "Email", value: "email"},
        {text: "Fullname", value: "full_name"},
        {text: "Class Name", value: "class_name", sortable: false},
        {text: "Pre Test Score", value: "pre_test_score", sortable: false},
        {text: "Quiz Score", value: "quiz_score", sortable: false},
        {text: "Post Test Score", value: "post_test_score", sortable: false},
        {text: "Notes", value: "notes", sortable: false},
        {text: "Is Completed", value: "is_completed", sortable: false},
        {text: "Actions", value: "actions", sortable: false},
      ],
      assesments: [],
      editedIndex: -1,
      editedItem: {
        user_id: "",
        class_id: "",
        email: "",
        full_name: "",
        class_name: "",
        pre_test_score: 0,
        quiz_score: 0,
        post_test_score: 0,
        notes: "",
        is_completed: false,
      },
      defaultItem: {
        user_id: "",
        class_id: "",
        email: "",
        full_name: "",
        class_name: "",
        pre_test_score: 0,
        quiz_score: 0,
        post_test_score: 0,
        notes: "",
        is_completed: false,
      },
      showFilterModal: false,
      filterModal: [
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create New Assesment"
        : "Edit Assesment";
    },
  },
  methods: {
    applyFilters() {
      this.getAssesments();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getAssesments() {
      await this.$lms_http
        .get(
          "/v1/user-assesment/dashboard?page=" +
            this.currentPage +
            "&limit=30&email=" +
            this.email
        )
        .then((response) => {
          this.assesments = [];
          var id = 1;
          response.data.data.assesments.forEach((element) => {
            this.assesments.push({
              id: id++,
              assesment_id: element.assesment_id,
              class_id: element.class_id,
              email: element.email,
              full_name: element.full_name,
              class_name: element.class_name,
              gender: element.gender,
              is_completed: element.is_completed,
              notes: element.notes,
              pre_test_score: element.pre_test_score,
              quiz_score: element.quiz_score,
              post_test_score: element.post_test_score,
              user_id: element.user_id,
              phone_number: element.phone_number,
            });
          });
          const t = parseInt(response.data.data.total);
          this.total = t / 30 + (t % 30 === 0 ? 0 : 1);
          this.totalPage = t;
          let no = (this.currentPage - 1) * this.perPage + 1;
          this.assesments = this.assesments.map((item) => {
            item.no = no++;
            return item;
          });
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    async updateAssesment() {
      // this.spin_load.val = true
      await this.$http
        .post("/v1/lms/assesment", {
          id: this.editedItem.assesment_id,
          user_id: this.editedItem.user_id,
          class_id: this.editedItem.class_id,
          pre_test_score: parseInt(this.editedItem.pre_test_score),
          quiz_score: parseInt(this.editedItem.quiz_score),
          post_test_score: parseInt(this.editedItem.post_test_score),
          notes: this.editedItem.notes,
          is_completed: this.editedItem.is_completed,
        })
        .then(() => {
          this.getAssesments();
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    pageNo(item) {
      if (item) {
        return item.no;
      }
      return;
    },
    editItem(item) {
      this.editedIndex = this.assesments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    save() {
      if (this.editedIndex > -1) {
        //update assesments
        console.log(this.editedItem.assesment_id);
        this.updateAssesment(this.editedItem.assesment_id);
        Object.assign(this.assesments[this.editedIndex], this.editedItem);
      }
      this.close();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getAssesments();
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.filter-box.input * {
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group .label {
  font-size: larger;
  color: #000000;
}

.input-group.btn {
  margin-left: auto;
  justify-content: flex-end;
}

.dropdown {
  width: 70%;
  height: 7%;
  border-radius: 14px !important;
  background-color: transparent !important;
}

.action-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.data-count {
  height: 0;
  position: relative;
  overflow-y: visible;
  display: flex;
}

.data-count span {
  position: absolute;
  font-size: medium;
  top: 35px;
}
</style>
