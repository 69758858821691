<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah kamu yakin ingin menghapus logo ini?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button name="YA, HAPUS DATA" width="100%" @click="onDelete" />
        <Button
          name="TIDAK, KEMBALI"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
      </div>
    </Modal>
    <div class="top-container-box">
      <h1>Main Logo</h1>
      <div>
        <Button
          icon="+"
          outline
          name="Buat Logo"
          @click="() => $router.push('/logo/new')"
        />
      </div>
    </div>
    <div class="container-box">
      <v-row>
        <v-col>
          Catgeory
          <v-select
            style="width: 70%; height: 7%; margin-top: 5px"
            v-model="filter.category"
            :items="category_list"
            :item-text="'name'"
            :item-value="'value'"
            class="form-input"
            solo
            @input="updateIsFilterActive"
          ></v-select>
        </v-col>
        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 25px;
          "
        >
          <Button
            name="RESET FILTER"
            outline
            @click="clearFilter"
            v-if="isFilterActive"
          />
          <Button name="TERAPKAN FILTER" @click="onFilter" width="190px" />
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :headers="headers"
      :items="logos"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <span :style="`color: ${item.is_active ? 'green' : 'red'}`">{{
          item.is_active ? "Aktif" : "Non aktif"
        }}</span>
      </template>
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getLogo"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{ Math.floor((currentPage - 1) * 10 + logos.length) }} dari
        {{ totalData }}</b
      >
      logo yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import Modal from "../../../components/Modal.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import EditTable from "../../../components/icons/EditTable.vue";
import TrashTable from "@/components/icons/TrashTable.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      message: "",
      isPopup: false,
      isFilterActive: false,
      filter: {
        category: "",
      },
      category_list: [
        {
          name: "Corporate",
          value: "CORPORATE",
        },
        {
          name: "Corporate Training",
          value: "TRAINING",
        },
        {
          name: "Corporate Collaboration",
          value: "COLLABORATION",
        },
        {
          name: "Client",
          value: "CLIENT",
        },
        {
          name: "Mitra",
          value: "MITRA",
        },
        {
          name: "Talent Service",
          value: "TALENT_SERVICE",
        },
        {
          name: "Technology Service",
          value: "SERVICES",
        },
      ],
      headers: [
        {
          text: "NO",
          value: "id",
        },
        { text: "Nama", value: "name" },
        { text: "KATEGORI", value: "category" },
        { text: "STATUS", value: "is_active" },
        { text: "URUTAN", value: "seq_no" },
        { text: "AKSI", value: "actions", sortable: false },
      ],
      logos: [],
    };
  },
  components: {
    Button,
    Modal,
    Loading,
    EditTable,
    TrashTable,
    Popup
  },
  methods: {
    async getLogo() {
      await this.$web_http
        .get(
          `/v1/logo?category=${this.filter.category || ""}&page=${
            this.currentPage
          }&per_page=10`
        )
        .then((response) => {
          this.logos = response.data.data.logos;
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    editItem(item) {
      this.$router.push("/logo/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$web_http.delete("/v1/logo/" + this.id);
        this.getLogo();
        this.isModal = false;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    onFilter() {
      this.currentPage = 1;
      this.isFilterActive = true;
      this.getLogo();
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        category: "",
      };
      this.getLogo();
    },
    handleClearButtonClick() {
      this.filter = {
        category: "",
      };
      this.updateIsFilterActive();
    },
    updateIsFilterActive() {
      this.isFilterActive = !!this.filter.category;
      // this.isFilterActive = true;
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getLogo();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>