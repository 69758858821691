<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3333 19.3333C27.1743 19.3333 28.6667 17.8409 28.6667 16C28.6667 14.159 27.1743 12.6666 25.3333 12.6666C23.4924 12.6666 22 14.159 22 16C22 17.8409 23.4924 19.3333 25.3333 19.3333Z"
      fill="#7B7E8C"
    />
    <path
      d="M15.9993 19.3333C17.8403 19.3333 19.3327 17.8409 19.3327 16C19.3327 14.159 17.8403 12.6666 15.9993 12.6666C14.1584 12.6666 12.666 14.159 12.666 16C12.666 17.8409 14.1584 19.3333 15.9993 19.3333Z"
      fill="#7B7E8C"
    />
    <path
      d="M6.66634 19.3333C8.50729 19.3333 9.99967 17.8409 9.99967 16C9.99967 14.159 8.50729 12.6666 6.66634 12.6666C4.82539 12.6666 3.33301 14.159 3.33301 16C3.33301 17.8409 4.82539 19.3333 6.66634 19.3333Z"
      fill="#7B7E8C"
    />
  </svg>
</template>

<script>
export default {
  name: "TrashIcon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
  },
};
</script>
