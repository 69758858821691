<template>
    <div class="ma-10">
        <div class="header-info">
      <div class="career-top">
        <span class="yellow-text font-poppins" @click="$router.push('/career-application')">Karir</span>
        <div>
          <Arrow :width="20" :height="20" direct="left" strokeColor="white" class="icon" />
        </div>
        <span class="font-poppins">Riwayat Karir</span>
      </div>
    </div>
        <div class="ctn-head mt-5">
            <h2 style="font-weight: bolder;">RIWAYAT KARIR (JOB APPLICANTS)</h2>
        </div>
        <div class="container-box mt-10" style="padding: 35px 25px">
            <v-row gap="4">
                <v-col cols="3.5">
                    <div class="action-head">
                        <div class="f-search">
                            <div class="f-search-ic">
                                <img src="/img/icons/search.png" alt="Search Ic" />
                            </div>
                            <div style="width: 90%">
                                <v-text-field v-model="filter.position" type="text" class="f-input"
                                    placeholder="Cari Pekerjaan" dense flat solo></v-text-field>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols='3.5'>
                    <date-picker class="my-datepicker" v-model="filter.date" placeholder="Date time" valueType="format" range></date-picker>
                </v-col>
                <v-col cols='2'>
                    <v-select outlined color="#ACAFBF" placeholder="Tipe Pekerjaan" v-model="filter.work_type"
                        :items="workTypeList" :item-text="'name'" :item-value="'value'"
                        style="border-radius: 10px !important; background-color: white" hide-details />
                </v-col>
                <v-col cols='2'>
                    <v-select outlined color="#ACAFBF" placeholder="Level Pekerjaan" v-model="filter.level"
                        :items="levelList" :item-text="'name'" :item-value="'value'"
                        style="border-radius: 10px !important; background-color: white" hide-details />
                </v-col>
                <v-col cols='2'>
                    <Button name="TERAPKAN" width="100%" height="54px" @click="() => getCareers()" />
                </v-col>
            </v-row>
            <div class="list-career">
          <div class="list-career-ctn" v-for="(data, idx) in careers" :key="idx" @click="$router.push(`/career-application/${data.id}?history=true`)">
            <h3 class="mb-3">{{ data.position }}</h3>
            <p class="text-limit">{{ data.company }}</p>
            <div style="height: 44px;">
                <p class="text-limit" style="font-weight: 400; -webkit-line-clamp: 2;">{{ data.location }}</p>
            </div>
            <div class="box-total">
                <p><b>{{ data.applied_total }}</b></p>
                <p style="font-size: 12px">Pelamar</p>
            </div>
            <div class="career-data">
              <div class="label">
                <div style="background-color: #f1f6ff" v-if="data.contract_type">
                  <clock-ic width="18px" height="18px" />
                  <p>{{ data.contract_type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="data.work_type">
                  <location-ic width="18px" height="18px"  />
                  <p>{{ data.work_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="data.level">
                  <level-ic width="18px" height="18px"  />
                  <p>{{ data.level }}</p>
                </div>
              </div>
            </div>
            <p class="mt-4">Batas Lamar <b style="font-family: 'Poppins';">{{ moment(data.closed_at) }}</b></p>
          </div>
        </div>
        </div>
    </div>
</template>


<script>
import Button from '@/components/Button.vue';
import DatePicker from 'vue2-datepicker';
import ClockIc from '@/components/icons/ClockLv.vue';
import LevelIc from '@/components/icons/Level.vue';
import LocationIc from '@/components/icons/Location.vue';
import Arrow from '@/components/icons/Arrow.vue';

import moment from 'moment';
import 'vue2-datepicker/index.css';

export default {
    name: "CareerAppList",
    components: {
        DatePicker,
        Button,
        ClockIc,
        LevelIc,
        LocationIc,
        Arrow
    },
    data() {
        return {
            filter: {
                position: "",
                work_type: "",
                level: "",
                start_date: "",
                end_date: "",
                date: [],
            },
            careers: [],
            currentPage: 1,
            total: 0,
            workTypeList: [
                {
                    name: "Full-Time",
                    value: "Full-Time"
                },
                {
                    name: "Part-Time",
                    value: "Part-Time"
                },
                {
                    name: "Freelance",
                    value: "Freelance"
                },
                {
                    name: "Internship",
                    value: "Internship"
                },
            ],
            levelList: [
                {
                    name: "Head",
                    value: "Head"
                },
                {
                    name: "Senior",
                    value: "Senior"
                },
                {
                    name: "Middle",
                    value: "Middle"
                },
                {
                    name: "Junior",
                    value: "Junior"
                },
            ],
        }
    },
    methods: {
        async getCareers() {
            this.filter.start_date = this.filter.date[0] || ""
            this.filter.end_date = this.filter.date[1] || ""
      await this.$crm_http
        .get(`/v1/career/total-applied?position=${this.filter.position}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&level=${this.filter.level}&work_type=${this.filter.work_type}&is_active=false&page=${this.currentPage}&per_page=12`)
        .then((response) => {
           this.careers = response.data.data.careers
          const t = parseInt(response.data.data.total);
          this.total = Math.floor(t / 10 + (t % 10 === 0 ? 0 : 1));
        })
        .catch((err) => console.error(err.response));
    },
    moment(date) {
      return moment(date).format("DD MMMM YYYY")
    },
    },
    mounted() {
        this.getCareers() 
    }
}
</script>

<style scoped>
.ctn-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.action-head {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-datepicker {
    width: 100%;
}

.my-datepicker ::v-deep .mx-input {
    height: 54px !important;
    border-radius: 12px !important;
}

.f-search {
    border: 1px solid #d3d6e1;
    width: 100%;
    height: 55px;
    gap: 10px;
    display: flex;
    border-radius: 10px;
    background-color: white;
    padding-left: 20px;
    margin-top: -7px;
}

.f-search-ic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25px;
}

.f-input {
    width: 100%;
    background: transparent;
    border: none;
    padding-top: 5px;
}

.f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
}

.list-career {
  margin-top: 30px;
  display: grid;  
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
}

.list-career-ctn {
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #D3D6E1;
}

.list-career-ctn img {
  width: 130px;
}

.list-career-ctn h2 {
  margin: 0;
}

.list-career-ctn p {
  margin: 0;
  color: #36373F;
  font-size: 14px;
}



.career-data .label {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;
}

.career-data .label div {
  padding: 5px 10px;
  border-radius: 30px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.career-data .label div p {
  font-size: 11px !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  color: #36373f;
}

.box-total {
    padding: 5px 10px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 10px 0;
  width: min-content;
  background-color: #f1f6ff;
  align-items: center;
}

.btn-stoke-blue {
    border: 1px solid #ACAFBF;
    border-radius: 12px;
    color: #ACAFBF;
    font-weight: 600;
    cursor: pointer;
    gap: 10px;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    padding: 11px 0;
    background-color: white;
    margin: 20px 0 0 20px !important;
}

.text-limit {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    visibility: visible;
    font-weight: 500;
}

.btn-stoke-blue span {
    font-family: "Poppins", sans-serif;
    font-weight: normal !important;
    font-size: 16px;
    min-width: max-content;
}


.header-info {
  margin: auto;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
}

.header-left {
  display: flex;
}

.career-detail {
  margin: auto;
  padding: 80px 0;
  max-width: 900px;
  width: 100%;
}

.career-detail-item {
  margin-top: 30px;
}

.career-detail-item h1 {
  font-size: 26px;
}

.career-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
}
.yellow-text {
  font-weight: 600;
  cursor: pointer;
  color: #162CA2;
  /* Apply yellow color to "Karir" text */
}

.career-info {
  color: #36373f;
}

.career-info-ctn {
  display: flex;
  gap: 2px;
  flex-direction: column;
  margin: 20px 0;
}
</style>