<template>
    <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.565 16.5H13.38C13.9119 16.5 14.4385 16.6048 14.9299 16.8083C15.4212 17.0118 15.8677 17.3102 16.2438 17.6862C16.6199 18.0623 16.9182 18.5088 17.1217 19.0002C17.3252 19.4915 17.43 20.0182 17.43 20.55V26.88H7.5V20.49C7.51579 19.4237 7.9515 18.4067 8.71256 17.6597C9.47362 16.9127 10.4986 16.496 11.565 16.5Z"
        fill="#7B7E8C"
      />
      <path d="M12.4649 15.045C13.9726 15.045 15.1949 13.8227 15.1949 12.315C15.1949 10.8072 13.9726 9.58496 12.4649 9.58496C10.9571 9.58496 9.73486 10.8072 9.73486 12.315C9.73486 13.8227 10.9571 15.045 12.4649 15.045Z" fill="#7B7E8C" />
      <path
        d="M29.625 27.93H6.375C5.8333 27.93 5.2969 27.8233 4.79643 27.616C4.29596 27.4087 3.84123 27.1048 3.45818 26.7218C3.07514 26.3388 2.7713 25.884 2.564 25.3836C2.3567 24.8831 2.25 24.3467 2.25 23.805V7.73999C2.25 6.64597 2.6846 5.59676 3.45818 4.82317C4.23177 4.04959 5.28098 3.61499 6.375 3.61499H29.625C30.719 3.61499 31.7682 4.04959 32.5418 4.82317C33.3154 5.59676 33.75 6.64597 33.75 7.73999V23.805C33.75 24.3467 33.6433 24.8831 33.436 25.3836C33.2287 25.884 32.9249 26.3388 32.5418 26.7218C32.1588 27.1048 31.704 27.4087 31.2036 27.616C30.7031 27.8233 30.1667 27.93 29.625 27.93ZM6.375 5.86499C5.87772 5.86499 5.40081 6.06253 5.04917 6.41416C4.69754 6.7658 4.5 7.24271 4.5 7.73999V23.805C4.5 24.3023 4.69754 24.7792 5.04917 25.1308C5.40081 25.4824 5.87772 25.68 6.375 25.68H29.625C30.1223 25.68 30.5992 25.4824 30.9508 25.1308C31.3025 24.7792 31.5 24.3023 31.5 23.805V7.73999C31.5 7.24271 31.3025 6.7658 30.9508 6.41416C30.5992 6.06253 30.1223 5.86499 29.625 5.86499H6.375Z"
        fill="#7B7E8C"
      />
      <path
        d="M25.5 32.385H10.5C10.2016 32.385 9.91548 32.2665 9.7045 32.0555C9.49353 31.8445 9.375 31.5584 9.375 31.26C9.375 30.9616 9.49353 30.6755 9.7045 30.4645C9.91548 30.2535 10.2016 30.135 10.5 30.135H25.5C25.7984 30.135 26.0845 30.2535 26.2955 30.4645C26.5065 30.6755 26.625 30.9616 26.625 31.26C26.625 31.5584 26.5065 31.8445 26.2955 32.0555C26.0845 32.2665 25.7984 32.385 25.5 32.385Z"
        fill="#7B7E8C"
      />
      <path
        d="M27 8.43005H19.5C19.1022 8.43005 18.7206 8.58809 18.4393 8.86939C18.158 9.1507 18 9.53223 18 9.93005V13.8001C18 14.1979 18.158 14.5794 18.4393 14.8607C18.7206 15.142 19.1022 15.3001 19.5 15.3001H19.935L19.59 16.0801L21.195 15.3001H27C27.3978 15.3001 27.7794 15.142 28.0607 14.8607C28.342 14.5794 28.5 14.1979 28.5 13.8001V9.93005C28.5 9.53223 28.342 9.1507 28.0607 8.86939C27.7794 8.58809 27.3978 8.43005 27 8.43005ZM20.82 13.9351C20.6211 13.9351 20.4303 13.856 20.2897 13.7154C20.149 13.5747 20.07 13.384 20.07 13.1851C20.07 12.9861 20.149 12.7954 20.2897 12.6547C20.4303 12.5141 20.6211 12.4351 20.82 12.4351C21.0189 12.4351 21.2097 12.5141 21.3503 12.6547C21.491 12.7954 21.57 12.9861 21.57 13.1851C21.57 13.384 21.491 13.5747 21.3503 13.7154C21.2097 13.856 21.0189 13.9351 20.82 13.9351ZM23.265 13.9351C23.0661 13.9351 22.8753 13.856 22.7347 13.7154C22.594 13.5747 22.515 13.384 22.515 13.1851C22.515 12.9861 22.594 12.7954 22.7347 12.6547C22.8753 12.5141 23.0661 12.4351 23.265 12.4351C23.4627 12.4389 23.6513 12.5191 23.7911 12.659C23.9309 12.7988 24.0112 12.9873 24.015 13.1851C24.015 13.2835 23.9956 13.3811 23.9579 13.4721C23.9202 13.5631 23.865 13.6457 23.7953 13.7154C23.7257 13.785 23.643 13.8403 23.552 13.878C23.461 13.9157 23.3635 13.9351 23.265 13.9351ZM25.68 13.9351C25.4811 13.9351 25.2903 13.856 25.1497 13.7154C25.009 13.5747 24.93 13.384 24.93 13.1851C24.93 12.9861 25.009 12.7954 25.1497 12.6547C25.2903 12.5141 25.4811 12.4351 25.68 12.4351C25.8777 12.4389 26.0663 12.5191 26.2061 12.659C26.3459 12.7988 26.4262 12.9873 26.43 13.1851C26.43 13.384 26.351 13.5747 26.2103 13.7154C26.0697 13.856 25.8789 13.9351 25.68 13.9351Z"
        fill="#7B7E8C"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'TrainMethodIcon',
    props: {
      width: {
        type: Number,
        default: 25,
      },
      height: {
        type: Number,
        default: 25,
      },
    },
  };
  </script>
  