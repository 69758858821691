<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <div class="cart-form">
        <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
          {{ name }}
        </h1>
        <div class="form-ctn">
          <div class="content-form">
            <p>Assessment</p>
            <v-autocomplete
              placeholder="Find assessment"
              :disabled="isWatch || isEdit"
              v-model="form.assesment_id"
              :items="assesments"
              class="form-input mt-2 no-space"
              :item-text="'name'"
              :item-value="'id'"
            ></v-autocomplete>
          </div>
        </div>
        
        <div class="form-ctn">
          <div class="content-form">
            <p>Cover Certificate</p>
            <span class="alert-info"
              >Rekomendasi dimensi file 1.600 x 420 px, kapasitas maksimal 1
              MB</span
            >
            <div class="field">
              <v-text-field
                :disabled="isWatch"
                outlined
                v-model="form.image_url"
                :rules="[!!form.image_url || 'Wajib diisi!']"
                class="form-input"
                placeholder="Link Image Certificate"
                dense
                clearable
              >
              </v-text-field>
              <div v-if="isWatch" style="margin-top: 10px">
                <!-- <span class="link-c">{{ form.image_url }}</span> -->
                <CopyIcon
                  @click.native="copyToClipboard(form.image_url)"
                  class="copy-icon"
                />
              </div>
            </div>
          </div>
          <img
            v-if="form.image_url"
            :src="form.image_url"
            alt="gambar tidak valid"
            style="width: 100%; border-radius: 14px"
          />
          <div class="content-form">
            <p>Link File Certificate</p>
            <div class="field">
              <v-text-field
                :disabled="isWatch "
                outlined
                v-model="form.file_url"
                :rules="[!!form.file_url || 'Wajib diisi!']"
                class="form-input"
                placeholder="Link File"
                dense
                clearable
              ></v-text-field>
              <div v-if="isWatch" style="margin-top: 10px">
                <!-- <span class="link-c">{{ form.image_url }}</span> -->
                <CopyIcon
                  @click.native="copyToClipboard(form.image_url)"
                  class="copy-icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button name="PUBLISH" width="260px" @click="onSumbmit" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import Button from "@/components/Button.vue";
  import CopyIcon from "@/components/icons/Copy.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    name: "ClassForm",
    data() {
      return {
        id: null,
        name: "",
        isLoading: false,
        isWatch: false,
        isEdit: false,
        users: [],
        classes: [],
        assesments: [],
        message: "",
        isPopup: false,
        form: {
          assesment_id: "",
          user_id: "",
          class_id: "",
          image_url: "",
          file_url: "",
        },
      };
    },
    components: {
      Loading,
      Button,
      CopyIcon,
      Popup
    },
    computed: {
      validData() {
        return (
          this.form.user_id &&
          this.form.class_id &&
          this.form.image_url &&
          this.form.file_url
        );
      },
    },
    methods: {
      checkingType() {
        this.name = this.$route.meta.name;
        this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
        this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getDataCertificate();
      },
      copyToClipboard(text) {
        navigator.clipboard.writeText(text);
      },
      async getUserId(id) {
        try {
          const response = await this.$crm_http.get("/v1/user/" + id);
          return response.data.data.user;
        } catch {
          return false
        }
      },
      async getClassId(id) {
        try {
          const resp = await this.$crm_http.get("/v1/class/" + id);
          return resp.data.data.class;  
        } catch {
          return false
        }
        
      },
      async getAssesment() {
        this.isLoading = true
        try {
          const resp = await this.$lms_http.get("/v1/assesment");
          const dataAssasment = resp.data.data.assesments
          for (let i = 0; i < dataAssasment.length; i++) {
            const item = dataAssasment[i];
            this.assesments.push({
            ...item,
            name: `Kelas ID = ${item.class_id} - User ID = ${item.user_id}`  
            })
          }
          this.isLoading = false
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.assesments = []
          this.isLoading = false
        }
      },
      async getDataCertificate() {
        this.isLoading = true;
        try {
          const resp = await this.$lms_http.get("/v1/certificate/" + this.id, {
            ...this.form,
          });
          this.form = resp.data.data.certificate;
          const assesmentData = this.assesments.find((item) => item.class_id === this.form.class_id && item.user_id === this.form.user_id)
          if (assesmentData) {
            this.form.assesment_id = assesmentData.id
          }
          this.isLoading = false;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
      async onSumbmit() {
        this.isLoading = true;
        try {
          const assessment = this.assesments.find((item) => item.id === this.form.assesment_id)
          if (assessment) {
            this.form.class_id = Number(assessment.class_id)
            this.form.user_id = Number(assessment.user_id)
          }
          if (this.isEdit) {
            await this.$lms_http.put("/v1/certificate/" + this.form.id, {
              ...this.form,
            });
          } else {
            await this.$lms_http.post("/v1/certificate", {
              ...this.form,
            });
          }
          setTimeout(() => {
            this.$router.push("/certificate");
          }, 2000);
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
    },
    async mounted() {
      await this.getAssesment()
      this.checkingType();
    },
  };
  </script>
  
  <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafaf9;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  .cart-form {
    position: relative;
    background-color: white;
    width: 50%;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  .alert-info {
    font-size: 14px;
    color: #7b7e8c;
  }
  .form-ctn {
    padding: 0 40px;
  }
  .submit-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  .field {
    display: flex;
  }
  .copy-icon {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
  }
  .link-c {
    color: #162ca2;
    cursor: pointer;
  }
  
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  </style>