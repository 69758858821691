<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="6" fill="#FFE5E6" />
    <path
      d="M11.6076 6.5L8.89258 9.2225"
      stroke="#FF5656"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.3926 6.5L19.1076 9.2225"
      stroke="#FF5656"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.5 10.8875C6.5 9.49995 7.2425 9.38745 8.165 9.38745H19.835C20.7575 9.38745 21.5 9.49995 21.5 10.8875C21.5 12.5 20.7575 12.3875 19.835 12.3875H8.165C7.2425 12.3875 6.5 12.5 6.5 10.8875Z"
      stroke="#FF5656"
      stroke-width="1.5"
    />
    <path
      d="M12.3203 15.5V18.1625"
      stroke="#FF5656"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M15.7695 15.5V18.1625"
      stroke="#FF5656"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M7.625 12.5L8.6825 18.98C8.9225 20.435 9.5 21.5 11.645 21.5H16.1675C18.5 21.5 18.845 20.48 19.115 19.07L20.375 12.5"
      stroke="#FF5656"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "TrashTable",
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
};
</script>
