<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.12 5.11C15.79 4.07 16.18 2.78 17.26 2.43C17.83 2.25 18.54 2.4 18.94 2.95C19.32 2.38 20.05 2.24 20.62 2.43C21.71 2.78 22.1 4.07 21.77 5.11C21.25 6.75 19.45 7.61 18.95 7.61C18.44 7.61 16.65 6.77 16.12 5.11Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.67001 18.95L7.60001 15.64C8.39001 15.11 9.53001 15.17 10.24 15.78L10.57 16.07C11.35 16.74 12.61 16.74 13.39 16.07L17.55 12.5C18.33 11.83 19.59 11.83 20.37 12.5L22 13.9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  </template>
  
  <script>
  export default {
    name: 'LogoKlien',
    props: {
      width: {
        type: Number,
        default: 25,
      },
      height: {
        type: Number,
        default: 25,
      },
    },
  };
  </script>