<template>
    
<svg :width="width" :height="width" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="white"/>
<path d="M20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30Z" stroke="#7B7E8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.1699 22.8299L22.8299 17.1699" stroke="#7B7E8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.8299 22.8299L17.1699 17.1699" stroke="#7B7E8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "close-icon",
  props: {
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
};
</script>