<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus reflective journal ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <div class="top-container-box">
      <h1>Reflective Journal</h1>
      <div>
        <Button
          icon="+"
          outline
          name="Create Reflective Journal"
          style="background-color: #3b54d9; color: #ffffff; width: 270px"
          @click="() => $router.push('/reflective-journal/new')"
        />
      </div>
    </div>
    <!-- <div class="container-box">
      <v-row>
        <v-col>
          Kategori
          <v-select
            style="width: 70%; height: 7%; margin-top: 5px"
            v-model="filter.category"
            :items="categoryes"
            :item-text="'name'"
            :item-value="'value'"
            class="form-input"
            solo
          ></v-select>
        </v-col>
        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 25px;
          "
        >
          <Button name="RESET FILTER" outline @click="clearFilter" />
          <Button
            name="TERAPKAN FILTER"
            @click="getReflectiveJournal"
            width="190px"
          />
        </v-col>
      </v-row>
    </div> -->
    <v-row>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            margin-top: 7px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="reflectiveJournals"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <span
          :style="`color: ${item.is_active === 'Aktif' ? 'green' : 'red'}`"
          >{{ item.is_active }}</span
        >
      </template>
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <button @click="watchItem(item)" class="me-2">
            <WatchTable height="28" width="28" />
          </button>
          <button @click="editItem(item)" class="me-2">
            <EditTable height="28" width="28" />
          </button>
          <button @click="deleteItem(item)">
            <TrashTable height="28" width="28" />
          </button>
        </v-row>
      </template>
    </v-data-table>

    <!-- <v-row justify="center" v-if="total >= 1">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getReflectiveJournal"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row> -->

    <!-- Display when no data is available -->
    <NoDataAlert
      v-if="reflectiveJournals.length === 0"
      :totalData="totalData"
    />

    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{
          Math.floor((currentPage - 1) * 10 + reflectiveJournals.length)
        }}
        dari {{ totalData }}</b
      >
      reflective journal yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import NewModal from "@/components/NewModal.vue";
import Loading from "@/components/ilustration/Loading.vue";
import moment from "moment";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      message: "",
      isPopup: false,
      filter: {
        category: "",
      },
      headers: [
        {
          text: "NO",
          value: "id",
          width: "1"
        },
        { text: "PERTANYAAN", value: "question", width: "340"},
        { text: "TIPE ", value: "question_type", width: "76" },
        { text: "Urutan ", value: "sequence", width: "94" },
        { text: "KATEGORI", value: "category", width: "107" },
        { text: "TANGGAL DIBUAT", value: "created_at", width: "111"},
        { text: "AKSI", value: "actions", sortable: false, width: "132" },
      ],
      reflectiveJournals: [],
      showFilterModal: false,
      filterModal: [
        {
          title: "Kategori",
          name: "category",
          items: [
            {
              name: "Prakerja",
              value: "PRAKERJA",
            },
            {
              name: "Bootcamp",
              value: "BOOTCAMP",
            },
          ],
        },
      ],
    };
  },
  components: {
    Button,
    Loading,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    NewModal,
    FilterIcon,
    FilterModal,
    Popup
  },
  methods: {
    applyFilters() {
      this.getReflectiveJournal();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getReflectiveJournal() {
      await this.$web_http
        .get(`/v1/reflective-journal?category=${this.filter.category}`)
        .then((response) => {
          this.reflectiveJournals =
            response.data.data.reflective_journals || [];
            this.reflectiveJournals = this.reflectiveJournals.map((item) => {
              return {
                ...item,
                created_at: this.format_date(item.created_at),
              };
            });
          //   this.reflectiveJournals = response.data.data.reflectiveJournals
          //   const t = parseInt(response.data.data.total);
          //   this.total = Math.ceil(t / 10);
          //   this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    watchItem(item) {
      this.$router.push("/reflective-journal/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/reflective-journal/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$web_http.delete("/v1/reflective-journal/" + this.id);
        this.getReflectiveJournal();
        this.isModal = false;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    clearFilter() {
      this.filter = {
        category: "",
      };
      this.getReflectiveJournal();
    },
    handleClearButtonClick() {
      this.filter = {
        category: "",
      };
    },
  },
  mounted() {
    this.getReflectiveJournal();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>
