<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">{{ name }}</h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Full Name</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.full_name" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Email</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.email" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Phone Number</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.phone_number" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Linkedin Url</p>
          <div class="input-button-container">
            <v-text-field :readonly="isWatch" outlined v-model="form.linkedin_url" class="form-input" placeholder="Kosong" dense></v-text-field>
            <Button @click="openUrl(form.linkedin_url)" name="Open" width="100px" height="40px" />
          </div>
        </div>
        <div class="content-form">
          <p>Resume Url</p>
          <div class="input-button-container">
            <v-text-field :readonly="isWatch" outlined v-model="form.resume_url" class="form-input" placeholder="Kosong" dense></v-text-field>
            <Button @click="openUrl(form.resume_url)" name="Open" width="100px" height="40px" />
          </div>
        </div>
        <div class="content-form">
          <p>Portofolio Url</p>
          <div class="input-button-container">
            <v-text-field :readonly="isWatch" outlined v-model="form.portfolio_url" class="form-input" placeholder="Kosong" dense></v-text-field>
            <Button @click="openUrl(form.portfolio_url)" name="Open" width="100px" height="40px" />
          </div>
        </div>
        <div class="content-form">
          <p>Website</p>
          <div class="input-button-container">
            <v-text-field :readonly="isWatch" outlined v-model="form.website" class="form-input" placeholder="Kosong" dense></v-text-field>
            <Button @click="openUrl(form.website)" name="Open" width="100px" height="40px" v-if="form.website" />
          </div>
        </div>
        <div class="content-form">
          <p>Position</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.position" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Company</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.company" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Location</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.location" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Perusahaan Sebelumnya</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.current_company" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Level Sebelumnya</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.current_level" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form mb-5">
          <p>Tanggal Pendaftaran</p>
          <v-text-field :readonly="isWatch" outlined v-model="form.created_at" class="form-input" placeholder="Kosong" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Catatan tambagan</p>
          <v-textarea placeholder="Kosong" :readonly="isWatch" rows="3" outlined v-model="form.notes" class="form-input" ></v-textarea>
        </div>
        <div class="content-form">
          <p>Status (Dapat diubah)</p>
          <v-select
            class="form-input"
            :items="statuses"
            v-model="form.status"
            @input="updateStatus()"
            solo
            :item-text="'text'"
            :item-value="'value'"
          ></v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/ilustration/Loading.vue';
import Button from '../../../components/Button.vue';

export default {
  name: 'FormCareerApplication',
  data() {
    return {
      id: null,
      modal: false,
      name: '',
      isLoading: false,
      isWatch: false,
      isEdit: false,
      statuses: [
        {
          text: 'Terkirim',
          value: 'SUBMITTED'
        },
        {
          text: 'Di Proses',
          value: 'PROCESS'
        },
        {
          text: 'Di Terima',
          value: 'APPROVE'
        },
        {
          text: 'Di Tolak',
          value: 'REJECT'
        },
      ],
      form: {
        id: '',
        full_name: '',
        email: '',
        phone_number: '',
        linkedin_url: '',
        resume_url: '',
        portfolio_url: '',
        position: '',
        company: '',
        status: '',
        location: '',
        current_company: '',
        current_level: '',
        website: '',
        notes: '',
        created_at: '',
      },
    };
  },
  components: {
    Loading,
    Button,
  },
  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    },
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf('watch') >= 0;
      this.isEdit = this.$route.path.lastIndexOf('edit') >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataCareerApplication();
    },
    async updateStatus() {
      try {
        await this.$crm_http.put(`/v1/career-application/${this.form.id}?status=${this.form.status}`);
      } catch (err) {
        console.error(err.response);
      }
    },
    async getDataCareerApplication() {
      this.isLoading = true;
      try {
        const appResp = await this.$crm_http.get(`/v1/career-application/` + this.id);
        const appData = appResp.data.data.career_application;

        const careerResp = await this.$crm_http.get(`/v1/career/` + appData.career_id);
        const careerData = careerResp.data.data.career;

        this.form = {
          id: appData.id,
          full_name: appData.full_name,
          email: appData.email,
          status: appData.status,
          phone_number: appData.phone_number,
          linkedin_url: appData.linkedin_url,
          resume_url: appData.resume_url,
          portfolio_url: appData.portfolio_url,
          created_at: this.$helpers.formatDate(appData.created_at),
          position: careerData.position,
          company: careerData.company,
          location: careerData.location,
          current_company: appData.current_company,
          current_level: appData.current_level,
          website: appData.website,
          notes: appData.notes,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.input-button-container {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
