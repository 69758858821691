<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus partner ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <div class="top-container-box">
      <h1>Main Partner</h1>
      <div>
        <Button
          icon="+"
          outline
          name="Create Partner"
          style="background-color: #3b54d9; color: #ffffff; width: 180px"
          @click="() => $router.push('/partner/new')"
        />
      </div>
    </div>
    <v-row>
      <!-- Search  -->
      <v-col>
        <div class="action-head">
          <div class="f-search">
            <!-- Input -->
            <div>
              <v-text-field
                v-model="filter.name"
                type="text"
                class="f-input"
                placeholder="Search..."
                clearable
                dense
                flat
                solo
                style="
                  padding-top: 5px;
                  padding-left: 5px;
                  width: 448px;
                  border-radius: 14px;
                "
                @input="applyFilters"
              ></v-text-field>
            </div>
            <!-- Icon -->
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        style="display: flex; justify-content: flex-end; gap: 10px"
      ></v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="partners"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <span
          :style="`color: ${item.is_active === 'Aktif' ? 'green' : 'red'}`"
          >{{ item.is_active }}</span
        >
      </template>
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getPartners"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="totalData" />

    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{ Math.floor((currentPage - 1) * 10 + partners.length) }} dari
        {{ totalData }}</b
      >
      partner yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import NewModal from "@/components/NewModal.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      message: "",
      isPopup: false,
      isFilterActive: false,
      filter: {
        name: "",
      },
      headers: [
        {
          text: "NO",
          value: "id",
          width: "1",
        },
        { text: "Nama", value: "name" },
        { text: "KATEGORI", value: "category" },
        { text: "Partner Code", value: "code" },
        { text: "AKSI", value: "actions", sortable: false, width: "132px" },
      ],
      partners: [],
    };
  },
  components: {
    Button,
    NewModal,
    Loading,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    Popup
  },
  methods: {
    applyFilters() {
      this.getPartners();
      this.showFilterModal = false;
    },
    async getPartners() {
      await this.$crm_http
        .get(
          `/v1/partner?name=${this.filter.name || ""}&page=${
            this.currentPage
          }&per_page=10`
        )
        .then((response) => {
          this.partners = response.data.data.partners;
          // const t = parseInt(response.data.data.total);
          // this.total = t / 10 + (t % 10 === 0 ? 0 : 1);
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    watchItem(item) {
      this.$router.push("/partner/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/partner/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$web_http.delete("/v1/partner/" + this.id, this.form);
        this.getPartners();
        this.isModal = false;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    onFilter() {
      this.currentPage = 1;
      this.isFilterActive = true;
      this.getPartners();
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        name: "",
      };
      this.getPartners();
    },
    handleClearButtonClick() {
      this.filter = {
        name: "",
      };
      this.updateIsFilterActive();
    },
    updateIsFilterActive() {
      this.isFilterActive = !!this.filter.name;
      // this.isFilterActive = true;
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getPartners();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>
