<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="6" fill="#E3FAF2" />
    <path
      d="M13.25 6.5H11.75C8 6.5 6.5 8 6.5 11.75V16.25C6.5 20 8 21.5 11.75 21.5H16.25C20 21.5 21.5 20 21.5 16.25V14.75"
      stroke="#619885"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0304 7.26495L11.1204 13.1749C10.8954 13.3999 10.6704 13.8424 10.6254 14.1649L10.3029 16.4224C10.1829 17.2399 10.7604 17.8099 11.5779 17.6974L13.8354 17.3749C14.1504 17.3299 14.5929 17.1049 14.8254 16.8799L20.7354 10.9699C21.7554 9.94995 22.2354 8.76495 20.7354 7.26495C19.2354 5.76495 18.0504 6.24495 17.0304 7.26495Z"
      stroke="#619885"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1826 8.11255C16.6851 9.90505 18.0876 11.3075 19.8876 11.8175"
      stroke="#619885"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EditTable",
  props: {
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
};
</script>
