<template>
    <div>
        <div class="d-flex flex-row">
            <v-file-input
                v-model="file"
                show-size
                label="File input"
                class="mr-5"
            ></v-file-input>

            <v-btn :disabled="!file" color="success" @click="importFinalProject" class="ml-5 mt-3">Submit</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            file: [],
        }
    },
    methods: {
        async importFinalProject() {

            // this.spin_load.val = true
            let formData = new FormData();

            // files
            formData.append("file", this.file, this.file.name);


            await this.$lms_http
            .post("/v1/import-final-project", formData)
            // .then(
            //     location.reload()
            // )
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
    },
    mounted () {
    }
}
</script>