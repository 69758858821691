<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <!-- Delete Data Pop-up -->
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus banner ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <div class="top-container-box">
      <h1>Hit Banner</h1>
      <!-- Create Banner Button -->
      <div>
        <Button
          icon="+"
          name="Create Hit Banner"
          @click="() => $router.push('/hit-banner/new')"
          style="background-color: #3b54d9; color: #ffffff; width: 210px"
        />
      </div>
    </div>
    <div class="container-box" style="background-color: #f5f5f5; padding: 0px">
      <v-row>
        <!-- Search  -->
        <v-col>
          <div class="action-head">
            <div class="f-search">
              <!-- Input -->
              <div>
                <v-text-field
                  v-model="filter.name"
                  type="text"
                  class="f-input"
                  placeholder="Search..."
                  clearable
                  dense
                  flat
                  solo
                  style="
                    padding-top: 5px;
                    padding-left: 5px;
                    width: 448px;
                    border-radius: 14px;
                  "
                  @input="applyFilters"
                ></v-text-field>
              </div>
              <!-- Icon -->
              <div class="f-search-ic">
                <img
                  src="/img/icons/search.png"
                  style="width: 100%"
                  alt="Search Ic"
                />
              </div>
            </div>
          </div>
        </v-col>
        <!-- Filter -->
        <v-col style="position: relative">
          <!-- Button -->
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 8px;
              color: #7b7e8c;
              font-weight: 500;
              justify-content: center;
              align-items: center;
              background-color: white;
              padding: 10px;
              border: 1px solid #d3d6e1;
              border-radius: 10px;
              width: 100px;
              margin-top: 7px;
              cursor: pointer;
            "
            @click="toggleFilterModal"
          >
            <FilterIcon width="24" height="24" />
            <div>Filter</div>
          </div>
          <!-- Modal -->
          <FilterModal
            :show="showFilterModal"
            @apply="applyFilters"
            :filterDatas="filterModal"
            :filters="filter"
            @updateFilter="updateFilters"
          />
        </v-col>
        <v-col style="display: flex; justify-content: flex-end; gap: 10px">
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :headers="headers"
      :items="banners"
      sort-by="calories"
      class="elevation-1 mt-8 table"
      hide-default-footer
    >
      <template v-slot:item.category="{ item }">
        <span class="pill">{{ item.category }}</span>
      </template>
      <template v-slot:item.is_active="{ item }">
        <span
          :class="`pill ${
            item.is_active === 'Aktif' ? 'pill-active' : 'pill-nonactive'
          }`"
          >{{ item.is_active }}</span
        >
      </template>
      <template v-slot:item.type="{ item }">
        <span style="text-transform: capitalize">{{ item.type }}</span>
      </template>
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <button @click="watchItem(item)" class="me-2">
            <WatchTable height="28" width="28" />
          </button>
          <button @click="editItem(item)" class="me-2">
            <EditTable height="28" width="28" />
          </button>
          <button @click="deleteItem(item)">
            <TrashTable height="28" width="28" />
          </button>
        </v-row>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="totalData" />

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-if="totalData != 0"
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getBanner"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p
      :class="[total >= 1 ? 'count-data' : 'no-pagination']"
      v-if="totalData"
      style="color: #515151"
    >
      Menampilkan {{ Math.floor((currentPage - 1) * 10 + banners.length) }} dari
      {{ totalData }}
      data
    </p>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
// import Modal from "../../../components/Modal.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import NewModal from "@/components/NewModal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: Math.ceil(14 / 10),
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      isFilterActive: false,
      hover: false,
      showFilterModal: false,
      message: "",
      isPopup: false,
      filter: {
        sort_by: "",
        type: "",
        name: "",
      },
      filterModal: [
        {
          title: "Category",
          name: "type",
          items: [
            {
              name: "Sales",
              value: "sales",
            },
            {
              name: "Awareness",
              value: "awareness",
            },
          ],
        },
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
      headers: [
        {
          text: "NO",
          value: "id",
          width: "68px",
        },
        { text: "Title", value: "name", width: "350" },
        { text: "Type", value: "type" },
        { text: "Status", value: "is_active", width: "111" },
        { text: "Start", value: "start" },
        { text: "Due", value: "due" },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          width: "108",
        },
      ],
      banners: [],
    };
  },
  components: {
    Button,
    // Modal,
    Loading,
    TrashTable,
    EditTable,
    WatchTable,
    FilterIcon,
    FilterModal,
    NoDataAlert,
    NewModal,
    Popup
  },
  methods: {
    async getBanner() {
      await this.$crm_http
        .get(
          `/v1/banner?sort_by=${this.filter.sort_by}&name=${
            this.filter.name || ""
          }&category=hit&type=${this.filter.type || ""}&page=${
            this.currentPage
          }&per_page=10`
        )
        .then((response) => {
          this.banners = [];
          response.data.data.banners.forEach((element) => {
            this.banners.push({
              id: element.id,
              name: element.name,
              link_url: element.link_url,
              category: element.category,
              program: element.program,
              is_active: element.is_active ? "Aktif" : "Non Aktif",
              type: element.type == "" ? "-" : element.type,
              start:
                element.start_datetime == "null"
                  ? "-"
                  : moment(element.start_datetime).format("DD/MM/YYYY"),
              due:
                element.end_datetime == "null"
                  ? "-"
                  : moment(element.end_datetime).format("DD/MM/YYYY"),
            });
          });
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    watchItem(item) {
      this.$router.push("/hit-banner/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/hit-banner/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete("/v1/banner/" + this.id);
        this.getBanner();
        this.isModal = false;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    onFilter() {
      this.currentPage = 1;
      this.isFilterActive = true;
      this.getBanner();
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },

    applyFilters() {
      this.getBanner();
      this.showFilterModal = false;
    },

    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getBanner();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}

.pill {
  background-color: #fafaf9;
  border-color: #e7e8f0;
  border: 1px solid;
  border-radius: 100px;
  padding: 4px 12px 4px 12px;
  color: #7b7e8c;
  text-transform: capitalize;
}

.pill-active {
  background-color: #e9f1fb;
  border: none;
  color: #2671d9;
  padding: 4px 8px 4px 8px;
}

.pill-nonactive {
  background-color: #f5f6fa;
  border-color: #d3d6e1;
  color: #acafbf;
  padding: 4px 8px 4px 8px;
}

.pill-due {
  background-color: #ffebd4;
  border: none;
  color: #ff9a23;
  padding: 4px 8px 4px 8px;
}
</style>
