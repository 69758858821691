<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <div class="top-container-box">
      <h1>Corporate Registration</h1>
    </div>
    <v-row>
      <!-- Search  -->
      <v-col>
        <div class="action-head">
          <div class="f-search">
            <!-- Input -->
            <div>
              <v-text-field
                v-model="filter.corporate_name"
                type="text"
                class="f-input"
                placeholder="Search..."
                clearable
                dense
                flat
                solo
                style="
                  padding-top: 5px;
                  padding-left: 5px;
                  width: 448px;
                  border-radius: 14px;
                "
                @input="applyFilters"
              ></v-text-field>
            </div>
            <!-- Icon -->
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
          </div>
        </div>
      </v-col>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            margin-top: 7px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="corporate_registrations"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getCorporateRegistration"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b>{{ totalData }}</b>
      data yang tersedia
    </p>
  </div>
</template>

<script>
import FilterModal from "@/components/FilterModal.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import NoDataAlert from "@/components/NoDataAlert.vue";
import WatchTable from "@/components/icons/WatchTable.vue";
import FilterIcon from "@/components/icons/Filter.vue";

export default {
  name: "CorporateRegistration",
  data() {
    return {
      currentPage: 1,
      total: 0,
      isLoading: false,
      id: 0,
      totalData: 0,
      filter: {
    sort_by: "",
    type: "",
    corporate_name: "",
  },

      headers: [
        {
          text: "NO",
          value: "id",
          width: "1",
        },
        { text: "Nama Perusahaan", value: "corporate_name", width: "250" },
        { text: "Tipe Perusahaan", value: "company_field" },
        { text: "Program yang dipilih", value: "program" },
        { text: "Total Peserta", value: "total_student" },
        { text: "AKSI", value: "actions", sortable: false },
      ],
      corporate_registrations: [
        // {
        //   corporate_name: "Gunadarma",
        //   company_field: "testing",
        //   program: ""
        // }
      ],
      showFilterModal: false,
      filterModal: [
        {
          title: "Category",
          name: "type",
          items: [
            {
              name: "General",
              value: "GENERAL",
            },
            {
              name: "Collaboration",
              value: "COLLABORAION",
            },
            {
              name: "Training",
              value: "TRAINING",
            },
            {
              name: "Talent Service",
              value: "TALENT_SERVICE",
            },
            {
              name: "Services",
              value: "SERVICES",
            },
          ],
        },
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
    };
  },
  components: {
    Loading,
    NoDataAlert,
    WatchTable,
    FilterModal,
    FilterIcon,
  },
  methods: {
    async getCorporateRegistration() {
      await this.$crm_http
        .get(
          `/v1/corporate-registration?sort_by=${
            this.filter.sort_by || ""
          }&company_name=${
            this.filter.corporate_name || ""
          }&category=${this.filter.type ||
            ""}&page=${
              this.currentPage
            }&per_page=10
          `)
        .then((response) => {
          if (!response.data.data.corporate_registration) {
            this.corporate_registrations = [];
            return;
          }
          this.corporate_registrations = response.data.data.corporate_registration.map((item) => ({
            ...item,
            created_at: this.format_date(item.created_at),
          }));
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.total;
          console.log(this.filter)
        })
        .catch((err) => console.error(err.response));
    },
    watchItem(item) {
      this.$router.push('/corporate-registration/watch/' + item.id);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    applyFilters() {
      this.getCorporateRegistration();
      this.showFilterModal = false;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getCorporateRegistration();
  },
};
</script>
  
  <style scoped>
  .action-head {
      margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-title {
    font-weight: bold;
    font-size: 19px;
  }

  .f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
  .top-container-box {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 30px;
  }
  .form-input {
   border-radius: 14px !important;
   background-color: transparent !important;
  }
  .count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
  </style>