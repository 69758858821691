<template>
  
<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6667 6.66663V5.33329C22.6667 4.62605 22.3857 3.94777 21.8856 3.44767C21.3855 2.94758 20.7072 2.66663 20 2.66663H12C11.2928 2.66663 10.6145 2.94758 10.1144 3.44767C9.61428 3.94777 9.33333 4.62605 9.33333 5.33329V6.66663C7.91885 6.66663 6.56229 7.22853 5.5621 8.22872C4.5619 9.22892 4 10.5855 4 12V13.3333C4 13.6869 4.14048 14.0261 4.39052 14.2761C4.64057 14.5262 4.97971 14.6666 5.33333 14.6666H26.6667C27.0203 14.6666 27.3594 14.5262 27.6095 14.2761C27.8595 14.0261 28 13.6869 28 13.3333V12C28 10.5855 27.4381 9.22892 26.4379 8.22872C25.4377 7.22853 24.0812 6.66663 22.6667 6.66663Z" fill="currentColor"/>
<path d="M26.6667 16.6666H23.8C23.5158 16.6478 23.2329 16.7204 22.993 16.874C22.753 17.0276 22.5686 17.2539 22.4667 17.52C22.2915 18.039 21.9608 18.4914 21.5195 18.8159C21.0781 19.1403 20.5476 19.321 20 19.3333H12C11.441 19.3347 10.8956 19.1604 10.441 18.835C9.98639 18.5096 9.64556 18.0496 9.46667 17.52C9.36837 17.2651 9.19417 17.0466 8.96762 16.894C8.74108 16.7413 8.47312 16.662 8.2 16.6666H5.33333C4.97971 16.6666 4.64057 16.8071 4.39052 17.0571C4.14048 17.3072 4 17.6463 4 18V22.6666C4 24.0811 4.5619 25.4377 5.5621 26.4379C6.56229 27.4381 7.91885 28 9.33333 28H22.6667C24.0812 28 25.4377 27.4381 26.4379 26.4379C27.4381 25.4377 28 24.0811 28 22.6666V18C28 17.6463 27.8595 17.3072 27.6095 17.0571C27.3594 16.8071 27.0203 16.6666 26.6667 16.6666Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'CareerIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
