<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 4.5H17.22C17.1624 3.81826 16.8508 3.18301 16.3471 2.72002C15.8434 2.25704 15.1842 2.00007 14.5 2H6.5C5.77065 2 5.07118 2.28973 4.55546 2.80546C4.03973 3.32118 3.75 4.02065 3.75 4.75V16.75C3.75 17.4793 4.03973 18.1788 4.55546 18.6945C5.07118 19.2103 5.77065 19.5 6.5 19.5H6.78C6.83764 20.1817 7.14916 20.817 7.65289 21.28C8.15662 21.743 8.81582 21.9999 9.5 22H17.5C18.2293 22 18.9288 21.7103 19.4445 21.1945C19.9603 20.6788 20.25 19.9793 20.25 19.25V7.25C20.25 6.52065 19.9603 5.82118 19.4445 5.30546C18.9288 4.78973 18.2293 4.5 17.5 4.5ZM6.75 7.25V18H6.5C6.16848 18 5.85054 17.8683 5.61612 17.6339C5.3817 17.3995 5.25 17.0815 5.25 16.75V4.75C5.25 4.41848 5.3817 4.10054 5.61612 3.86612C5.85054 3.6317 6.16848 3.5 6.5 3.5H14.5C14.7849 3.50192 15.0603 3.60268 15.2791 3.78506C15.498 3.96744 15.6467 4.22014 15.7 4.5H9.5C8.77065 4.5 8.07118 4.78973 7.55546 5.30546C7.03973 5.82118 6.75 6.52065 6.75 7.25ZM18.75 19.25C18.75 19.4142 18.7177 19.5767 18.6549 19.7284C18.592 19.88 18.5 20.0178 18.3839 20.1339C18.2678 20.25 18.13 20.342 17.9784 20.4049C17.8267 20.4677 17.6642 20.5 17.5 20.5H9.5C9.33585 20.5 9.1733 20.4677 9.02165 20.4049C8.86999 20.342 8.73219 20.25 8.61612 20.1339C8.50004 20.0178 8.40797 19.88 8.34515 19.7284C8.28233 19.5767 8.25 19.4142 8.25 19.25V7.25C8.25 6.91848 8.3817 6.60054 8.61612 6.36612C8.85054 6.1317 9.16848 6 9.5 6H17.5C17.8315 6 18.1495 6.1317 18.3839 6.36612C18.6183 6.60054 18.75 6.91848 18.75 7.25V19.25Z"
        fill="#ACAFBF"
      />
    </svg>
  </template>
  <script>
  export default {
    name: 'CopyIcon',
    props: {
      width: {
        type: Number,
        default: 25,
      },
      height: {
        type: Number,
        default: 25,
      },
    },
  };
  </script>