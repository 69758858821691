<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <!-- Delete Data Pop-up -->
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus sertifikat ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <!-- <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah kamu yakin ingin menghapus sertifikat ini?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button name="YA, HAPUS DATA" width="100%" @click="onDelete" />
        <Button
          name="TIDAK, KEMBALI"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
      </div>
    </Modal> -->

    <div class="head-info">
      <div class="head-info-ttl">
        <div style="display: flex; gap: 10px; align-items: center">
          <h1>Sertifikat</h1>
        </div>
      </div>
      <Button
        icon="+"
        width="290px"
        name="Create Sertifikat"
        outline
        style="background-color: #3b54d9; color: #ffffff; width: 260px"
        @click="() => $router.push(`/certificate/new`)"
      />
    </div>
    <div class="container-box">
      <div class="action-head">
        <div style="display: flex; gap: 15px">
          <div>
            <span>Kelas</span>
            <v-select
              :items="classes"
              v-model="classId"
              solo
              placeholder="filter by class"
              style="border-radius: 16px; width: 200px"
              :item-text="'name'"
              :item-value="'id'"
            >
            </v-select>
          </div>
        </div>
        <div style="display: flex; gap: 10px">
          <Button name="Clear" outline @click="clearFilter" />
          <Button
            name="TERAPKAN FILTER"
            @click="getCertificates"
            width="210px"
          />
        </div>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="certificates"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total && !search">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getCertificates"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getAuthentification from "@/utils/badrequest.js";
import Button from "../../../components/Button.vue";
import Loading from "@/components/ilustration/Loading.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import NewModal from "@/components/NewModal.vue";
import WatchTable from "@/components/icons/WatchTable.vue";
import EditTable from "@/components/icons/EditTable.vue";
import TrashTable from "@/components/icons/TrashTable.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isLoading: false,
      isModal: false,
      currentPage: 1,
      total: 0,
      classId: "",
      id: 0,
      message: "",
        isPopup: false,
      headers: [
        {
          text: "ID",
          value: "id",
          width: "1",
        },
        { text: "Email", value: "email", width: "250" },
        { text: "Fullname", value: "full_name", width: "250" },
        {
          text: "Nama Kelas",
          value: "class_name",
          sortable: false,
          width: "250",
        },
        { text: "Aksi", value: "actions", sortable: false, width: "132" },
      ],
      certificates: [],
      classes: [],
    };
  },
  components: {
    Loading,
    Button,
    NoDataAlert,
    NewModal,
    WatchTable,
    EditTable,
    TrashTable,
    Popup
  },
  methods: {
    watchItem(item) {
      this.$router.push("/certificate/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/certificate/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },

    async getCertificates() {
      this.certificates = [];
      var userId = "";
      if (!this.search == "") {
        userId = await this.getUserBySearch(this.search);
      }
      const response = await this.$lms_http.get(
        `/v1/certificate?user_id=${userId}&class_id=${this.classId}&page=${this.currentPage}&limit=30`
      );
      for (let i = 0; i < response.data.data.certificates.length; i++) {
        const element = response.data.data.certificates[i];
        try {
          const user = await this.getUser(element.user_id);
          this.certificates.push({
            id: element.id,
            class_id: element.class_id,
            user_id: element.user_id,
            image_url: element.image_url,
            file_url: element.file_url,
            class_name: element.class.name,
            full_name: user.full_name,
            email: user.email,
          });
        } catch {
          this.certificates.push({
            id: element.id,
            class_id: element.class_id,
            user_id: element.user_id,
            image_url: element.image_url,
            file_url: element.file_url,
            class_name: element.class.name,
            full_name: "-",
            email: "-",
          });
        }
      }
      const t = parseInt(response.data.data.total);
      this.total = t / 10 + (t % 10 === 0 ? 0 : 1);
    },

    async onDelete() {
      this.isLoading = true;
      try {
        console.log("HAI", this.id);
        await this.$lms_http.delete("/v1/certificate/" + this.id);
        this.isModal = false;
        this.getCertificates();
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },

    async getUser(id) {
      try {
        const response = await this.$crm_http.get("/v1/user/" + id);
        return response.data.data.user;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        return null
      }
    },
    async getUserBySearch(keyword) {
      try {
        const response = await this.$crm_http.get(
          `/v1/user?keyword=${keyword}`
        );
        return response.data.data.users[0].id;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        return null
      }
    },

    async getClasses() {
      await this.$web_http
        .get("/v1/class")
        .then((response) => {
          this.classes = response.data.data.classes;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },

    clearFilter() {
      this.search = "";
      this.sortBy = "";
      this.getCertificates();
    },
  },

  beforeCreate() {
    getAuthentification();
  },

  mounted() {
    this.getCertificates();
    this.getClasses();
  },
};
</script>
<style scoped>
.action-head {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.head-info-ttl {
  width: 100%;
  margin-bottom: 30px;
}

.container-box {
  margin-top: 30px;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
</style>
