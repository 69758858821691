<template>
  <svg :width="width" :height="width" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.16783 16.8274C3.6379 17.4481 4.38002 17.7899 5.25711 17.7899H18.2032C19.8888 17.7899 21.56 16.5168 22.0073 14.8921L23.8884 8.06495C24.1211 7.22002 23.9886 6.41316 23.516 5.79247C23.043 5.17178 22.3005 4.83034 21.4238 4.83034H20.1359L19.8537 3.70912C19.5609 2.54178 18.0056 1.62788 16.3136 1.62788H11.5579C10.3174 1.62788 9.46993 1.37402 9.26768 1.23017C8.735 0.260421 6.36225 0.210495 5.88287 0.210495H2.80693C1.94549 0.210495 1.1801 0.54813 0.652072 1.16205C0.124041 1.77555 -0.0963944 2.58198 0.0394211 3.47514L2.72104 15.765C2.78069 16.153 2.93047 16.5143 3.16783 16.8274ZM22.5061 6.56167C22.7333 6.85996 22.7892 7.27417 22.6643 7.72774L20.7837 14.5553C20.4904 15.6207 19.3087 16.5211 18.2032 16.5211H5.25711C4.78662 16.5211 4.40413 16.3577 4.17947 16.0616C3.9548 15.765 3.90149 15.3524 4.02884 14.8997L5.32226 10.3057C5.62224 9.24032 6.81073 8.33996 7.91714 8.33996H12.0428C13.5279 8.33996 15.1996 7.8022 15.5203 6.77661C15.6032 6.57394 16.4748 6.09964 17.7877 6.09964H21.4234C21.8951 6.09964 22.2793 6.26381 22.5061 6.56167ZM1.6142 1.98921C1.89726 1.66046 2.32121 1.47895 2.80735 1.47895H5.8833C7.12383 1.47895 7.9713 1.73281 8.17354 1.87709C8.70665 2.84684 11.0786 2.89677 11.5579 2.89677H16.314C17.4746 2.89677 18.4904 3.48868 18.6233 4.01798L18.8272 4.82992H17.7881C16.3026 4.82992 14.6313 5.36768 14.3106 6.39328C14.2273 6.59594 13.3557 7.07024 12.0432 7.07024H7.91757C6.23405 7.07024 4.5573 8.33996 4.10077 9.96087L3.34215 12.6564L1.28715 3.24413C1.2148 2.76391 1.33115 2.31796 1.6142 1.98921Z" fill="currentColor" fill-opacity="0.972549"/>
  </svg>
</template>

<script>
export default {
  name: "ProgramManager",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
