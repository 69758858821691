<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <Popup
        :message="message"
        v-if="isPopup"
        @close="() => (isPopup = false)"
      />
      <div class="cart-form">
        <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
        <div class="content-form">
          <p>Kategori</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="categories"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.category"
            placeholder="Pilih kategori"
            solo
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Urutan</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.sequence"
            type="number"
            class="form-input"
            placeholder="Urutan"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Pertanyaan</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.question"
            class="form-input"
            placeholder="Tulis Pertanyaan"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button
            name="PUBLISH"
            width="260px"
            @click="onSumbmit"
            :disabled="!validData"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import Loading from "@/components/ilustration/Loading.vue";
  import Button from "../../../components/Button.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    name: "FormBlog",
    data() {
      return {
        id: null,
        modal: false,
        name: "",
        isLoading: false,
        isWatch: false,
        isEdit: false,
        message: "",
        isPopup: false,
        categories: [
        {
          name: "Prakerja",
          value: "PRAKERJA",
        },
        {
          name: "Bootcamp",
          value: "BOOTCAMP",
        },
        ],
        form: {
          question: "",
          question_type: "essay",
          sequence: 0,
          choices: [],
          category: ""
        },
      };
    },
    components: {
      Button,
      Loading,
      Popup
    },
    computed: {
      validData() {
        return this.form.question && this.form.category && this.form.sequence;
      },
    },
    methods: {
      checkingType() {
        this.name = this.$route.meta.name;
        this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
        this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getDataReflectiveJournal();
      },
      async getDataReflectiveJournal() {
        this.isLoading = true;
        try {
          const resp = await this.$web_http.get("/v1/reflective-journal/" + this.id);
          this.form = resp.data.data.reflective_journal;
          this.isLoading = false;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
      async onSumbmit() {
        this.isLoading = true;
        try {
          if (this.isEdit) {
            await this.$web_http.put("/v1/reflective-journal/" + this.form.id, {...this.form, sequence: Number(this.form.sequence)});
          } else {
            await this.$web_http.post("/v1/reflective-journal", {...this.form, sequence: Number(this.form.sequence)});
          }
          setTimeout(() => {
            this.$router.push("/reflective-journal");
          }, 2000);
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
    },
    mounted() {
      this.checkingType();
    },
  };
  </script>
  
  <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafaf9;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  .cart-form {
    position: relative;
    width: 50%;
    border-radius: 30px;
    padding: 20px 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  .banner-berkas {
    font-size: 18px;
    margin: 20px 0;
    font-weight: bold;
  }
  .watch-ctn {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid gray;
  }
  .submit-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  .alert-info {
    font-size: 14px;
    color: #7b7e8c;
  }
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  </style>
  