<template>
    <svg :width="width" :height="height" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6128 18H8.61279C8.19858 18 7.86279 18.3358 7.86279 18.75V20.25C7.86279 20.6642 8.19858 21 8.61279 21H11.6128C12.027 21 12.3628 20.6642 12.3628 20.25V18.75C12.3628 18.3358 12.027 18 11.6128 18Z" fill="#7B7E8C" />
      <path d="M19.833 18H16.833C16.4188 18 16.083 18.3358 16.083 18.75V20.25C16.083 20.6642 16.4188 21 16.833 21H19.833C20.2472 21 20.583 20.6642 20.583 20.25V18.75C20.583 18.3358 20.2472 18 19.833 18Z" fill="#7B7E8C" />
      <path d="M28.0679 18H25.0679C24.6537 18 24.3179 18.3358 24.3179 18.75V20.25C24.3179 20.6642 24.6537 21 25.0679 21H28.0679C28.4821 21 28.8179 20.6642 28.8179 20.25V18.75C28.8179 18.3358 28.4821 18 28.0679 18Z" fill="#7B7E8C" />
      <path
        d="M11.6128 23.4301H8.61279C8.19858 23.4301 7.86279 23.7658 7.86279 24.1801V25.6801C7.86279 26.0943 8.19858 26.4301 8.61279 26.4301H11.6128C12.027 26.4301 12.3628 26.0943 12.3628 25.6801V24.1801C12.3628 23.7658 12.027 23.4301 11.6128 23.4301Z"
        fill="#7B7E8C"
      />
      <path
        d="M19.833 23.4301H16.833C16.4188 23.4301 16.083 23.7658 16.083 24.1801V25.6801C16.083 26.0943 16.4188 26.4301 16.833 26.4301H19.833C20.2472 26.4301 20.583 26.0943 20.583 25.6801V24.1801C20.583 23.7658 20.2472 23.4301 19.833 23.4301Z"
        fill="#7B7E8C"
      />
      <path
        d="M28.0679 23.4301H25.0679C24.6537 23.4301 24.3179 23.7658 24.3179 24.1801V25.6801C24.3179 26.0943 24.6537 26.4301 25.0679 26.4301H28.0679C28.4821 26.4301 28.8179 26.0943 28.8179 25.6801V24.1801C28.8179 23.7658 28.4821 23.4301 28.0679 23.4301Z"
        fill="#7B7E8C"
      />
      <path
        d="M8.68799 5.02501V7.89001C8.68799 8.51134 9.19167 9.01501 9.81299 9.01501C10.4343 9.01501 10.938 8.51134 10.938 7.89001V5.02501C10.938 4.40369 10.4343 3.90001 9.81299 3.90001C9.19167 3.90001 8.68799 4.40369 8.68799 5.02501Z"
        fill="#7B7E8C"
      />
      <path
        d="M25.668 5.02501V7.89001C25.668 8.51133 26.1716 9.01501 26.793 9.01501C27.4143 9.01501 27.918 8.51133 27.918 7.89001V5.02501C27.918 4.40369 27.4143 3.90001 26.793 3.90001C26.1716 3.90001 25.668 4.40369 25.668 5.02501Z"
        fill="#7B7E8C"
      />
      <path
        d="M29.5832 5.83496C29.3843 5.83496 29.1935 5.91398 29.0529 6.05463C28.9122 6.19528 28.8332 6.38605 28.8332 6.58496V7.75496C28.7898 8.25614 28.56 8.72282 28.1893 9.06283C27.8185 9.40285 27.3338 9.59149 26.8307 9.59149C26.3276 9.59149 25.8429 9.40285 25.4721 9.06283C25.1014 8.72282 24.8716 8.25614 24.8282 7.75496V6.59996C24.8282 6.40105 24.7492 6.21028 24.6085 6.06963C24.4679 5.92898 24.2771 5.84996 24.0782 5.84996H12.5582C12.3605 5.8538 12.172 5.93405 12.0321 6.07388C11.8923 6.21371 11.812 6.40225 11.8082 6.59996V7.78496C11.7346 8.26044 11.493 8.69375 11.1271 9.00619C10.7612 9.31862 10.2954 9.48945 9.81425 9.48764C9.33312 9.48582 8.8686 9.31148 8.50509 8.99629C8.14157 8.68111 7.90317 8.24598 7.8332 7.76996V6.59996C7.83525 6.50091 7.81724 6.40247 7.78027 6.31055C7.74331 6.21863 7.68814 6.13514 7.61808 6.06508C7.54803 5.99503 7.46453 5.93986 7.37261 5.90289C7.2807 5.86592 7.18225 5.84792 7.0832 5.84996C6.00359 5.86572 4.97355 6.30569 4.2157 7.07476C3.45786 7.84383 3.03309 8.88024 3.0332 9.95996V27.96C3.03302 29.0411 3.45731 30.0791 4.21475 30.8506C4.97219 31.622 6.00224 32.0653 7.0832 32.085H29.5832C30.1249 32.085 30.6613 31.9783 31.1618 31.771C31.6622 31.5637 32.117 31.2598 32.5 30.8768C32.8831 30.4937 33.1869 30.039 33.3942 29.5385C33.6015 29.0381 33.7082 28.5017 33.7082 27.96V9.95996C33.7082 9.41826 33.6015 8.88186 33.3942 8.38139C33.1869 7.88092 32.8831 7.42619 32.5 7.04315C32.117 6.6601 31.6622 6.35626 31.1618 6.14896C30.6613 5.94166 30.1249 5.83496 29.5832 5.83496ZM31.4582 27.96C31.4582 28.4572 31.2607 28.9342 30.909 29.2858C30.5574 29.6374 30.0805 29.835 29.5832 29.835H7.0832C6.58592 29.835 6.10901 29.6374 5.75738 29.2858C5.40575 28.9342 5.2082 28.4572 5.2082 27.96V14.46H31.4582V27.96Z"
        fill="#7B7E8C"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'DateIcon',
    props: {
      width: {
        type: Number,
        default: 25,
      },
      height: {
        type: Number,
        default: 25,
      },
    },
  };
  </script>