<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :style="`transform: rotate(${
      direct
        ? direct == 'left'
          ? -90
          : direct == 'right'
          ? 90
          : direct == 'down'
          ? 180
          : 0
        : 0
    }deg)`"
    viewBox="0 0 10 9"
    fill="none"
  >
    <path
      d="M9.61159 3.96587L5.86467 0.218955C5.79341 0.150731 5.70937 0.0972513 5.61738 0.0615849C5.43493 -0.0133668 5.23029 -0.0133668 5.04785 0.0615849C4.95586 0.0972513 4.87182 0.150731 4.80055 0.218955L1.05364 3.96587C0.983768 4.03574 0.928343 4.11869 0.890529 4.20998C0.852715 4.30127 0.833252 4.39912 0.833252 4.49793C0.833252 4.69749 0.912528 4.88888 1.05364 5.02999C1.19475 5.1711 1.38614 5.25038 1.5857 5.25038C1.78526 5.25038 1.97665 5.1711 2.11776 5.02999L4.58323 2.55703V8.24484C4.58323 8.44359 4.66218 8.6342 4.80272 8.77473C4.94326 8.91527 5.13386 8.99422 5.33261 8.99422C5.53136 8.99422 5.72197 8.91527 5.86251 8.77473C6.00304 8.6342 6.082 8.44359 6.082 8.24484V2.55703L8.54746 5.02999C8.61713 5.10023 8.70001 5.15598 8.79133 5.19402C8.88265 5.23207 8.9806 5.25166 9.07952 5.25166C9.17845 5.25166 9.2764 5.23207 9.36772 5.19402C9.45904 5.15598 9.54192 5.10023 9.61159 5.02999C9.68182 4.96033 9.73757 4.87744 9.77562 4.78612C9.81367 4.6948 9.83325 4.59686 9.83325 4.49793C9.83325 4.399 9.81367 4.30105 9.77562 4.20973C9.73757 4.11841 9.68182 4.03553 9.61159 3.96587Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "Arrow2",
  props: {
    width: {
      type: Number,
      default: 10,
    },
    height: {
      type: Number,
      default: 9,
    },
    direct: {
      type: String,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
