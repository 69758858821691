<template>
    <div class="ma-10">
      <Loading v-if="isLoading" />
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <div class="head-ctn">
        <h1>RIWAYAT KATEGORI EVENT</h1>
        <div style="display: flex; align-items: center; cursor: pointer;" @click="backToCategory">
            <h4>Kembali</h4>
        </div>
      </div>
      <hr class="line-strip" />
      <div v-for="(item, idx) in categories" :key="idx" class="box-category">
        <div style="width: 97%">
          <h2>{{ item.name }}</h2>
          <span>{{ item.description }}</span>
        </div>
        <div class="menu-act">
          <TripleElipse />
          <div class="menu-act-item" @click="pulihkanCategory(item.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
            </svg>
            <span>Pulihkan</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import getAuthentification from "@/utils/badrequest.js";
  import TripleElipse from "../../../components/icons/TripleElipse.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    name: "EventCategory",
    data() {
      return {
        categories: [],
        isLoading: false,
        message: "",
        isPopup: false, 
      };
    },
    components: {
      Loading,
      TripleElipse,
      Popup
    },
    methods: {
      async getDataCategories() {
         this.isLoading = true
         try {
              const resp = await this.$crm_http.get("/v1/event-category/deleted");
              this.categories = resp.data.data.event_categories;  
              this.isLoading = false
         } catch (err) {
              this.message = err.response.data.data
              this.isPopup = true 
              this.isLoading = false
         } 
      },
      async pulihkanCategory(id) {
          this.isLoading = true
          try {
              await this.$crm_http.put("/v1/event-category/restore/" + id, {});
              this.getDataCategories()
              this.isLoading = false
          } catch (err) {
              this.message = err.response.data.data
              this.isPopup = true
              this.isLoading = false
          }
      },
      backToCategory() {
        this.$router.push('/event-category')
        },
    },
    beforeCreate() {
      getAuthentification();
    },
    mounted() {
      this.getDataCategories();
    },
  };
  </script>
  <style scoped>
  .head-ctn {
    display: flex;
    justify-content: space-between;
  }
  .line-strip {
    margin: 30px 0;
  }
  .box-category {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 30px 50px;
    background-color: #ffffff;
    border-radius: 20px;
  }
  .menu-act {
    width: 3%;
    position: relative;
    cursor: pointer;
  }
  .menu-act:hover .menu-act-item {
    display: flex;
  }
  .menu-act-item {
    display: none;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    height: 40px;
    width: 150px;
    border-radius: 12px;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.2);
    padding: 2px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translate(-80%, 70%);
  }
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
      text-align: left;
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .action-menu {
    display: flex; gap: 20px; color: #ACAFBF;
  }
  .action-riwayat {
    border: 1px solid;
    border-radius: 16px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  </style>
  