<template>
  <svg width="52" height="1" viewBox="0 0 52 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="0.5" x2="52" y2="0.5" stroke="#F8F8F8" />
  </svg>
</template>

<script>
export default {
  name: 'Garis',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
