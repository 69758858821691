<template>
    <div class="body-form">
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="body-form-ctn">
      <div class="watch-event-details">
        <div class="navigation">
          <a href="/event-category">Kategori Event</a>
          <ArrowIcon style="transform: rotate(90deg)" />
          <a :href="`/event-category/${$route.params.id_category}/event`">Webinar</a>
          <ArrowIcon style="transform: rotate(90deg)" />
          <a href="#" style="color: #acafbf">Detail Event</a>
        </div>
        <div class="event-title">
          <h1>{{ event.name }}</h1>
        </div>
        <div class="event-status">
          <v-chip :color="event.is_active ? 'green' : 'red'" dark>
            {{ event.is_active ? 'Aktif' : 'Tidak Aktif' }}
          </v-chip>
        </div>
        <div class="event-info">
          <div class="event-info-item">
            <span class="event-info-title">Info Event</span>
            <p>
              {{ event.description }}
            </p>
          </div>
          <table class="event-table">
            <div class="header-event">
              <div style="display: flex; align-items: center; gap: 10px">
                <PeopleIcon />
                <span>{{ event.participant_quota ? event.participant_quota + " Peserta" : "Tidak Terbatas" }}</span>
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                <DateIcon />
                <span>{{ event.total_session }} Sesi</span>
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                <TrainMethodIcon />
                <span>{{ event.method === "Online" ? "Daring (Online Via Zoom)" : "Luring" }}</span>
              </div>
            </div>
            <tr>
              <td colspan="3">
                <span class="icon"><ClockIcon /></span>
                <span>Waktu Pelaksanaan</span>
                <div class="list">
                  <ul>
                    <li v-for="(item, idx) in event.session_schedules" :key="idx">
                      <div style="display: flex; gap: 15px; align-items: flex-start;">
                        <span style="min-width: 150px;">{{ moment(item.date_start) }} {{ item.date_end ? "- " + moment(item.date_end) : "" }}</span> <span>: {{ item.start_time }} - {{ item.end_time }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr class="no-border">
              <td colspan="3">
                <span class="icon"><LinkIcon v-if="!event.location_name" /> <MapIcon v-else/></span>
                <span>Link Pelaksanaan Event</span>
                <div class="list" v-if="!event.location_name">
                  <span class="link-c" @click="redirect(event.learning_url)">{{ maxStrLength(event.learning_url, 25) }}</span>
                  <CopyIcon @click.native="copyToClipboard(event.learning_url)" class="copy-icon" :width="20" :height="20" />
                </div>
                <div class="list" v-else>
                  <p style="margin: 0">{{ event.location_name }}</p>
                  <p>{{ event.location_address }}</p>
                  <div>
                    <span class="link-c" @click="redirect(event.location_map)">{{ event.location_map }}</span>
                    <CopyIcon @click.native="copyToClipboard(event.location_map)" class="copy-icon" :width="20" :height="20" />
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <p class="berkas-title mt-10">Tiket</p>
          <div class="data-row">
            <span>Harga Tiket</span>
            <span style="font-weight: bold">{{ event.price ? toCurrency(event.price) : "Gratis"}}</span>
          </div>
          <div class="data-row">
            <span>Promo Harga</span>
            <span class="promo-harga" v-if="event.voucher_id">
              Promo Early Bird
              <br />
              Sampai {{ moment(event.voucher.end_date, true) }}
            </span>
            <span v-else>Tidak ada promo</span>
          </div>
          <div class="data-row">
            <span>Batas Pembelian Tiket</span>
            <span>{{ moment(event.registration_close_date, true) }}</span>
          </div>
          <p class="berkas-title">Desain Banner</p>
          <h5>Website Desktop</h5>
          <div class="data-row">
            <span>Banner Thumbnail</span>
            <div>
              <span class="link-c" @click="redirect(event.img_urls.desktop.thumbnail)">
                {{ maxStrLength(event.img_urls.desktop.thumbnail, 25) }}
              </span>
              <CopyIcon @click.native="copyToClipboard(event.img_urls.desktop.thumbnail)" class="copy-icon" />
            </div>
          </div>
          <div class="data-row">
            <span>Banner Detail Produk</span>
            <div>
              <span class="link-c" @click="redirect(event.img_urls.desktop.product_detail)">
                {{ maxStrLength(event.img_urls.desktop.product_detail, 25) }}
              </span>
              <CopyIcon @click.native="copyToClipboard(event.img_urls.desktop.product_detail)" class="copy-icon" />
            </div>
          </div>
          <div class="data-row">
            <span>Banner Rincian Pembelian</span>
            <div>
              <span class="link-c" @click="redirect(event.img_urls.desktop.purchase_detail)">
                {{ maxStrLength(event.img_urls.desktop.purchase_detail, 25) }}
              </span>
              <CopyIcon @click.native="copyToClipboard(event.img_urls.desktop.purchase_detail)" class="copy-icon" />
            </div>
          </div>
          <br />
          <h5>Website Mobile</h5>
          <div class="data-row">
            <span>Banner Thumbnail</span>
            <div>
              <span class="link-c" @click="redirect(event.img_urls.mobile.thumbnail)">
                {{ maxStrLength(event.img_urls.mobile.thumbnail, 25) }}
              </span>
              <CopyIcon @click.native="copyToClipboard(event.img_urls.mobile.thumbnail)" class="copy-icon" />
            </div>
          </div>
          <div class="data-row">
            <span>Banner Detail Produk</span>
            <div>
              <span class="link-c" @click="redirect(event.img_urls.mobile.product_detail)">
                {{ maxStrLength(event.img_urls.mobile.product_detail, 25) }}
              </span>
              <CopyIcon @click.native="copyToClipboard(event.img_urls.mobile.product_detail)" class="copy-icon" />
            </div>
          </div>
          <div class="data-row">
            <span>Banner Rincian Pembelian</span>
            <div>
              <span class="link-c" @click="redirect(event.img_urls.mobile.purchase_detail)">
                {{ maxStrLength(event.img_urls.mobile.purchase_detail, 25) }}
              </span>
              <CopyIcon @click.native="copyToClipboard(event.img_urls.mobile.purchase_detail)" class="copy-icon" />
            </div>
          </div>
          <div class="event-narasumber-item">
            <p class="narasumber-title">Narasumber</p>
            <div class="narasumber-info mt-5" v-for="(item, idx) in event.instructors" :key="idx">
              <div>
                <img class="narasumber-img" :src="item.image_url" />
              </div>
              <div>
                <p class="narasumber-name">{{ item.name }}</p>
                <p class="fullstack-info">{{ item.company }}</p>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
          <div class="event-benefit-item">
            <p class="benefit-label">Benefit</p>
            <div class="benefit-list" v-for="(benefit, index) in event.benefits" :key="index">
              <span class="icon"><VerifiedIcon /></span>
              <span>{{ benefit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PeopleIcon from "@/components/icons/People.vue"
import ArrowIcon from '@/components/icons/Arrow.vue';
import ClockIcon from '@/components/icons/Clock.vue';
import LinkIcon from '@/components/icons/Link.vue';
import DateIcon from '@/components/icons/Date.vue';
import TrainMethodIcon from '@/components/icons/TrainMethod.vue';
import VerifiedIcon from '@/components/icons/Verified.vue';
import CopyIcon from '@/components/icons/Copy.vue';
import MapIcon from "@/components/icons/Map.vue";
import moment from "moment";
import Popup from "@/components/others/Popup.vue";

export default {
  name: 'WatchEvent',
  components: {
    ArrowIcon,
    PeopleIcon,
    ClockIcon,
    LinkIcon,
    DateIcon,
    TrainMethodIcon,
    VerifiedIcon,
    CopyIcon,
    MapIcon,
    Popup
  },
  data() {
    return {
      eventData: {
        title: '',
        status: false,
        info_desc: '',
        total_peserta: '',
        sesi: '',
        method: '',
        tgl_pelaksanaan: [''],
        link: '',
        harga_tiket: '',
        message: "",
        isPopup: false,
        promo_harga: [
          {
            promo: 'Promo Early Bird',
            waktu: '(Sampai Sabtu, 14 Oktober 2023)',
          },
        ],
        batas_pembelian_tiket: '',
        banner_thumbnail: '',
        banner_detail_produk: '',
        banner_rincian_pembelian: '',
        banner_thumbnail_mobile: '',
        banner_detail_produk_mobile: '',
        banner_rincian_pembelian_mobile: '',
        benefits: [''],
      },
      event: {
        syllabuses: [],
        instructors: [],
        session_schedules: [],
        benefits: [],
        category: {},
        voucher: {},
        img_urls: {
          desktop: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
          mobile: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: ""
          }
        }
      }
    };
  },
  methods: {
    async getDataEvent() {
      try {
        const resp = await this.$web_http.get('/v1/event/' + this.$route.params.id)
        const data = resp.data.data.event
        this.event = {
          ...data,
          session_schedules: data.session_schedules ? data.session_schedules: []
        }
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    moment(date, typeLine) {
      if (typeLine) return moment(date).format("dddd, DD MMMM YYYY");
      return moment(date).format("dddd, DD/MM/YYYY");
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
      });
      return formatter.format(value);
    },
    redirect(link) {
      window.open(link)
    },
    maxStrLength(str, max) {
      if (str.length > max) {
        return str.substring(0, max) + '...';
      }
      return str;
    },
  },
  mounted() {
    this.getDataEvent()
  }
}
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e8f0;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.body-form-ctn {
    width: 50%;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
}
.navigation {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.navigation a {
  text-decoration: none;
  
}

.watch-event-details {
  margin-top: 20px;
  padding: 0 40px;
}

.watch-event-details h5 {
  font-weight: normal;
  margin-bottom: 8px;
  font-size: 16px;
}

.event-status {
  display: flex;
  align-items: center;
}

.status-text {
  font-size: 14px;
}

.status-icon {
  font-size: 16px;
  cursor: pointer;
}

.event-info {
  margin-top: 20px;
}

.event-info-item {
  margin-bottom: 10px;
}

.event-info-item span {
  font-size: 18px;
  font-weight: bold;
}
.event-info-item p {
  margin: 20px 0;
}

.event-table {
  width: 100%;
  border: 1px solid #acafbf;
  border-radius: 24px;
}

.header-event {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
}

.event-table td {
  padding: 14px;
}

.event-table td span {
  padding: 10px;
}

.event-table td .icon {
  vertical-align: middle;
  display: inline-block;
}

.event-table td .list {
  padding-left: 55px;
}

.event-table .list ul li {
  margin: 10px 0;
}

.event-table tr:not(.no-border) {
  position: relative;
}
.event-table tr:not(.no-border)::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(to right, #acafbf, #acafbf 7px, transparent 7px, transparent 10px);
}

.info-label {
  font-size: 14px;
  font-weight: bold;
}

.berkas-title,
.event-title,
.narasumber-title,
.benefit-label {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.event-narasumber-item img {
  max-width: 400px;
  height: auto;
  border-radius: 90%;
  overflow: hidden;
  object-fit: cover;
}

.narasumber-img {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50%;
}

.narasumber-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.fullstack-info {
  margin-bottom: 20px;
  color: #acafbf;
}

.narasumber-info {
  gap: 20px;
  display: flex;
}

.benefit-list {
  display: flex;
  text-align: center;
  justify-items: center;
  align-items: center;
  padding-bottom: 10px;
}

.benefit-list .icon {
  margin-right: 10px;
}

.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.copy-icon {
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

.link-c {
  color: #162CA2;
  cursor: pointer;
}
.promo-harga {
  text-align: right;
}
</style>