<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3334 17H6.66675C6.40153 17 6.14718 16.8946 5.95964 16.7071C5.7721 16.5196 5.66675 16.2652 5.66675 16C5.66675 15.7348 5.7721 15.4804 5.95964 15.2929C6.14718 15.1054 6.40153 15 6.66675 15H25.3334C25.5986 15 25.853 15.1054 26.0405 15.2929C26.2281 15.4804 26.3334 15.7348 26.3334 16C26.3334 16.2652 26.2281 16.5196 26.0405 16.7071C25.853 16.8946 25.5986 17 25.3334 17Z"
      fill="#7B7E8C"
    />
  </svg>
</template>
<script>
export default {
  name: "PeopleIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
