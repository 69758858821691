<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="top-container-box">
      <h1>Reporting Log</h1>
      <div>
        <Button
          icon=""
          outline
          name="Lihat data yang belum terkirim"
          @click="() => $router.push('/reporting-log/draft')"
          width="300px"
        />
      </div>
    </div>
  
    <v-data-table
      :headers="headers"
      :items="reportingLogs"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>
    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getReportingLog"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{ Math.floor((currentPage - 1) * this.perPage + reportingLogs.length) }} dari
        {{ totalData }}</b
      >
      Reporting Log yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import Loading from "@/components/ilustration/Loading.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      message: "",
      isPopup: false,
      totalData: 0,
      perPage: 10,
      filter: {
        sort_by: "DESC",
        category: "",
      },
      showFilterModal: false,
      filterModal: [
        {
          title: "Kategori",
          name: "category",
          items: [
            {
              name: "Semua",
              value: "",
            },
            {
              name: "Dp Pijar",
              value: "PIJAR",
            },
          ],
        },
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        { text: "TODO", value: "action", width: "350" },
        { text: "BATCH", value: "batch" },
        { text: "TOTAL DATA TERKIRIM", value: "sent_total" },
        { text: "KATEGORI", value: "category" },
        { text: "TANGGAL", value: "date", width: "111" },
        { text: "AKSI", value: "actions", sortable: false, width: "132" },
      ],
      reportingLogs: [],
    };
  },
  components: {
    Button,
    Loading,
    WatchTable,
    NoDataAlert,
    Popup
  },
  methods: {
    applyFilters() {
      this.getReportingLog();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getReportingLog() {
      await this.$crm_http
        .get(
          `/v1/reporting-log?page=${this.currentPage}&per_page=${this.perPage}&category=${this.filter.category}&sort_by=${this.filter.sort_by}`
        )
        .then((response) => {
          this.reportingLogs = response.data.data.reporting_logs || [];
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / this.perPage);
          this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    watchItem(item) {
      this.$router.push("/reporting-log/" + item.id);
    },
    clearFilter() {
      this.filter = {
        category: "",
        sort_by: "desc",
      };
      this.getReportingLog();
    },
    handleClearButtonClick() {
      this.filter = {
        category: "",
        sort_by: "desc",
      };
    },
  },
  mounted() {
    this.getReportingLog();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>
