<template>
  <!-- <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 16.5H5.75C2.75 16.5 2 15.75 2 12.75V5.25C2 2.25 2.75 1.5 5.75 1.5H6.875C8 1.5 8.24752 1.83001 8.67502 2.40001L9.80002 3.90001C10.085 4.27501 10.25 4.5 11 4.5H13.25C16.25 4.5 17 5.25 17 8.25V9.75"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8199 13.74C9.05742 13.8675 9.05742 16.4175 10.8199 16.545H14.9899C15.4924 16.545 15.9874 16.3575 16.3549 16.02C17.5924 14.94 16.9324 12.78 15.3049 12.5775C14.7199 9.06 9.63494 10.395 10.8349 13.7475"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 11.5C2.5 7.25768 2.5 5.13558 3.81779 3.81779C5.13558 2.5 7.25674 2.5 11.5 2.5C15.7423 2.5 17.8644 2.5 19.1822 3.81779C20.5 5.13558 20.5 7.25674 20.5 11.5C20.5 15.7423 20.5 17.8644 19.1822 19.1822C17.8644 20.5 15.7433 20.5 11.5 20.5C7.25768 20.5 5.13558 20.5 3.81779 19.1822C2.5 17.8644 2.5 15.7433 2.5 11.5Z"
      stroke="currentColor"
      stroke-opacity="0.972549"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.5256 7.71053L7.76444 8.95821C7.37602 9.23295 7.18181 9.37126 7.25002 9.48874C7.31823 9.60526 7.59297 9.60526 8.14244 9.60526H15.7631M13.4742 15.2895L15.2354 14.0418C15.6238 13.7671 15.818 13.6287 15.7498 13.5113C15.6816 13.3947 15.4069 13.3947 14.8574 13.3947H7.23676"
      stroke="currentColor"
      stroke-opacity="0.972549"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "TransactionIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
