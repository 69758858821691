<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1>{{ name }}</h1>
      <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs">
      </v-breadcrumbs>
      <div v-if="name == 'Edit Completion'">
        <div class="content-form">
          <p>Voucher Code</p>
          <v-text-field
            outlined
            v-model="form.voucher_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Completion Date</p>
          <v-text-field
            outlined
            v-model="form.completion_date"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Course Code</p>
          <v-text-field
            outlined
            v-model="form.course_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Invoice Code</p>
          <v-text-field
            outlined
            v-model="form.invoice_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Pre Score</p>
          <v-text-field
            outlined
            v-model="form.pre_test"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Post Score</p>
          <v-text-field
            outlined
            v-model="form.post_score"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Certificate URL</p>
          <v-text-field
            outlined
            v-model="form.certificate_url"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Status</p>
          <v-select
            class="form-select"
            :items="status"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.status"
            outlined
            :readonly="watchMode"
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Batch</p>
          <v-text-field
            outlined
            v-model="form.batch"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Category</p>
          <v-text-field
            outlined
            v-model="form.category"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Skill test</p>
          <v-text-field
            outlined
            v-model="form.skill_test"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
      </div>
      <div v-else>
        <div class="content-form">
          <p>Voucher Code</p>
          <v-text-field
            outlined
            v-model="form.voucher_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Price</p>
          <v-text-field
            outlined
            v-model="form.price"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Redeem Code</p>
          <v-text-field
            outlined
            v-model="form.redeem_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Invoice Number</p>
          <v-text-field
            outlined
            v-model="form.invoice_number"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Course Code</p>
          <v-text-field
            outlined
            v-model="form.course_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Schedule Code</p>
          <v-text-field
            outlined
            v-model="form.schedule_code"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Schedule</p>
          <v-text-field
            outlined
            v-model="form.schedule"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Status</p>
          <v-select
            class="form-select"
            :items="status"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.status"
            outlined
            :readonly="watchMode"
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Batch</p>
          <v-text-field
            outlined
            v-model="form.batch"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Category</p>
          <v-text-field
            outlined
            v-model="form.category"
            class="form-input"
            dense
            readonly
          ></v-text-field>
        </div>
      </div>
      <div class="submit-box" v-if="!watchMode">
        <Button
          name="Batalkan"
          outline
          class="me-3"
          @click="$router.push('/reconciliation')"
        />
        <Button
          name="Simpan"
          width="156px"
          @click="onSubmit"
          style="background-color: #3b54d9; color: #ffffff"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import moment from "moment";
import Button from "../../../components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      name: "",
      watchMode: false,
      isLoading: false,
      voucher_code: "",
      form: {},
      message: "",
      isPopup: false,
      status: [
        { name: "Draft", value: "DRAFT" },
        { name: "Pending", value: "PENDING" },
        { name: "Sent", value: "SENT" },
      ],
      breadcrumbs: [
        {
          text: "Master Data",
          href: "/reconciliation",
        },
        {
          text: "Reconciliation",
          href: "/reconciliation",
        },
      ],
    };
  },
  methods: {
    checkingType() {
      if (this.$route.query.watch) {
        this.watchMode = true
      }
      this.name = this.$route.meta.name;
      
      this.breadcrumbs.push({
        text: this.$route.meta.name,
        readonly: true,
      });
      this.voucher_code = this.$route.params.voucher_code;
      if (this.$route.path.includes("completion")) {
        this.getCompletion();
      } else {
        this.getRedemption();
      }
    },
    async getCompletion() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get(
          "/v1/completion-report/voucher-code/" + this.voucher_code
        );
        this.form = resp.data.data.completion_report;
        this.form.completion_date = moment(this.form.completion_date).format(
          "YYYY-MM-DD HH:mm"
        );
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async getRedemption() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get(
          "/v1/redemption-report/voucher-code/" + this.voucher_code
        );
        this.form = resp.data.data.redemption_report;
        this.form.completion_date = moment(this.form.completion_date).format(
          "YYYY-MM-DD HH:mm"
        );
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        if (this.$route.path.includes("completion")) {
          await this.$crm_http.put(
            "/v1/completion-report/" + this.voucher_code,
            { status: this.form.status }
          );
        } else {
          await this.$crm_http.put(
            "/v1/redemption-report/" + this.voucher_code,
            { status: this.form.status }
          );
        }
        setTimeout(() => {
          this.$router.push("/reconciliation");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
  },
  components: {
    Loading,
    Button,
    Popup
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fd;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  width: 50%;
  border-radius: 30px;
  padding: 30px 40px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}

.breadcrumbs {
  padding: 0px;
  margin-left: 0px;
  margin-bottom: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 14px;
  margin: 3px 0;
  font-weight: 400;
}

.form-input {
  border-radius: 10px !important;
}

::v-deep .form-input .v-input__slot {
  height: 62px !important;
  margin-bottom: 20px;
  background-color: #ffffff !important;
}

::v-deep .form-input .v-text-field__details {
  display: none;
}

::v-deep .form-select .v-input__slot {
  height: 56 !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

.submit-box {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
</style>
