<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus event ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <Modal v-if="isModal2" @close="() => (isModal2 = false)" width="40%">
      <h2 class="text-center">Edit Kategori Event</h2>
      <div class="content-form mt-10">
        <p>Kategori Event</p>
        <v-text-field
          outlined
          v-model="form.name"
          class="form-input"
          placeholder="Masukkan Nama Kategori Event"
          dense
          clearable
          :rules="[!!form.name || 'Wajib diisi!']"
        ></v-text-field>
      </div>
      <div class="content-form">
        <p>Deskripsi</p>
        <v-textarea
          placeholder="Tulis deskripsi ...."
          outlined
          rows="3"
          v-model="form.description"
          :rules="[!!form.description || 'Wajib diisi!']"
          clearable
          class="form-input"
        ></v-textarea>
      </div>
      <div style="display: flex; justify-content: center">
        <Button
          :disabled="validForm"
          name="SIMPAN PERUBAHAN"
          width="230px"
          @click="submitCategory"
        />
      </div>
    </Modal>
    <div class="menus-top">
      <span class="blue-text" @click="$router.push('/event-category')"
        >Kategori Event</span
      >
      <Arrow
        :width="20"
        :height="20"
        direct="left"
        strokeColor="#7B7E8C"
        class="icon"
      />
      <span style="color: #7b7e8c">{{ category.name }}</span>
    </div>

    <div class="head-info">
      <div class="head-info-ttl">
        <div style="display: flex; gap: 10px; align-items: center">
          <h1>{{ category.name }}</h1>
          <div style="cursor: pointer" @click="editCategory">
            <pencil :height="30" />
          </div>
        </div>
        <p>{{ category.description }}</p>
      </div>
      <Button
        icon="+"
        name="Create Event"
        outline
        style="background-color: #3b54d9; color: #ffffff; width: 180px"
        @click="() => $router.push(`/event-category/${category_id}/event/new`)"
      />
    </div>
    <!-- <div class="container-box">
      <div class="action-head">
        <div style="display: flex; gap: 15px;">
          <div>
            <span>Cari</span>
            <div class="f-search">
                <div class="f-search-ic">
                  <img
                    src="/img/icons/search.png"
                    style="width: 100%"
                    alt="Search Ic"
                  />
                </div>
                <input
                  v-model="search"
                  type="text"
                  class="f-input"
                  placeholder="Cari"
                />
            
            </div>
          </div>
          
          <div>
            <span>Urutkan</span>
            <v-select
              :disabled="isWatch"
              :items="listSort"
              v-model="sortBy"
              solo
              style="border-radius: 16px; width: 200px"
              :item-text="'name'"
              :item-value="'value'"
            >
            </v-select>
          </div>
        </div>
        <div style="display: flex; gap: 10px">
          <Button name="Clear" outline @click="clearFilter" />
          <Button name="TERAPKAN FILTER" @click="getEvents" width="210px" />
        </div>
      </div>
    </div> -->
    <v-row>
      <!-- Search  -->
      <v-col>
        <div class="action-head">
          <div class="f-search">
            <!-- Input -->
            <div>
              <v-text-field
                v-model="filter.name"
                type="text"
                class="f-input"
                placeholder="Search..."
                clearable
                dense
                flat
                solo
                style="
                  padding-top: 5px;
                  padding-left: 5px;
                  width: 448px;
                  border-radius: 14px;
                "
                @input="applyFilters"
              ></v-text-field>
            </div>
            <!-- Icon -->
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
          </div>
        </div>
      </v-col>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="events"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <v-chip :color="item.is_active ? 'green' : 'red'" dark>
          {{ item.is_active ? "Aktif" : "Non Aktif" }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="totalData" />

    <v-row justify="center" v-if="total">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getEvents"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p class="count-data" v-if="totalData">
      <b>{{ shown }} dari {{ totalData }}</b>
      event yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Pencil from "@/components/icons/Pencil.vue";
import Arrow from "@/components/icons/Arrow.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import NewModal from "@/components/NewModal.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      isModal2: false,
      currentPage: 1,
      isLoading: false,
      total: 0,
      totalData: 0,
      shown: 0,
      id: 0,
      category_id: 0,
      message: "",
      isPopup: false,
      form: {
        name: "",
        description: "",
      },
      category: {
        name: "",
        description: "",
      }, // important
      headers: [
        {
          text: "NO",
          value: "id",
        },
        { text: "Judul", value: "name",width: "350" },
        { text: "TANGGAL PELAKSANAAN", value: "date", width: "205" },
        { text: "KUOTA PESERTA", value: "participant_quota", width: "141" },
        { text: "KATEGORI TIKET", value: "price", width: "140" },
        { text: "STATUS", value: "is_active" },
        { text: "Aksi", value: "actions", sortable: false, width: "132" },
      ],
      listSort: [
        { name: "Terlama", value: "asc" },
        { name: "Terbaru", value: "" },
      ],
      events: [],
      filter: {
        name: "",
        sort_by: "",
      },
      showFilterModal: false,
      filterModal: [
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
    };
  },
  computed: {
    validForm() {
      if (this.form.name && this.form.description) return false;
      return true;
    },
  },
  components: {
    Button,
    Modal,
    Loading,
    Pencil,
    Arrow,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    NewModal,
    FilterIcon,
    Popup,
    FilterModal,
  },
  methods: {
    applyFilters() {
      this.getEvents();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getCategory() {
      try {
        const resp = await this.$crm_http.get(
          `/v1/event-category/${this.category_id}`
        );
        this.category = resp.data.data.event_category;
        this.getEvents();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getEvents() {
      await this.$crm_http
        .get(
          `/v1/event?name=${this.filter.name}&category=${this.category.name}&sort_by=${this.filter.sort_by}&page=${this.currentPage}&limit=10`
        )
        .then((response) => {
          if (!response.data.data.events) {
            this.events = [];
            return;
          }
          this.events = response.data.data.events.map((e) => ({
            ...e,
            participant_quota: e.participant_quota
              ? e.participant_quota
              : "Tidak Terbatas",
            price: e.price ? "Berbayar" : "Gratis",
            date:
              this.$helpers.getDate(e.start_date) +
              " - " +
              this.$helpers.getDate(e.end_date),
            time: e.start_time + " - " + e.end_time + " WIB",
          }));
          const t = parseInt(response.data.data.total);
          this.total = t / 10 + (t % 10 === 0 ? 0 : 1);
          this.totalData = response.data.data.total;
          this.shown = response.data.data.shown;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    watchItem(item) {
      this.$router.push(
        `/event-category/${this.category_id}/event/watch/${item.id}`
      );
    },
    editItem(item) {
      this.$router.push(
        `/event-category/${this.category_id}/event/edit/${item.id}`
      );
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    editCategory() {
      this.form = this.category;
      this.isModal2 = true;
    },
    async submitCategory() {
      try {
        await this.$crm_http.put(
          "/v1/event-category/" + this.category_id,
          this.form
        );
        this.isModal2 = false;
        this.getCategory();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete("/v1/event/" + this.id);
        this.isModal = false;
        this.isLoading = false;
        this.getEvents();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.category_id = this.$route.params.id_category;
    this.getCategory();
  },
};
</script>

<style scoped>
.action-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.head-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.head-info-ttl {
  width: 80%;
  margin-bottom: 30px;
}
.count-data {
  position: relative;
  top: -60px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}
.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  text-align: left;
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
</style>
