<template>
  <div class="ma-10">
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <Loading v-if="isLoading" />
    <div class="top-container-box">
      <h1>User Schedule</h1>
    </div>
    <div class="container-box">
      <v-row>
        <v-col>
          Status
          <v-select
            style="width: 70%; height: 7%; margin-top: 5px"
            label="Status"
            v-model="filter.status"
            :items="statuses"
            :item-text="'name'"
            :item-value="'value'"
            class="form-input"
            solo
          ></v-select>
        </v-col>
        <v-col>
          Pilih durasi tanggal kelas
          <v-select
            style="width: 90% !important; margin-top: 5px; border-radius: 16px"
            v-model="filter.dates"
            :items="sheduleDateOption"
            placeholder="Pilih durasi"
            :item-text="'dates'"
            :item-value="'dates'"
            class="form-input"
            solo
          ></v-select>
        </v-col>
        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 25px;
          "
        >
          <Button name="RESET FILTER" outline @click="clearFilter" />
          <Button name="TERAPKAN FILTER" @click="onFilter" width="190px" />
        </v-col>
      </v-row>
    </div>
    <v-data-table
      :headers="headers"
      :items="schedules"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.status="{ item }">
        <div style="display: flex; gap: 10px">
          <v-switch
            v-model="item.status"
            @change="onUpdateStatus(item)"
            color="primary"
            hide-details
            style="margin-top: -5px"
          ></v-switch>
          <v-chip :color="item.status ? 'green' : 'red'" dark>
            {{ item.status ? "Di Kelas" : "Tamat" }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getSchedule"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p class="count-data" v-if="totalData">
      <b>{{ shown }} dari {{ totalData }}</b>
      event yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import NoDataAlert from "@/components/NoDataAlert.vue";
import EditTable from "@/components/icons/EditTable.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      currentPage: 1,
      total: 0,
      totalData: 0,
      shown: 0,
      search: "",
      isLoading: false,
      message: "",
      isPopup: false,
      filter: {
        status: "",
        dates: "",
      },
      statuses: [
        {
          name: "Semua",
          value: "",
        },
        {
          name: "Aktif",
          value: "true",
        },
        {
          name: "Tidak Aktif",
          value: "false",
        },
      ],
      headers: [
        { text: "Nama Peserta", value: "full_name", width: "150" },
        { text: "Nama Kelas", value: "name", width: "300" },
        { text: "Durasi Kelas", value: "dates", width: "215" },
        { text: "Metode pembelajaran", value: "learning_method" },
        { text: "Level", value: "level" },
        { text: "Status", value: "status" },
        { text: "AKSI", value: "actions", sortable: false },
      ],
      schedules: [],
      sheduleDateOption: [],
    };
  },
  components: {
    Button,
    Loading,
    NoDataAlert,
    EditTable,
    Popup
  },
  methods: {
    async getSchedule() {
      this.schedules = [];
      await this.$lms_http
        .get(
          `/v1/user-schedule/dashboard?status=${this.filter.status}&date=${this.filter.dates}&page=${this.currentPage}&per_page=10`
        )
        .then(async (response) => {
          const data = response.data.data;
          for (let i = 0; i < data.schedules.length; i++) {
            const item = data.schedules[i];
            const user = await this.getUserId(item.user_id);
            this.schedules.push({
              ...item,
              full_name: user.full_name,
            });
          }
          const t = parseInt(data.total);
          this.total = Math.floor(t / 10 + (t % 10 === 0 ? 0 : 1));
          this.totalData = data.total;
          this.shown = data.n;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    async onUpdateStatus(item) {
      try {
        await this.$lms_http.put("/v1/user-schedule/status", {
          status: item.status,
          user_id: item.user_id,
          class_id: item.class_id,
        });
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getScheduleDate() {
      try {
        const resp = await this.$lms_http.get("/v1/user-schedule/option-date");
        this.sheduleDateOption = resp.data.data.schedule_option;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getUserId(id) {
      try {
        const response = await this.$crm_http.get("/v1/user/" + id);
        return response.data.data.user;
      } catch (err) {
        return {
          full_name: "",
        };
      }
    },
    editItem(item) {
      this.$router.push(
        `/user-schedule/edit?class_id=${item.class_id}&user_id=${item.user_id}`
      );
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    onFilter() {
      this.getSchedule();
    },
    clearFilter() {
      this.filter = {
        status: "",
        dates: "",
      };
      this.getSchedule();
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getSchedule();
    this.getScheduleDate();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>
