<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
        {{ name }}
      </h1>
      <div class="form-ctn">
        <p class="info-head">Info Event</p>
        <div class="content-form">
          <p>Nama Event</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.name"
            class="form-input"
            placeholder="Masukkan Nama Event"
            dense
            clearable
            :rules="[!!form.name || 'Wajib diisi!']"
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Status Event</p>
          <div class="spacing-switch">
            <v-switch
              v-model="form.is_active"
              color="primary"
              hide-details
              style="margin-top: -5px"
            ></v-switch>
            <div>
              <Chip :active="form.is_active" />
            </div>
          </div>
        </div>
        <div class="content-form mt-5">
          <p>Caption</p>
          <v-textarea
            placeholder="Tulis caption ...."
            :disabled="isWatch"
            outlined
            rows="3"
            v-model="form.caption"
            clearable
            class="form-input"
          ></v-textarea>
        </div>
        <div class="content-form ">
          <p>Deskripsi Event</p>
          <vue-editor
          v-model="form.description"
          :editorToolbar="$helpers.customToolBar()"
          class="form-input"
          placeholder="Tulis Deskripsi ...."
          v-if="!isWatch"
        ></vue-editor>
        <div v-else class="watch-ctn">
          <div v-html="form.description"></div>
        </div>
        </div>
        <div class="content-form mt-4">
          <p>Kuota Peserta</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="typeLimitEvent"
            v-model="limitOfEvent"
            solo
            :item-text="'name'"
            :item-value="'value'"
          >
          </v-select>
        </div>
        <div class="content-form" v-if="limitOfEvent">
          <p>Batas Kuota</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.participant_quota"
            class="form-input"
            type="number"
            min="1"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Sesi Pelaksanaan</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="listSessionSchedule"
            v-model="sessionSchedule"
            solo
            @change="checkScheduleType"
            :item-text="'name'"
            :item-value="'value'"
          >
          </v-select>
        </div>
        <div v-for="(item, idx) in form.session_schedules" :key="idx">
          <div v-if="sessionSchedule === 2" class="session-numb">
            <p style="font-weight: bold">Sesi {{ idx + 1 }}</p>
            <div
              @click="form.session_schedules.splice(idx, 1)"
              style="cursor: pointer"
              v-if="idx != 0"
            >
              <strip />
            </div>
          </div>
          <div class="content-form">
            <p>Hari & Tanggal Pelaksanaan</p>
            <div class="container-date pt-2" v-if="sessionSchedule === 1">
              <div class="container-date-left">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Mulai </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :disabled="isWatch"
                      outlined
                      type="date"
                      :rules="[!!item.date_start || 'Wajib diisi!']"
                      v-model="item.date_start"
                      class="form-input"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="container-date-rigth">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Selesai </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :disabled="isWatch"
                      outlined
                      type="date"
                      :rules="[!!item.date_end || 'Wajib diisi!']"
                      v-model="item.date_end"
                      class="form-input"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-text-field
              v-else
              :disabled="isWatch"
              outlined
              type="date"
              :rules="[!!item.date_start || 'Wajib diisi!']"
              v-model="item.date_start"
              class="form-input"
              dense
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Jam Pelaksanaan</p>
            <div class="container-date pt-2">
              <div class="container-date-left">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Mulai </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :disabled="isWatch"
                      outlined
                      type="time"
                      :rules="[!!item.start_time || 'Wajib diisi!']"
                      v-model="item.start_time"
                      class="form-input"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="container-date-rigth">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Selesai </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :disabled="isWatch"
                      outlined
                      type="time"
                      :rules="[!!item.end_time || 'Wajib diisi!']"
                      v-model="item.end_time"
                      class="form-input"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
        <div
          class="add-button"
          @click="addSessionSchedlue"
          v-if="sessionSchedule === 2 && !isWatch"
        >
          <div class="line"></div>
          <div class="button">+ Tambah Sesi</div>
          <div class="line"></div>
        </div>
        <div class="content-form">
          <p>Materi Silabus</p>
          <div class="spacing-switch">
            <v-switch
              v-model="form.isSyllabuses"
              color="primary"
              hide-details
              style="margin-top: -5px"
            ></v-switch>
          </div>
        </div>
        <div class="content-form mt-5" v-if="form.isSyllabuses">
          <p>Materi Event</p>
          <div
            class="list-event"
            v-for="(item, idx) in form.syllabuses"
            :key="idx"
          >
            <div class="icon-plus" @click="addSubSession(idx)">
              <add-icon />
            </div>
            <b>Sesi {{ idx + 1 }}</b>
            <div v-for="(item2, idx2) in item" :key="idx2" class="session-nb-3">
              <v-text-field
                :disabled="isWatch"
                outlined
                v-model="form.syllabuses[idx][idx2]"
                class="form-input mt-2 no-space"
                dense
                clearable
              ></v-text-field>
              <div
                @click="onRemoveEvent(idx, idx2)"
                style="cursor: pointer"
              >
                <trash :width="38" :height="38" />
              </div>
            </div>
          </div>
          <div class="add-button" @click="addSubSession(null)" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Sesi</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="content-form">
          <p>Metode</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="methods"
            v-model="form.method"
            solo
            :item-text="'name'"
            :item-value="'value'"
          >
          </v-select>
        </div>
        <div class="content-form" v-if="form.method === 'Online'">
          <p>Link Pelaksanaan Event</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.learning_url"
            class="form-input"
            placeholder="Gmeet, zoom, or other"
            dense
            clearable
          ></v-text-field>
        </div>
        <div v-else>
          <div class="content-form">
            <p>Nama Lokasi Pelaksanaan</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="form.location_name"
              class="form-input"
              placeholder="Masukkan Nama Lokasi Pelaksanaan"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Alamat Lokasi Pelaksanaan</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="form.location_address"
              class="form-input"
              placeholder="Masukkan Alamat Lokasi Pelaksanaan"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>URL Link Maps Lokasi Pelaksanaan</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="form.location_map"
              class="form-input"
              placeholder="Masukkan URL Link Maps"
              dense
              clearable
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="spacing-ctn"></div>
      <div class="form-ctn">
        <p class="info-head">Desain Banner</p>
        <p class="berkas-title">Website Desktop</p>
        <div class="content-form">
          <p>Banner Thumbnail</p>
          <span class="alert-info"
            >Rekomendasi dimensi file 400 x 200 px, kapasitas maksimal 1
            MB</span
          >
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.img_urls.desktop.thumbnail"
            :rules="[!!form.img_urls.desktop.thumbnail || 'Wajib diisi!']"
            class="form-input"
            placeholder="Masukkan URL Link Image"
            dense
            clearable
          ></v-text-field>
        </div>
        <img
          v-if="form.img_urls.desktop.thumbnail"
          :src="form.img_urls.desktop.thumbnail"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px"
        />
        <div class="content-form mt-3">
          <p>Banner Detail Produk</p>
          <span class="alert-info"
            >Rekomendasi dimensi file 1.600 x 420 px, kapasitas maksimal 1
            MB</span
          >
          <v-text-field
            :disabled="isWatch"
            outlined
            :rules="[!!form.img_urls.desktop.product_detail || 'Wajib diisi!']"
            v-model="form.img_urls.desktop.product_detail"
            class="form-input"
            placeholder="Masukkan URL Link Image"
            dense
            clearable
          ></v-text-field>
        </div>
        <img
          v-if="form.img_urls.desktop.product_detail"
          :src="form.img_urls.desktop.product_detail"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px"
        />
        <div class="content-form mt-3">
          <p>Banner Rincian Pembelian</p>
          <span class="alert-info"
            >Rekomendasi dimensi file 200 x 200 px, kapasitas maksimal 1
            MB</span
          >
          <v-text-field
            :disabled="isWatch"
            outlined
            :rules="[!!form.img_urls.desktop.purchase_detail || 'Wajib diisi!']"
            v-model="form.img_urls.desktop.purchase_detail"
            class="form-input"
            placeholder="Masukkan URL Link Image"
            dense
            clearable
          ></v-text-field>
        </div>
        <img
          v-if="form.img_urls.desktop.purchase_detail"
          :src="form.img_urls.desktop.purchase_detail"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px"
        />
        <p class="berkas-title">Website Mobile</p>
        <div class="content-form">
          <p>Banner Thumbnail</p>
          <span class="alert-info"
            >Rekomendasi dimensi file 300 x 150 px, kapasitas maksimal 1
            MB</span
          >
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.img_urls.mobile.thumbnail"
            :rules="[!!form.img_urls.mobile.thumbnail || 'Wajib diisi!']"
            class="form-input"
            placeholder="Masukkan URL Link Image"
            dense
            clearable
          ></v-text-field>
        </div>
        <img
          v-if="form.img_urls.mobile.thumbnail"
          :src="form.img_urls.mobile.thumbnail"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px"
        />
        <div class="content-form mt-3">
          <p>Banner Detail Produk</p>
          <span class="alert-info"
            >Rekomendasi dimensi file 400 x 200 px, kapasitas maksimal 1
            MB</span
          >
          <v-text-field
            :disabled="isWatch"
            outlined
            :rules="[!!form.img_urls.mobile.product_detail || 'Wajib diisi!']"
            v-model="form.img_urls.mobile.product_detail"
            class="form-input"
            placeholder="Masukkan URL Link Image"
            dense
            clearable
          ></v-text-field>
        </div>
        <img
          v-if="form.img_urls.mobile.product_detail"
          :src="form.img_urls.mobile.product_detail"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px"
        />
        <div class="content-form mt-3">
          <p>Banner Rincian Pembelian</p>
          <span class="alert-info"
            >Rekomendasi dimensi file 100 x 100 px, kapasitas maksimal 1
            MB</span
          >
          <v-text-field
            :disabled="isWatch"
            outlined
            :rules="[!!form.img_urls.mobile.purchase_detail || 'Wajib diisi!']"
            v-model="form.img_urls.mobile.purchase_detail"
            class="form-input"
            placeholder="Masukkan URL Link Image"
            dense
            clearable
          ></v-text-field>
        </div>
        <img
          v-if="form.img_urls.mobile.purchase_detail"
          :src="form.img_urls.mobile.purchase_detail"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px"
        />
      </div>
      <div class="spacing-ctn"></div>
      <div class="form-ctn">
        <!-- <div style="display: flex; justify-content: space-between">
          <p class="info-head">Narasumber</p>
          <Button
            icon="+"
            name="TAMBAH NARASUMBER"
            outline
            height="40px"
            width="230px"
            @click="createNewInstructor"
          />
        </div> -->
        <div class="content-form">
          <p>Narasumber</p>
          <div
            v-for="(item, idx) in form.instructors"
            :key="idx"
            class="session-nb-3"
          >
            <div
              @click="addNewInsturctor"
              style="cursor: pointer"
              v-if="form.instructors.length - 1 === idx"
            >
              <add-icon />
            </div>
            <v-autocomplete
              label="Find instructor"
              outlined
              v-model="form.instructors[idx]"
              :items="instructors"
              class="form-input mt-2 no-space"
              dense
              :item-text="'name'"
              :item-value="'id'"
            ></v-autocomplete>
            <div
              @click="form.instructors.splice(idx, 1)"
              v-if="idx != 0"
              style="cursor: pointer"
            >
              <trash :width="38" :height="38" />
            </div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn mt-5"></div>
      <div class="form-ctn">
        <p class="info-head">Tiket</p>
        <div class="content-form">
          <p>Jenis Tiket</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="typeTiket"
            v-model="form.is_event_paid"
            solo
            :item-text="'name'"
            :item-value="'value'"
          >
          </v-select>
        </div>
        <div class="content-form" v-if="form.is_event_paid">
          <p>Harga Tiket</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.price"
            class="form-input"
            placeholder="Harga"
            type="number"
            :min="1"
            @input="
              () => {
                if (form.price < 0) {
                  form.price = 0;
                }
              }
            "
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Batas Pembelian Tiket</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            type="datetime-local"
            :rules="[!!form.registration_close_date || 'Wajib diisi!']"
            v-model="form.registration_close_date"
            class="form-input"
            dense
          ></v-text-field>
        </div>
        <div class="content-form" v-if="form.is_event_paid">
          <p>Potongan harga</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.discount_price"
            class="form-input"
            :min="0"
            prefix="IDR"
            type="number"
            @input="
              () => {
                if (form.discount_price < 0) {
                  form.discount_price = 0;
                }
              }
            "
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form" v-if="form.is_event_paid">
          <p>Durasi Earlybird</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="pt-2"> Mulai </v-col>
                <v-col cols="8">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="datetime-local"
                    v-model="form.start_discount_date"
                    class="form-input"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5 ml-5">
              <v-row no-gutters>
                <v-col cols="3" class="pt-2"> Selesai </v-col>
                <v-col cols="9">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="datetime-local"
                    v-model="form.end_discount_date"
                    class="form-input"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="spacing-ctn mt-5"></div>
      <div class="form-ctn">
        <div class="content-form">
          <p>Benefit</p>
          <div
            v-for="(item, idx) in form.benefits"
            :key="idx"
            class="session-nb-3"
          >
            <div
              @click="form.benefits.push('')"
              style="cursor: pointer"
              v-if="form.benefits.length - 1 === idx"
            >
              <add-icon />
            </div>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="form.benefits[idx]"
              class="form-input mt-2 no-space"
              dense
              clearable
            ></v-text-field>
            <div
              @click="form.benefits.splice(idx, 1)"
              v-if="idx != 0"
              style="cursor: pointer"
            >
              <trash :width="38" :height="38" />
            </div>
          </div>
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button
          name="Simpan"
          width="260px"
          @click="onSubmit"
          :disabled="!validData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import AddIcon from "@/components/icons/Add.vue";
import Trash from "@/components/icons/Trash.vue";
import Strip from "@/components/icons/Strip.vue";
import Chip from "@/components/Chip.vue";
import Button from "@/components/Button.vue";
import {VueEditor} from "vue2-editor";
import Popup from "@/components/others/Popup.vue";
import moment from "moment";

export default {
  name: "FormBlog",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      label_event: [
        {name: "Webinar", value: "webinar"},
        {name: "Mini Bootcamp", value: "mini-bootcamp"},
      ],
      statuses: [
        {name: "Aktif", value: true},
        {name: "Non Aktif", value: false},
      ],
      methods: [
        {
          name: "Daring (Online via Zoom)",
          value: "Online",
        },
        {
          name: "Luring (Offline in office)",
          value: "Offline",
        },
      ],
      typeLimitEvent: [
        {
          name: "Tidak Terbatas",
          value: false,
        },
        {
          name: "Terbatas",
          value: true,
        },
      ],
      listSessionSchedule: [
        {
          name: "Hanya 1 Sesi",
          value: 0,
        },
        {
          name: "Lebih dari 1 Sesi Beruntun",
          value: 1,
        },
        {
          name: "Lebih dari 1 Sesi Acak",
          value: 2,
        },
      ],
      typeTiket: [
        {
          name: "Berbayar",
          value: true,
        },
        {
          name: "Gratis",
          value: false,
        },
      ],

      limitOfEvent: false,
      sessionSchedule: 0,
      instructors: [],
      formInstructor: {
        name: "",
        image_url: "",
        position: "",
        institutional_origin: "",
        description: "",
        cv_url: "",
        linkedin_url: "",
      },
      form: {
        name: "",
        caption: "",
        description: "",
        category_id: 0,
        price: 0,
        total_session: 1,
        day_session: "",
        method: "Online",
        isSyllabuses: false,
        syllabuses: [],
        learning_url: "",
        benefits: [""],
        instructors: [""],
        participant_quota: 0,
        session_schedules: [
          {date_start: "", date_end: "", start_time: "", end_time: ""},
        ],
        img_urls: {
          desktop: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
          mobile: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
        },
        is_event_paid: false,
        registration_close_date: "",
        discount_price: 0,
        start_discount_date: "",
        end_discount_date: "",
        is_active: true,
        session_schedule_type: "",
        location_name: "",
        location_address: "",
        location_map: "",
      },
    };
  },
  components: {
    Button,
    Loading,
    AddIcon,
    Chip,
    Strip,
    Trash,
    VueEditor,
    Popup
  },
  watch: {
    limitOfEvent(e) {
      if (e) {
        this.form.participant_quota = 1;
      } else {
        this.form.participant_quota = 0;
      }
    }
  },
  computed: {
    validData() {
      if (this.form.name &&this.form.description) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async addNewInsturctor() {
      this.form.instructors.push("");
    },
    addSessionSchedlue() {
      this.form.session_schedules.push({
        date_start: "",
        date_end: "",
        start_time: "",
        end_time: "",
      });
    },
    onRemoveIntructor(idx) {
      this.form.instructors.splice(idx, 1);
    },
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataBanner();
    },
   
    async getIntructure() {
      const resp = await this.$crm_http.get("/v1/instructor");
      this.instructors = resp.data.data.instructors;
    },
    
    async getDataBanner() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/event/" + this.id);
        const data = resp.data.data.event;
        if (data.participant_quota) {
          this.limitOfEvent = true
        }
        this.form = {
          ...data,
          session_schedules: data.session_schedules ? data.session_schedules: [{
            date_start: "",
            date_end: "",
            start_time: "",
            end_time: ""
          }],
          img_urls: Object.keys(data.img_urls).length
            ? data.img_urls
            : {
            desktop: {
              thumbnail: "",
              product_detail: "",
              purchase_detail: "",
            },
            mobile: {
              thumbnail: "",
              product_detail: "",
              purchase_detail: "",
            },
          },
          is_event_paid: data.price ? true : false,
          instructors: data.instructors.map((item) => item.id),
          registration_close_date: moment(data.registration_close_date).format("YYYY-MM-DDThh:mm"),
          start_discount_date: moment(data.start_discount_date).format("YYYY-MM-DDThh:mm"),
          end_discount_date: moment(data.end_discount_date).format("YYYY-MM-DDThh:mm")
        };
        this.sessionSchedule = this.listSessionSchedule.find((item) => item.name === data.session_schedule_type)?.value
        if (this.form.syllabuses.length) {
          this.form.isSyllabuses = true
        }
        setTimeout(() => {
          this.form.participant_quota = data.participant_quota
          this.isLoading = false;
        }, 1000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      if (!this.form.is_event_paid) {
        this.form.price = 0 
        this.form.discount_price = 0 
      }
      // important declare
      if (!this.form.start_discount_date)  this.form.start_discount_date = moment(new Date()).format("YYYY-MM-DD")
      if (!this.form.end_discount_date) this.form.end_discount_date = moment(new Date()).format("YYYY-MM-DD")
      try {
        this.form = {
          ...this.form,
          session_schedule_type: this.listSessionSchedule.find((item) => item.value === this.sessionSchedule).name,
          category_id: Number(this.$route.params.id_category),
          price: Number(this.form.price),
          discount_price: Number(this.form.discount_price),
          participant_quota: Number(this.form.participant_quota),
          total_session: this.form.session_schedules.length ? this.form.session_schedules.length : 1
        };
        if (this.isEdit) {
          await this.$crm_http.put("/v1/event/" + this.form.id, this.form);
        } else {
          await this.$crm_http.post("/v1/event", this.form);
        }
        setTimeout(() => {
          this.$router.push(`/event-category/${this.$route.params.id_category}/event`);
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    checkScheduleType() {
      if (this.sessionSchedule === 0) {
          this.form.session_schedules[0].date_end = ''
          this.form.session_schedules.splice(1)
      } else if (this.sessionSchedule === 1) {
          this.form.session_schedules.splice(1)
      } else {
        this.form.session_schedules = this.form.session_schedules.map(item => ({
          ...item,
          date_end: ""
        }))
      }
    },
    createNewInstructor() {
      sessionStorage.setItem('redirect', this.$route.fullPath)
      this.$router.push("/instructor/new")
    },
    addSubSession(i) {
      if (i !== null) {
        this.form.syllabuses[i].push("");
        return;
      }
      this.form.syllabuses.push([""]);
      this.form.total_session = this.form.syllabuses.length;
    },
    onRemoveEvent(i, i2) {
      this.form.syllabuses[i].splice(i2, 1);
      if (this.form.syllabuses[i].length === 0) {
        this.form.syllabuses.splice(i, 1);
        this.form.total_session = this.form.syllabuses.length;
      }
    },
  },
  mounted() {
    this.getIntructure();
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.no-space {
  margin-bottom: -20px;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}
.icon-plus {
  position: absolute;
  bottom: 0px;
  left: -56px;
  cursor: pointer;
}
.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}
.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}
.label-info {
  color: gray;
  font-weight: normal;
  font-size: 15px;
}
.info-head {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 30px;
}
.spacing-switch {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}

.container-date {
  display: flex;
  gap: 15px;
}
.container-date-left {
  width: 50%;
}
.container-date-rigth {
  width: 50%;
}
.session-numb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.session-nb-3 {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
