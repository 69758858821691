<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div class="header">
      <h1>Survey</h1>
      <vue-excel-xlsx v-if="filter.classId" class="button-3" :data="mappingExcelData" :columns="headerExcelData"
        :file-name="filename" :file-type="'xlsx'">
        Download Data
      </vue-excel-xlsx>
    </div>
    <div class="container-box mt-10 filter-box">
      <div style="display: flex; gap: 20px">
        <div class="input-group">
          <span class="label">Category</span>
          <v-select label="Urutkan dari" v-model="filter.category" :items="categories" :item-text="'name'"
            :item-value="'value'" hide-details="auto" class="dropdown" solo style="width: 200px"></v-select>
        </div>
        <div class="input-group">
          <span class="label">Kelas</span>
          <v-select :items="classes" v-model="filter.classId" solo placeholder="filter by class"
            style="border-radius: 16px; width: 200px" :item-text="'name'" :item-value="'id'">
          </v-select>
        </div>
        <div class="input-group">
          <span class="label">Pilih durasi tanggal kelas</span>
          <v-select style="width: 90% !important; margin-top: 5px; border-radius: 16px" v-model="filter.dates"
            :items="sheduleDateOption" placeholder="Pilih durasi" :item-text="'dates'" :item-value="'dates'"
            class="form-input" solo></v-select>
        </div>
      </div>
      <div class="input-group btn">
        <Button name="Terapkan Filter" width="200px" @click="getSurvey" />
      </div>
    </div>

    <v-data-table :headers="headers" :items="surveys" class="elevation-1 mt-10 table" :items-per-page="-1"
      hide-default-footer>
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <NoDataAlert v-if="surveys.length === 0" :totalData="total" />

    <!-- <div class="data-count">
      <span><b>{{ pageNo(surveys[surveys.length - 1]) || 0 }} dari {{ total }} </b>data yang tertampil</span>
    </div> -->

    <!-- <v-row justify="center" v-if="total">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination v-model="currentPage" class="my-4" total-visible="8" :length="totalPage"
            @input="getSurvey"></v-pagination>
        </v-container>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isLoading: false,
      total: 0,
      filter: {
        category: "",
        classId: 0,
        dates: "",
      },
      filename: "",
      message: "",
      isPopup: false,
      headerExcelData: [
        {
          label: "Nama Peserta",
          field: "user_name",
        },
        {
          label: "Pertanyaan",
          field: "question",
        },
        {
          label: "Jawaban",
          field: "answer",
        },
      ],
      mappingExcelData: [],
      classes: [],
      categories: [
        {
          name: "Prakerja",
          value: "PRAKERJA",
        },
        {
          name: "Bootcamp",
          value: "BOOTCAMP",
        },
      ],
      id: 0,
      headers: [
        { text: "Nama Kleas", value: "class_name", width: "300" },
        { text: "Nama Peserta", value: "user_name", width: "300" },
        { text: "DATE", value: "date", width: "215px" },
        { text: "KATEGORI", value: "category" },
        { text: "AKSI", value: "actions", sortable: false },
      ],
      surveys: [],
      sheduleDateOption: [],
    };
  },
  components: {
    Button,
    Loading,
    WatchTable,
    NoDataAlert,
    Popup
  },
  methods: {
    async getSurvey() {
      this.surveys = [];
      this.mappingExcelData = [];
      await this.$web_http
        .get(
          `/v1/survey?class_id=${this.filter.classId}&date=${this.filter.dates}&category=${this.filter.category}`
        )
        .then(async (response) => {
          const data = response.data.data.surveys || [];
          for (let i = 0; i < data.length; i++) {
            const user = await this.getUserId(data[i].user_id);
            const classes = await this.getClassId(data[i].class_id);
            this.filename = "Survey_kelas_" + classes.name;
            this.surveys.push({
              ...data[i],
              user_name: user.full_name,
              class_name: classes.name,
            });

            data[i].answers.forEach((item) => {
              this.mappingExcelData.push({
                ...item,
                user_name: user.full_name,
              });
            });
          }
        })

        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    async getScheduleDate() {
      try {
        const resp = await this.$lms_http.get("/v1/user-schedule/option-date");
        console.log(resp.data.data);
        this.sheduleDateOption = resp.data.data.schedule_option;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getUserId(id) {
      try {
        const response = await this.$crm_http.get("/v1/user/" + id);
        return response.data.data.user;
      } catch (err) {
        return {
          full_name: "",
        };
      }
    },
    async getClassId(classId) {
      try {
        const resp = await this.$lms_http.get("/v1/class/" + classId);
        return resp.data.data.class;
      } catch (err) {
        return {
          name: "",
        };
      }
    },
    async getClasses() {
      await this.$web_http
        .get("/v1/class")
        .then((response) => {
          this.classes = response.data.data.classes;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },

    watchItem(item) {
      this.$router.push("/survey/watch/" + item.id);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getSurvey();
    this.getClasses();
    this.getScheduleDate();
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group .label {
  font-size: larger;
  color: #000000;
}

.input-group.btn {
  margin-left: auto;
  justify-content: flex-end;
}

.dropdown {
  width: 70%;
  height: 7%;
  border-radius: 14px !important;
  background-color: transparent !important;
}

.action-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.data-count {
  height: 0;
  position: relative;
  overflow-y: visible;
  display: flex;
}

.data-count span {
  position: absolute;
  font-size: medium;
  top: 35px;
}

/* CSS */
.button-3 {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 250px;
  height: 50px;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}
</style>
