<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
        {{ name }}
      </h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Kategori</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="listCategory"
            v-model="form.category"
            solo
            :item-text="'name'"
            :item-value="'value'"
            placeholder="Pilih Kategori"
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Nama</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            class="form-input mt-2"
            placeholder="Masukkan Nama Lengkap"
            v-model="form.name"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Status</p>
          <div class="spacing-switch">
            <v-switch
              v-model="form.is_active"
              color="primary"
              hide-details
              style="margin-top: -5px"
            ></v-switch>
            <div>
              <Chip :active="form.is_active" />
            </div>
          </div>
        </div>
        <div class="content-form">
          <p>Jurusan Kuliah</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            class="form-input mt-2"
            placeholder="Masukkan Jurusan Kuliah"
            v-model="form.field_of_study"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Posisi Pekerjaan</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            class="form-input mt-2"
            placeholder="Tulis Posisi Pekerjaan"
            v-model="form.job"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Asal Universitas</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            class="form-input mt-2"
            placeholder="Masukkan Asal Universitas"
            v-model="form.place"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Feedback</p>
          <v-textarea
            placeholder="Tulis masukan ...."
            :disabled="isWatch"
            outlined
            rows="3"
            v-model="form.feedback"
            clearable
            class="form-input"
          ></v-textarea>
        </div>
        
        <div class="content-form">
          <p>Foto Profil</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.image_url"
            :rules="[!!form.image_url || 'Wajib diisi!']"
            class="form-input"
            placeholder="Pilih foto"
            dense
            clearable
          ></v-text-field>
          <h6 class="alert-info" style="margin-top: -20px">
            Rekomendasi dimensi file 500 x 500 px, kapasitas maksimal 1 MB
          </h6>
        </div>
        <img
          v-if="form.image_url"
          :src="form.image_url"
          alt="gambar tidak valid"
          style="width: 100%; border-radius: 14px; margin-top: 20px"
        />
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button
          name="Simpan"
          width="260px"
          @click="onSumbmit"
          :disabled="!validData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import Chip from '../../../components/Chip.vue';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "Forminstructor",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      form: {
        name: "",
        image_url: "",
        feedback: "",
        field_of_study: "",
        job: "",
        is_active: false,
        place: "",
        category: "",
      },
      listCategory: [
        {name: "Kampus Merdeka", value: "KAMPUS_MERDEKA"},      
        {name: "Prakerja", value: "PRAKERJA"},      
        {name: "Home", value: "HOME"},
        {name: "Corporate Service", value: "CORPORATE"},
        {name: "Akselerasi", value: "ACCELERATION"},
        {name: "Corporate Training", value: "TRAINING"},
        {name: "Corporate Collaboration", value: "COLLABORATION"},
        {name: "Talent as a Service", value: "TALENT_SERVICE"},
      ],
    };
  },
  components: {
    Loading,
    Button,
    Chip,
    Popup
  },
  computed: {
    validData() {
      return (
        this.form.name &&
        this.form.image_url &&
        this.form.feedback &&
        this.form.field_of_study &&
        this.form.job &&
        this.form.place &&
        this.form.category
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataTestimoni();
    },
    async getDataTestimoni() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/testimony/" + this.id);
        this.form = resp.data.data.testimony;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          await this.$crm_http.put("/v1/testimony/" + this.form.id, this.form);
        } else {
          await this.$crm_http.post("/v1/testimony", this.form);
        }
        setTimeout(() => {
          this.$router.push("/testimoni");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>
<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.no-space {
  margin-bottom: -20px;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  color: #7b7e8c;
}
.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}
.icon-plus {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
}
.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}
.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}
.label-info {
  color: gray;
  font-weight: normal;
  font-size: 15px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
