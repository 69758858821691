<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Kategori</p>
        <v-select
          :disabled="isWatch"
          class="form-input"
          :items="categories"
          :item-text="'name'"
          :item-value="'value'"
          v-model="form.category"
          placeholder="Pilih kategori"
          solo
        >
        </v-select>
      </div>
      <div class="content-form">
        <p>Pertanyaan</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          v-model="form.question"
          class="form-input"
          placeholder="Tulis Pertanyaan"
          dense
          clearable
        ></v-text-field>
      </div>
      <div class="content-form mb-5">
        <p>Jawaban</p>
        <vue-editor
          v-model="form.answer"
          :editorToolbar="$helpers.customToolBar()"
          class="form-input"
          v-if="!isWatch"
        ></vue-editor>
        <div v-else class="watch-ctn">
          <div v-html="form.answer"></div>
        </div>
      </div>
      <v-switch
              v-model="form.is_main"
              color="primary"
              label="is_main"
              hide-details
              v-if="isWatch"
              disabled
            ></v-switch>
            <v-switch
              v-model="form.is_main"
              color="primary"
              label="is_main"
              hide-details
              v-if="!isWatch"
            ></v-switch>
      <div class="submit-box" v-if="!isWatch">
        <Button
          name="PUBLISH"
          width="260px"
          @click="onSumbmit"
          :disabled="!validData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";

import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormBlog",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      tags: [],
      categories: [
        {name: "Kampus Merdeka (Global)", value: "KAMPUS_MERDEKA"}, 
        {name: "Kampus Merdeka (Magang)", value: "MAGANG"}, 
        {name: "Prakerja", value: "PRAKERJA"}, {name: "Home", value: "HOME"}, 
        {name: "Bootcamp", value: "BOOTCAMP"},
        {name: "Akselerasi", value: "ACCELERATION"},
        {name: "Corporate Training", value: "TRAINING"}, 
        {name: "Akselerasi Class", value: "ACCELERATION_CLASS"},
        {name: "Corporate Collaboration", value: "COLLABORATION"},
        { name: "Talent as a service", value: "TALENT_SERVICE" },
        {name: "Technology Service", value: "SERVICES"},
        {name: "Career", value: "CAREER"},
        {name: "Event", value: "EVENT"},
      ],
      form: { 
        question: "",
        answer: "",
        category: "",
        is_main: false
      },
    };
  },
  components: {
    Button,
    Loading,
    VueEditor,
    Popup
  },
  computed: {
    validData() {
      return this.form.question && this.form.answer && this.form.category;
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataFaq();
    },
    async getDataFaq() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/faq/" + this.id);
        this.form = resp.data.data;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          await this.$crm_http.put("/v1/faq/" + this.form.id, {
            ...this.form,
            answer: [this.form.answer]
        });
        } else {
          await this.$crm_http.post("/v1/faq", {
            ...this.form,
            answer: [this.form.answer]
        });
        }
        setTimeout(() => {
          this.$router.push("/faq");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
