<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 42 42" fill="none">
        <path
            d="M37.1875 34.9824H4.8125C4.08763 34.9824 3.5 35.57 3.5 36.2949C3.5 37.0198 4.08763 37.6074 4.8125 37.6074H37.1875C37.9124 37.6074 38.5 37.0198 38.5 36.2949C38.5 35.57 37.9124 34.9824 37.1875 34.9824Z"
            :fill="strokeColor" />
        <path
            d="M28.63 17.5H26.6875V27.1075C26.6875 27.3373 26.6422 27.5648 26.5543 27.7772C26.4663 27.9895 26.3374 28.1824 26.1749 28.3449C26.0124 28.5074 25.8195 28.6363 25.6072 28.7243C25.3949 28.8122 25.1673 28.8575 24.9375 28.8575H17.0625C16.5984 28.8575 16.1532 28.6731 15.825 28.3449C15.4969 28.0167 15.3125 27.5716 15.3125 27.1075V17.5H13.37C13.0806 17.4943 12.7989 17.4062 12.5578 17.2461C12.3167 17.0861 12.1262 16.8606 12.0087 16.5961C11.8911 16.3316 11.8514 16.0391 11.8941 15.7529C11.9369 15.4667 12.0603 15.1986 12.25 14.98L19.7925 6.64996C20.1179 6.3397 20.5503 6.16663 21 6.16663C21.4496 6.16663 21.882 6.3397 22.2075 6.64996L29.8375 14.98C30.0323 15.2042 30.1573 15.4804 30.197 15.7748C30.2368 16.0691 30.1896 16.3686 30.0612 16.6365C29.9329 16.9043 29.729 17.1288 29.4747 17.2822C29.2204 17.4356 28.9268 17.5113 28.63 17.5Z"
            :fill="strokeColor" />
    </svg>
</template>

<script>
    export default {
        props: {
            width: {
                type: Number,
                default: 42
            },
            height: {
                type: Number,
                default: 42
            },
            strokeColor: {
                type: String,
                default: "#162CA2"
            }
        }
    }
</script>