<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="back-icon" @click="$router.push('/privacy-policy')">
      <round-arrow-back />
    </div>
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Judul</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          v-model="form.title"
          class="form-input"
          placeholder="Tulis judul privacy policy"
          dense
          :suffix="`${form.title.length}/90`"
          clearable
        >
        </v-text-field>
      </div>
      <div v-for="(data, idx) in content" :key="idx">
        <div class="content-form mt-4">
          <b>Ke {{ idx + 1 }}</b>
          <p>Sub Judul</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="data.title"
            class="form-input"
            placeholder="Tulis judul privacy policy"
            dense
            :suffix="`${data.title.length}/90`"
            clearable
          >
          </v-text-field>
        </div>
        <div class="content-form">
          <p>Deskripsi</p>
          <vue-editor
            v-model="data.description"
            :editorToolbar="$helpers.customToolBar()"
            class="form-input"
            style="height: 400px; padding-bottom: 100px"
            v-if="!isWatch"
          >
          </vue-editor>
          <div v-else class="watch-ctn">
            <div v-html="data.description"></div>
          </div>
        </div>
      </div>
      <div class="add-button" @click="addListDescription" v-if="!isWatch">
        <div class="line"></div>
        <div class="button">+ Tambah Deskripsi</div>
        <div class="line"></div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button
          name="PUBLISH"
          width="260px"
          @click="onSubmit"
          :disabled="!validData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/ilustration/Loading.vue";
import RoundArrowBack from "../../../components/icons/RoundArrowBack.vue";
import { VueEditor } from "vue2-editor";
import Button from "../../../components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  components: {
    RoundArrowBack,
    VueEditor,
    Button,
    Loading,
    Popup
  },
  name: "FormPrivacyPolicy",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      content: [
        {
          title: "",
          description: "",
        },
      ],
      form: {
        title: "",
      },
    };
  },
  computed: {
    validData() {
      return this.form.title && this.content[0].description;
    },
  },
  methods: {
    addListDescription() {
      this.content.push({
        title: "",
        description: "",
      });
    },
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataPrivacyPolicy();
    },
    async getDataPrivacyPolicy() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/privacy-policy/" + this.id);
        this.form.title = resp.data.data.privacy_policy.title;
        this.content = resp.data.data.privacy_policy.content;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        // Check if scope_learning is a string and parse it to an array
        if (typeof this.form.scope_learning === "string") {
          this.form.scope_learning = JSON.parse(this.form.scope_learning);
        }

        const payload = {
          ...this.form,
          scope_learning: this.form.scope_learning,
        };

        console.log("Payload:", payload);

        if (this.isEdit) {
          await this.$crm_http.put(
            "/v1/corporate-training/" + this.form.id,
            payload
          );
        } else {
          await this.$crm_http.post("/v1/corporate-training", payload);
        }

        setTimeout(() => {
          this.$router.push("/corporate-training");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>
<style scoped>
.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}
.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}

.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding-bottom: 30px;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.session-nb-3 {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
