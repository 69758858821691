<template>
    <div class="ma-10">
      <Loading v-if="isLoading" />
      <div class="top-container-box">
        <h1>Blast Email Report</h1>
      </div>
      <v-row>
        <!-- Search  -->
        <v-col>
          <div class="action-head">
            <div class="f-search" style="width: 300px">
              <!-- Input -->
              <div>
                <v-text-field
                  v-model="filter.batch"
                  type="text"
                  class="f-input"
                  placeholder="Batch number"
                  clearable
                  dense
                  flat
                  solo
                  style="padding-top: 5px;padding-left: 5px; width: 288px;border-radius: 14px;"
                  @input="getBlastEmail"
                ></v-text-field>
              </div>
            </div>
            <div>
          <!-- <v-text-field
            outlined
            v-model="filter.created_at"
            type="date"
            @input="getBlastEmail"
            clearable
            dense
                  flat
                  solo
                  style="padding-top: 5px;padding-left: 5px; width: 288px;border-radius: 14px;"
          ></v-text-field> -->
        </div>
          </div>
        </v-col>
        <v-col style="display: flex; justify-content: flex-end; gap: 10px">
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="blasts"
        sort-by="calories"
        class="elevation-1 mt-10 table"
        hide-default-footer
      >
        <template v-slot:item.actions="{ item }">
          <button @click="watchItem(item)" class="me-2">
            <WatchTable height="28" width="28" />
          </button>
        </template>
      </v-data-table>
  
      <!-- Display when no data is available -->
      <NoDataAlert :totalData="totalData" />
  
      <v-row justify="center" v-if="total >= 1">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getBlastEmail"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
      <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
        <b
          >{{ Math.floor((currentPage - 1) * 10 + blasts.length) }} dari
          {{ totalData }}</b
        >
        blast email yang tertampil
      </p>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import Loading from "@/components/ilustration/Loading.vue";
  import getAuthentification from "@/utils/badrequest.js";
  import WatchTable from "../../../components/icons/WatchTable.vue";
  import NoDataAlert from "@/components/NoDataAlert.vue";
  
  export default {
    name: "BlastEmail",
    data() {
      return {
        currentPage: 1,
        total: 0,
        search: "",
        isLoading: false,
        id: 0,
        totalData: 0,
        filter: {
          created_at: "",
          batch: "",
        },
        headers: [
          {text: "NO", value: "id", width: "100"},
          { text: "Judul", value: "blast.title", width: "300" },
          { text: "Batch", value: "batch", width: "300" },
          { text: "Success", value: "success", width: "200" },
          { text: "Failed", value: "failed", width: "200" },
          { text: "Created At", value: "created_at", width: "200" },
          { text: "AKSI", value: "actions", sortable: false, width: "132" },
        ],
        blasts: [],
      };
    },
    components: {
      Loading,
      WatchTable,
      NoDataAlert,
    },
    methods: {
      async getBlastEmail() {
        await this.$crm_http
          .get(
            `/v1/blast-report?batch=${this.filter.batch}&page=${this.currentPage}&per_page=10`
          )
          .then((response) => {
            this.blasts = response.data.data.blast_emails

            const t = parseInt(response.data.data.total);
            this.total = Math.ceil(t / 10);
            this.totalData = response.data.data.total;
          })
          .catch((err) => console.error(err.response));
        // .finally(() => this.spin_load.val = false)
      },
      watchItem(item) {
        console.log(item);
      },
      format_date(value) {
        if (value) {
          return moment(String(value)).format("YYYY-MM-DD");
        }
      },
    },
    beforeCreate() {
      getAuthentification();
    },
    mounted() {
      this.getBlastEmail();
    },
  };
  </script>
  
  <style scoped>
  .action-head {
    margin-top: 7px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .modal-title {
    font-weight: bold;
    font-size: 19px;
  }
  .f-search {
    border: 1px solid #d3d6e1;
    width: 100%;
    height: 48px;
    gap: 10px;
    display: flex;
    border-radius: 10px;
    background-color: white;
  }
  .f-search-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin-right: 18px;
  }
  .f-input {
    width: 86%;
    background: transparent;
    border: none;
  }
  .f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
  }
  .top-container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .count-data {
    position: relative;
    top: -60px;
    width: 30%;
  }
  
  .no-pagination {
    margin-top: 20px;
    margin-left: 5px;
  }
  </style>
  