<template>
  <v-alert v-if="totalData === 0" dense color="#F5F5F5">
    <!-- Container -->
    <div
      style="
        margin-top: 80px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;"
    >
      <!-- Image -->
      <div>
        <img
          src="/img/other/no-data-found.png"
          alt="Data not found"
          style="width: 600px"
        />
      </div>
      <!-- Detail -->
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0px 260px;
          color: #36373f;
          gap: 10px;"
      >
        <!-- Title -->
        <div style="font-weight: 600; font-size: 20px">Tidak ada hasil!</div>
        <!-- Description -->
        <div style="font-size: 12px; font-weight: 400; line-height: 20px">
          Kami tidak dapat menemukan data yang Anda cari, coba dengan kata
          kunci lain atau masukan kategori yang sesuai.
        </div>
      </div>
    </div>
  </v-alert>
</template>

<script>
  export default {
    name: "NoDataAlert",
    props: {
      totalData: Number,
    }
  }
</script>