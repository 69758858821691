<template>
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 9C11.5711 9 13.25 7.32107 13.25 5.25C13.25 3.17893 11.5711 1.5 9.5 1.5C7.42893 1.5 5.75 3.17893 5.75 5.25C5.75 7.32107 7.42893 9 9.5 9Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.9426 16.5C15.9426 13.5975 13.0551 11.25 9.50011 11.25C5.94511 11.25 3.05762 13.5975 3.05762 16.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>
<script>
export default {
  name: "UserManagementIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
