<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#E7E8F0" />
    <path
      d="M20 28.3971C19.7854 28.3943 19.5803 28.3078 19.4286 28.1561C19.2768 28.0043 19.1903 27.7993 19.1875 27.5846V12.418C19.1875 12.2025 19.2731 11.9958 19.4255 11.8434C19.5778 11.6911 19.7845 11.6055 20 11.6055C20.2155 11.6055 20.4222 11.6911 20.5745 11.8434C20.7269 11.9958 20.8125 12.2025 20.8125 12.418V27.5846C20.8097 27.7993 20.7232 28.0043 20.5714 28.1561C20.4197 28.3078 20.2146 28.3943 20 28.3971Z"
      fill="#7B7E8C"
    />
    <path
      d="M27.5833 20.8125H12.4166C12.2011 20.8125 11.9945 20.7269 11.8421 20.5745C11.6897 20.4222 11.6041 20.2155 11.6041 20C11.6041 19.7845 11.6897 19.5778 11.8421 19.4255C11.9945 19.2731 12.2011 19.1875 12.4166 19.1875H27.5833C27.7988 19.1875 28.0054 19.2731 28.1578 19.4255C28.3102 19.5778 28.3958 19.7845 28.3958 20C28.3958 20.2155 28.3102 20.4222 28.1578 20.5745C28.0054 20.7269 27.7988 20.8125 27.5833 20.8125Z"
      fill="#7B7E8C"
    />
  </svg>
</template>
<script>
export default {
  name: "AddIcon",
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
};
</script>
