<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6929 12.6L15.3996 8.30667C15.316 8.21614 15.2695 8.09742 15.2695 7.97417C15.2695 7.85092 15.316 7.7322 15.3996 7.64167L17.4996 5.495C17.6081 5.38565 17.7371 5.29886 17.8793 5.23963C18.0214 5.1804 18.1739 5.1499 18.3279 5.1499C18.482 5.1499 18.6344 5.1804 18.7766 5.23963C18.9188 5.29886 19.0478 5.38565 19.1563 5.495L22.4579 8.79667C22.5673 8.90512 22.6541 9.03416 22.7133 9.17633C22.7725 9.3185 22.803 9.47099 22.803 9.625C22.803 9.77901 22.7725 9.9315 22.7133 10.0737C22.6541 10.2158 22.5673 10.3449 22.4579 10.4533L20.3579 12.6C20.2674 12.6836 20.1487 12.7301 20.0254 12.7301C19.9022 12.7301 19.7835 12.6836 19.6929 12.6Z"
      fill="#7B7E8C"
    />
    <path
      d="M9.49653 22.8316L6.52153 22.5166C6.25569 22.4887 6.00752 22.3703 5.81851 22.1813C5.6295 21.9923 5.51113 21.7441 5.48319 21.4783L5.16819 18.5033C5.13998 18.3222 5.15486 18.1369 5.21161 17.9626C5.26837 17.7883 5.36541 17.6298 5.49486 17.5L14.3382 8.66831C14.4254 8.58281 14.5427 8.53491 14.6649 8.53491C14.787 8.53491 14.9043 8.58281 14.9915 8.66831L19.2849 12.9616C19.3704 13.0489 19.4183 13.1662 19.4183 13.2883C19.4183 13.4105 19.3704 13.5277 19.2849 13.615L10.4999 22.505C10.37 22.6344 10.2116 22.7315 10.0372 22.7882C9.86291 22.845 9.67767 22.8599 9.49653 22.8316Z"
      fill="#7B7E8C"
    />
  </svg>
</template>
<script>
export default {
  name: "PencilIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
