<template>
  <div class="body-form">
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div v-if="isCompletion">
        <div class="content-form">
          <p>DP Category</p>
          <v-text-field readonly outlined v-model="formCompletion.category" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Kode Voucher</p>
          <v-text-field readonly outlined v-model="formCompletion.voucher_code" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Completion Date</p>
          <v-text-field readonly outlined v-model="formCompletion.completion_date" class="form-input"
            dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Post Test Score</p>
          <v-text-field readonly outlined v-model="formCompletion.post_score" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Certificate URL</p>
          <v-text-field readonly outlined v-model="formCompletion.certificate_url" class="form-input"
            dense></v-text-field>
        </div>
      </div>
      <div v-else>
        <div class="content-form">
          <p>DP Category</p>
          <v-text-field readonly outlined v-model="formRedemption.category" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Kode Voucher</p>
          <v-text-field readonly outlined v-model="formRedemption.voucher_code" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Harga</p>
          <v-text-field readonly outlined v-model="formRedemption.price" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Redeem Code</p>
          <v-text-field readonly outlined v-model="formRedemption.redeem_code" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Schedule Code</p>
          <v-text-field readonly outlined v-model="formRedemption.schedule_code" class="form-input"
            dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Schedule</p>
          <v-text-field readonly outlined v-model="formRedemption.schedule" class="form-input" dense></v-text-field>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "DetailReporting",
  components: {
    Popup
  },
  data() {
    return {
      name: "",
      isCompletion: false,
      message: "",
      isPopup: false,
      formCompletion: {},
      formRedemption: {}
    };
  },
  methods: {
    async getCompleteReport() {
      await this.$crm_http
        .get(`/v1/completion-report/${this.$route.params.id}`)
        .then((response) => {
          this.formCompletion = {
            ...response.data.data.completion_report,
            completion_date: moment(response.data.data.completion_report.completion_date).format("YYYY-MMMM-DD hh:mm:ss")
          }
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    async getRedempReport() {
      await this.$crm_http
        .get(`/v1/redemption-report/${this.$route.params.id}`)
        .then((response) => {
          this.formRedemption = response.data.data.redemption_report
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
  },
  mounted() {
    this.name = this.$route.meta.name;
    if (this.name === "Redemption Detail") {
      this.isCompletion = false
      this.getRedempReport()
    } else {
      this.isCompletion = true
      this.getCompleteReport()
    }
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}

.cart-form {
  position: relative;
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}

.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>