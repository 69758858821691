<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <div class="cart-form">
        <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
        <div class="content-form">
          <p>Nama</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.name"
            class="form-input"
            placeholder="Judul banner"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Deskripsi</p>
          <v-textarea
              placeholder="Tulis deskripsi ...."
              :disabled="isWatch"
              outlined
              rows="3"
              v-model="form.description"
              clearable
              class="form-input"
            ></v-textarea>
        </div>
        <div class="content-form">
          <p>Kategori</p>
          <v-select
            label="Select"
            class="form-input"
            :items="categories"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.category"
            solo
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Image URL</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.image_url"
            class="form-input"
            placeholder="Image URL"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Small Image</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.small_img"
            class="form-input"
            placeholder="Image Small"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Code</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.code"
            class="form-input"
            placeholder="Image Small"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button
            name="PUBLISH"
            width="260px"
            @click="onSumbmit"
            :disabled="!validData"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import Button from "../../../components/Button.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    name: "FormBlog",
    data() {
      return {
        id: null,
        name: "",
        isLoading: false,
        isWatch: false,
        isEdit: false,
        message: "",
        isPopup: false,
        categories: [
          {name: "Corporate Training", value: "TRAINING"},
        ],
        form: {
          name: "",
          description: "",
          category: "",
          image_url: "",
          small_img: "",
          code: ""
        },
      };
    },
    components: {
      Button,
      Loading,
      Popup
    },
    computed: {
      validData() {
        return (
          this.form.name && this.form.category && this.form.small_img && this.form.image_url
        );
      },
    },
    methods: {
      checkingType() {
        this.name = this.$route.meta.name;
        this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
        this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getPartner();
      },
      async getPartner() {
        this.isLoading = true;
        try {
          const resp = await this.$crm_http.get("/v1/partner/" + this.id);
          this.form = {...resp.data.data.partner};
          this.isLoading = false;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
      async onSumbmit() {
        this.isLoading = true;
        try {
          if (this.isEdit) {
            await this.$crm_http.put("/v1/partner/" + this.form.id, this.form);
          } else {
            await this.$crm_http.post("/v1/partner", this.form);
          }
          setTimeout(() => {
            this.$router.push("/partner");
          }, 2000);
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
    },
    mounted() {
      this.checkingType();
    },
  };
  </script>
  
  <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafaf9;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  .cart-form {
    position: relative;
    width: 50%;
    border-radius: 30px;
    padding: 20px 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  .banner-berkas {
    font-size: 18px;
    margin: 20px 0;
    font-weight: bold;
  }
  .watch-ctn {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid gray;
  }
  .submit-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  .alert-info {
    font-size: 14px;
    color: #7b7e8c;
  }
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  </style>
  