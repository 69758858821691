<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <h2 class="text-center">Buat Kategori Event</h2>
      <div class="content-form mt-10">
        <p>Kategori Event</p>
        <v-text-field
          outlined
          v-model="form.name"
          class="form-input"
          placeholder="Masukkan Nama Kategori Event"
          dense
          clearable
          :rules="[!!form.name || 'Wajib diisi!']"
        ></v-text-field>
      </div>
      <div class="content-form">
        <p>Deskripsi</p>
        <v-textarea
          placeholder="Tulis deskripsi ...."
          outlined
          rows="3"
          v-model="form.description"
          :rules="[!!form.description || 'Wajib diisi!']"
          clearable
          class="form-input"
        ></v-textarea>
      </div>
      <div style="display: flex; justify-content: center;">
        <Button
            :disabled="validForm"
            name="SIMPAN"
            width="230px"
            @click="submitNewCategory"
        />
      </div>
    </Modal>
    <div class="head-ctn">
      <h1>EVENT</h1>
      <div class="action-menu">
        <div class="action-riwayat" title="Riwayat Category Event" @click="toRiwayatCategory">
          <clock />
        </div>
        <Button
          icon="+"
          name="Create Category"
          outline
          style="background-color: #3b54d9; color: #ffffff; width: 200px"
          @click="() => (isModal = true)"
        />
      </div>
    </div>
    <hr class="line-strip" />
    <div v-for="(item, idx) in categories" :key="idx" class="box-category">
      <div style="width: 97%" @click="toEvent(item.id)">
        <h2>{{ item.name }}</h2>
        <span>{{ item.description }}</span>
      </div>
      <div class="menu-act">
        <TripleElipse />
        <div class="menu-act-item" @click="deleteCategory(item.id)">
          <Trash :width="20" :height="20" />
          <span>Hapus</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import Modal from "../../../components/Modal.vue";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TripleElipse from "../../../components/icons/TripleElipse.vue";
import Trash from "../../../components/icons/Trash.vue";
import Clock from '../../../components/icons/Clock.vue';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "EventCategory",
  data() {
    return {
      categories: [],
      message: "",
      isPopup: false,
      form: {
        name: "",
        description: ""
      },
      isModal: false,
      isLoading: false,
    };
  },
  components: {
    Button,
    Modal,
    Loading,
    TripleElipse,
    Trash,
    Clock,
    Popup
  },
  computed: {
    validForm() {
       if (this.form.name && this.form.description) return false
       return true
    }
  },
  methods: {
    async getDataCategories() {
       this.isLoading = true
       try {
            const resp = await this.$crm_http.get("/v1/event-category");
            this.categories = resp.data.data.event_categories;  
            this.isLoading = false
       } catch (err) {
            this.message = err.response.data.data
            this.isPopup = true
            this.isLoading = false
       } 
    },
    async submitNewCategory() {
        this.isLoading = true
        try {
            await this.$crm_http.post("/v1/event-category", this.form);
            this.getDataCategories()
            this.isLoading = false
            this.isModal = false
            this.form = {
                name: "",
                description: ""
            }
        } catch (err) {
            this.message = err.response.data.data
            this.isPopup = true
            this.isLoading = false
        }
    },
    async deleteCategory(id) {
        this.isLoading = true
        try {
            await this.$crm_http.delete("/v1/event-category/" + id);
            this.getDataCategories()
            this.isLoading = false
        } catch (err) {
            this.message = err.response.data.data
            this.isPopup = true
            this.isLoading = false
        }
    },
    toRiwayatCategory() {
      this.$router.push(`/event-category/riwayat`)
    },
    toEvent(id) {
      this.$router.push(`/event-category/${id}/event`)
    }
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getDataCategories();
  },
};
</script>
<style scoped>
.head-ctn {
  display: flex;
  justify-content: space-between;
}
.line-strip {
  margin: 30px 0;
}
.box-category {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 30px 50px;
  background-color: #ffffff;
  border-radius: 20px;
}
.menu-act {
  width: 3%;
  position: relative;
  cursor: pointer;
}
.menu-act:hover .menu-act-item {
  display: flex;
}
.menu-act-item {
  display: none;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  height: 40px;
  width: 150px;
  border-radius: 12px;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 2px 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translate(-80%, 70%);
}
.content-form {
  margin-top: -5px;
}
.content-form p {
    text-align: left;
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.action-menu {
  display: flex; gap: 20px; color: #ACAFBF;
}
.action-riwayat {
  border: 1px solid;
  border-radius: 16px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
