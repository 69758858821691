import axios from 'axios';

// setup api

//prod
var crmAPI = axios.create({
  baseURL: process.env.VUE_APP_API_DASHBOARD_URL,
});

var lmsAPI = axios.create({
  baseURL: process.env.VUE_APP_API_LMS_URL
});

var webAPI = axios.create({
  baseURL: process.env.VUE_APP_API_CORE_URL,
});

// handle request

webAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

lmsAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

crmAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// handle response

webAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location = '/login';
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(err);
  }
);

lmsAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location = '/login';
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(err);
  }
);

crmAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location = '/login';
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(err);
  }
);

export { webAPI, crmAPI, lmsAPI };

