<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="top-container-box">
      <div>
        <h1>Detail Complete Report</h1>
        <h2>Action: {{ reportingLog.action }}</h2>
      </div>
      <div>
        <Button
          icon=""
          outline
          name="Download Data"
          @click="downloadData"
          width="300px"
        />
      </div>
    </div>
    <v-tabs v-model="tab" color="#162CA2" align-tabs="start">
      <v-tab
        v-for="item in titleTabs"
        :key="item"
        :value="item"
        @click="chooseTab(item)"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <div v-if="tabName === 'Completion'">
      <v-data-table
        :headers="headersComplete"
        :items="reportingComplete"
        sort-by="calories"
        class="elevation-1 mt-10 table"
        hide-default-footer
      >
        <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="watchItem(item, 'c')"> mdi-eye </v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-data-table
        :headers="headersRedemp"
        :items="reportingRedemp"
        sort-by="calories"
        class="elevation-1 mt-10 table"
        hide-default-footer
      >
        <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="watchItem(item, 'r')"> mdi-eye </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { export_json_to_excel } from "vue-excel-xlsx";

import Button from "../../../components/Button.vue";
import Loading from "@/components/ilustration/Loading.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      batch: 0,
      reportingLog: {},
      tabName: "Completion",
      tab: 0,
      message: "",
      isPopup: false,
      titleTabs: ["Completion", "Redemption"],
      headersComplete: [
        {
          text: "ID",
          value: "id",
        },
        { text: "Voucher Code", value: "voucher_code" },
        { text: "Completion Date", value: "completion_date" },
        { text: "Post Score", value: "post_score" },
        { text: "Status", value: "status" },
        { text: "AKSI", value: "actions", sortable: false },
      ],
      headersRedemp: [
        {
          text: "ID",
          value: "id",
        },
        { text: "Voucher Code", value: "voucher_code" },
        { text: "Price", value: "price" },
        { text: "Redeem Code", value: "redeem_code" },
        { text: "Schedule", value: "schedule" },
        { text: "Status", value: "status" },
        { text: "AKSI", value: "actions", sortable: false },
      ],
      reportingComplete: [],
      reportingRedemp: [],
    };
  },
  components: {
    Button,
    Loading,
    Popup
  },
  methods: {
    async getCompleteReport() {
      await this.$crm_http
        .get(`/v1/completion-report?status=SENT&batch=${this.batch}`)
        .then((response) => {
          this.reportingComplete = response.data.data.completion_reports || [];
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    async getRedempReport() {
      await this.$crm_http
        .get(`/v1/redemption-report?status=SENT&batch=${this.batch}`)
        .then((response) => {
          this.reportingRedemp = response.data.data.redemption_reports || [];
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    watchItem(item, c) {
      if (c === "r") {
        this.$router.push("/reporting-log/redemption/detail/" + item.id);
      } else {
        this.$router.push("/reporting-log/completion/detail/" + item.id);
      }
    },
    downloadData() {
      this.completionReportExport();
      this.redemptionReportExport();
    },
    completionReportExport() {
      // Data yang akan diekspor
      const completions = this.reportingComplete.map((item) => [
        item.voucher_code,
        item.completion_date,
        item.post_score,
        item.certificate_url,
      ]);
      const data = [
        ["Kode Voucher", "Completion Date", "Post Score", "Certificate URL"],
        ...completions,
      ];
      const excelConfig = {
        filename: "Completion Report",
        sheet: {
          data: data,
        },
      };
      // Memanggil fungsi export_json_to_excel dari vue-excel-xlsx
      export_json_to_excel(excelConfig);
    },
    redemptionReportExport() {
      // Data yang akan diekspor
      const redemptions = this.reportingRedemp.map((item) => [
        item.voucher_code,
        item.price,
        item.redeem_code,
        item.schedule_code,
        item.schedule,
      ]);
      const data = [
        [
          "Kode Voucher",
          "Nominal",
          "Redeem Code",
          "Schedule Code",
          "Schedule/tgl pelatihan",
        ],
        ...redemptions,
      ];
      const excelConfig = {
        filename: "Completion Report",
        sheet: {
          data: data,
        },
      };
      // Memanggil fungsi export_json_to_excel dari vue-excel-xlsx
      export_json_to_excel(excelConfig);
    },
    chooseTab(e) {
      this.tabName = e;
    },
    async getDetailReprot() {
      await this.$crm_http
        .get(`/v1/reporting-log/${this.$route.params.id}`)
        .then((response) => {
          this.reportingLog = response.data.data.reporting_log || {};
          this.batch = this.reportingLog.batch;
          this.getCompleteReport();
          this.getRedempReport();
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
  },
  mounted() {
    this.getDetailReprot();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>
