<template>
  <div class="body-form">
    <Loading v-if="isLoading" />

    <div class="cart-form">
      <h1 style="text-align: center; margin: 40px 0">{{ $route.meta.name }}</h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Nama Perusahaan</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.corporate_name"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Perusahaan bergerak dibidang</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.company_field"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
            <p>Nama Lengkap PIC</p>
            <v-row>
                <v-col cols="2">
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            label="Title"
            v-model="form.gender"
            dense
          ></v-text-field></v-col>
                <v-col><v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.full_name"
            dense
          ></v-text-field></v-col>
            </v-row>
          
          
        </div>
        <div class="content-form">
          <p>No. Handphone PIC</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.phone_number"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Email PIC</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.email"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Program Pelatihan yang Diinginkan</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.program"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Jumlah Karyawan yang Mengikuti Pelatihan</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.total_student"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Catatan</p>
          <v-textarea class="form-input mt-2"
            v-model="form.notes"
            readonly
            outlined
            
            dense
            >

          </v-textarea>
        </div>
        <div class="content-form">
          <p>Category</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.category"
            dense
          ></v-text-field>
        </div>

        <div class="content-form">
          <p>Web URL</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.web_url"
            dense
          ></v-text-field>
        </div>

        <div class="content-form">
          <p>Job Pic</p>
          <v-text-field
            readonly
            outlined
            class="form-input mt-2"
            v-model="form.job_pic"
            dense
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
export default {
  name: "FormCorporateRegistration",
  data() {
    return {
      id: null,
      isLoading: false,
      form: {
        name: "",
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    async getCorporateRegistration() {
      this.isLoading = true;
      try {
        const resp = await this.$web_http.get(
          "/v1/corporate-registration/" + this.$route.params.id
        );
        this.form = resp.data.data.corporate_registration
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getCorporateRegistration();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  background-color: white;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: absolute;
  top: 0;
  right: -50px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
.form-ctn {
  padding: 0 40px;
}
.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
