<template>
    <div class="line"></div>
</template>

<script>
export default {
    name: "LineDivider",
    props: {
        width: {
            type: String,
            default: "100%"
        },
        height: {
            type: String,
            default: "2px"
        },
        strokeColor: {
            type: String,
            default: "#7B7E8C"
        }
    }
}
</script>

<style scoped>
.line {
    width: v-bind(width);
    height: v-bind(height);
    background-color: v-bind(strokeColor);
}
</style>