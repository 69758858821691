<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Kategori</p>
        <v-select
          :disabled="isWatch"
          class="form-input"
          :items="categories"
          :item-text="'name'"
          :item-value="'value'"
          v-model="form.category"
          placeholder="Pilih kategori"
          solo
        >
        </v-select>
      </div>
      <div class="content-form">
        <p>Title</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          v-model="form.title"
          class="form-input"
          placeholder="Tulis Judul"
          dense
          clearable
        ></v-text-field>
      </div>
      <div class="content-form">
        <p>Description</p>
        <v-textarea :disabled="isWatch" outlined v-model="form.description" class="form-input" placeholder="Tulis Deskripsi" dense clearable></v-textarea>
      </div>
      <div class="content-form mb-5">
        <p>Scope Learning</p>
        <div
          
          class="scope-learning-item"
        >
          <div
            style="display: flex; gap: 10px; flex-wrap: wrap;"
            v-for="(data, idx) in form.scope_learning" :key="idx"
          >
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="form.scope_learning[idx].name"
              class="form-input no-space"
              dense
              placeholder="Enter scope learning"
              style="max-width: 290px"
            ></v-text-field>
            <Button
              name="-"
              width="40px"
              height="35px"
              title="Remove Scope Learning"
              @click="() => form.scope_learning.splice(idx, 1)"
              outline
              v-if="form.scope_learning.length > 1 && !isWatch"
            />
          </div>
          
        </div>
        <Button
            name="+"
            width="40px"
            height="35px"
            title="Add Scope Learning"
            v-if="!isWatch"
            @click="() => form.scope_learning.push({ name: '' })"
          />
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button
          name="PUBLISH"
          width="260px"
          @click="onSubmit"
          :disabled="!validData"
        />
      </div>
    </div>
  </div>
</template>

  
<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormCorporateTraining",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      tags: [],
      categories: [
        { name: "General", value: "GENERAL" },
        { name: "Certified", value: "CERTIFIED" },
      ],
      form: {
        title: "",
        description: "",
        scope_learning: [{ name: "" }],
        category: "",
      },
    };
  },
  components: {
    Button,
    Popup,
    Loading,
  },
  computed: {
    validData() {
      return (
        this.form.title &&
        this.form.description &&
        this.form.category &&
        this.form.scope_learning.some((item) => item.name)
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataCT();
    },
    async getDataCT() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get(
          "/v1/corporate-training/" + this.id
        );
        this.form = resp.data.data;
        if (typeof this.form.scope_learning === "string") {
          this.form.scope_learning = JSON.parse(this.form.scope_learning);
        }
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;

      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const payload = {
          ...this.form,
          scope_learning: this.form.scope_learning.map((item) => ({
            name: item.name,
          })),
        };

        if (this.isEdit) {
          await this.$crm_http.put(
            "/v1/corporate-training/" + this.form.id,
            payload
          );
        } else {
          await this.$crm_http.post("/v1/corporate-training", payload);
        }
        setTimeout(() => {
          this.$router.push("/corporate-training");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

  
  <style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.scope-learning-item {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
  