<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div style="display: flex; justify-content: space-between">
      <div>
        <div class="head-ctn">
          <h1>{{ dataClass.name }}</h1>
        </div>
        <div class="d-flex mt-3" style="gap: 20px">
          <span>Hari: {{ dataClass.days }}</span>
          <span>Jam: {{ dataClass.hours }}</span>
          <span
            ><b>Category: {{ categoryClass.name }}</b></span
          >
        </div>
      </div>
      <h3 style="cursor: pointer" @click="$router.go(-1)">Kembali</h3>
    </div>

    <hr class="line-strip" />
    <div v-for="(item, idx) in categories" :key="idx" class="box-category">
      <div style="width: 97%" @click="toChoice(item)">
        <h2>{{ item.name }}</h2>
        <span>{{ item.description }}</span>
      </div>
      <ArrowIcon direct="left" />
    </div>
  </div>
</template>
<script>
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import ArrowIcon from "@/components/icons/Arrow.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "ClassCategory",
  data() {
    return {
      categories: [
        {
          name: "Pre Test & Post Test",
          description: "Soal Pre Test & Post Test",
          category: "pre post test",
          sequence: 1,
        },
      ],
      message: "",
      isPopup: false,
      categoryClass: {},
      dataClass: {},
      isLoading: false,
      categoryId: Number(this.$route.params.id_category),
      classId: Number(this.$route.params.id_class),
    };
  },
  components: {
    Loading,
    ArrowIcon,
    Popup
  },
  methods: {
    async getClassId() {
      try {
        const resp = await this.$lms_http.get("/v1/class/" + this.classId);
        this.dataClass = resp.data.data.class;
        for (let i = 1; i <= this.dataClass.total_quiz; i++) {
          this.categories.push({
            name: "Quiz " + i,
            description: "Soal Quiz " + i,
            category: "quiz",
            sequence: i,
          });
        }
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getCategoryId() {
      try {
        const resp = await this.$crm_http.get(
          "/v1/class-category/" + this.categoryId
        );
        this.categoryClass = resp.data.data;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    toChoice(item) {
      this.$router.push(
        `/choice/${this.categoryId}/class/${this.classId}/detail?c=${item.category}&g=${item.sequence}`
      );
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getClassId();
    this.getCategoryId();
  },
};
</script>
<style scoped>
.head-ctn {
  display: flex;
  justify-content: space-between;
}
.line-strip {
  margin: 30px 0;
}
.box-category {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 30px 50px;
  background-color: #ffffff;
  border-radius: 20px;
}
.menu-act {
  width: 3%;
  position: relative;
  cursor: pointer;
}
.menu-act:hover .menu-act-item {
  display: flex;
}
.menu-act-item {
  display: none;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  height: 40px;
  width: 150px;
  border-radius: 12px;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 2px 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translate(-80%, 70%);
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  text-align: left;
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.action-menu {
  display: flex;
  gap: 20px;
  color: #acafbf;
}
.action-riwayat {
  border: 1px solid;
  border-radius: 16px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
