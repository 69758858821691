<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Judul</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.title" class="form-input" placeholder="Tulis judul"
          dense clearable></v-text-field>
      </div>
      <div class="content-form">
        <p>Subject</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.subject" class="form-input" placeholder="Tulis subject"
          dense clearable></v-text-field>
      </div>
      <div class="content-form">
        <p>Tipe</p>
        <v-select class="form-select" :items="list_type" :item-text="'name'" :item-value="'value'" v-model="form.type"
          outlined>
        </v-select>
      </div>
      <div class="content-form" v-if="form.type === 'TEXT'">
        <p class="banner-berkas">Body</p>
        <p>Konten</p>
        <vue-editor v-model="form.body" :editorToolbar="$helpers.customToolBar()" class="form-input"
          v-if="!isWatch"></vue-editor>
        <div v-else class="watch-ctn">
          <div v-html="form.body"></div>
        </div>
      </div>
      <div v-else-if="form.type === 'IMAGE'">
        <p class="banner-berkas">Berkas Gambar</p>
        <div class="content-form">
          <p>Gambar Main</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.body" class="form-input" placeholder="Link Image"
            dense clearable></v-text-field>
        </div>
        <img v-if="form.body" :src="form.body" alt="gambar tidak valid" style="width: 200px; border-radius: 14px" />
      </div>
      <div class="content-form" v-if="form.type === 'IMAGE'">
        <p>Redirect URL</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.attachment[0]" class="form-input"
          placeholder="Tulis link redirect" dense clearable></v-text-field>
      </div>
      <p class="banner-berkas">Pengaturan pengiriman</p>
      <div class="content-form">
        <p>Mode</p>
        <v-switch v-model="is_sigle" inset :label="is_sigle ? 'Sekali Kirim' : 'Kirim bertahap'
      "></v-switch>
      </div>
      <div class="content-form" v-if="is_sigle">
        <p>Email</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.senders[0]" class="form-input"
          placeholder="Tulis subject" dense clearable></v-text-field>
      </div>

      <div class="content-form" v-else>
        <p>List Email & attachment</p>
        <div class="my-4">
          <v-row>
            <v-col>
              <h3>Ambil Excel</h3>

              <xlsx-workbook>
                <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name"
                  :sheet-name="sheet.name" />
                <xlsx-download>
                  <Button name="Download" width="260px" />
                </xlsx-download>
              </xlsx-workbook>
            </v-col>
            <v-col>
              <h3>Unggah Excel</h3>
              <input type="file" @change="onChange" />
              <xlsx-read :file="file">
                <xlsx-sheets>
                  <template #default="{ sheets }">
                    <select v-model="selectedSheet">
                      <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                        {{ sheet }}
                      </option>
                    </select>
                  </template>
                </xlsx-sheets>
                <xlsx-table :sheet="selectedSheet" />
                <xlsx-json :sheet="selectedSheet">
                  <template #default="{ collection }">
                    <div>
                      {{ collection }}
                    </div>
                  </template>
                </xlsx-json>
              </xlsx-read>
            </v-col>
          </v-row>
    <!-- <v-table>
    <thead>
      <tr>
        <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
          <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
        </tr>
    </tbody>
  </v-table> -->
        </div>
        
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="PUBLISH" width="260px" @click="onSumbmit" :disabled="!validData" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
import * as XLSX from 'xlsx'
export default {
  name: "FormBlog",
  components: {
    VueEditor,
    Button,
    Loading,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload

  },
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      is_sigle: true,
      isEdit: false,
      list_type: [
        {
          name: "Image",
          value: "IMAGE"
        },
        {
          name: "Text",
          value: "TEXT"
        },
      ],
      form: {
        title: "",
        subject: "",
        type: "IMAGE",
        body: "",
        senders: [],
        attachment: []
      },
      tableHeaders: [],
      tableData: [],
      file: null,
      selectedSheet: null,
      sheetName: "Blast_Hicollagues",
      sheets: [{ name: "Sheet1", data: [{ email: "", nama: "" }] }],
      collection: [{ a: 1, b: 2 }]
    };
  },
  computed: {
    validData() {
      return this.form.title && this.form.subject && this.form.body
    }
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        await this.$crm_http.post("/v1/blast", {
          ...this.form,
        });
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push("/blast-email");
        }, 2000);
      } catch (e) {
        alert(e);
        this.isLoading = false;
      }
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        this.tableHeaders = jsonData[0];
        this.tableData = jsonData.slice(1);
        this.tableData.forEach((item) => {
          this.form.senders.push(item[0])
          this.form.attachment.push(item.slice(1).join(","))
        });
      };
      reader.readAsArrayBuffer(this.file);
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding-bottom: 30px;
  min-height: 100vh;
  width: 100vw;
}

.cart-form {
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 16px;
  margin: 3px;
  font-weight: bold;
}

.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

::v-deep .form-select .v-input__slot {
  height: 56 !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.session-nb-3 {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>