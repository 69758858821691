<template>
    <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.8349 20.715C23.687 20.7166 23.5403 20.6881 23.4036 20.6314C23.267 20.5747 23.1433 20.4909 23.0399 20.385C22.8292 20.1741 22.7109 19.8882 22.7109 19.59C22.7109 19.2919 22.8292 19.006 23.0399 18.795L27.5399 14.295C28.3103 13.5194 28.7426 12.4707 28.7426 11.3775C28.7426 10.2844 28.3103 9.2356 27.5399 8.46002C26.7516 7.71418 25.7076 7.29856 24.6224 7.29856C23.5372 7.29856 22.4932 7.71418 21.7049 8.46002L17.2049 12.96C16.9917 13.1587 16.7096 13.2669 16.4181 13.2618C16.1267 13.2566 15.8486 13.1386 15.6425 12.9325C15.4364 12.7263 15.3183 12.4483 15.3132 12.1568C15.308 11.8654 15.4162 11.5833 15.6149 11.37L20.1149 6.87002C20.692 6.22065 21.3958 5.69608 22.183 5.32852C22.9701 4.96097 23.8241 4.75818 24.6925 4.73262C25.5609 4.70706 26.4253 4.85925 27.2328 5.17987C28.0402 5.50048 28.7736 5.98275 29.3879 6.59705C30.0022 7.21136 30.4845 7.94474 30.8051 8.75218C31.1257 9.55961 31.2779 10.4241 31.2523 11.2924C31.2268 12.1608 31.024 13.0148 30.6564 13.802C30.2889 14.5892 29.7643 15.2929 29.1149 15.87L24.6149 20.37C24.5141 20.4761 24.3932 20.5612 24.2594 20.6204C24.1255 20.6796 23.9813 20.7118 23.8349 20.715Z"
        fill="#7B7E8C"
      />
      <path
        d="M11.3399 31.035C10.0813 31.0319 8.85179 30.6563 7.80629 29.9556C6.76079 29.2549 5.94609 28.2604 5.46486 27.0975C4.98364 25.9345 4.85742 24.6551 5.10212 23.4206C5.34681 22.186 5.95147 21.0515 6.83989 20.16L11.3399 15.66C11.5532 15.4612 11.8352 15.3531 12.1267 15.3582C12.4181 15.3633 12.6962 15.4814 12.9023 15.6875C13.1084 15.8937 13.2265 16.1717 13.2317 16.4632C13.2368 16.7546 13.1286 17.0367 12.9299 17.25L8.42989 21.75C7.7136 22.5331 7.32671 23.5622 7.34967 24.6232C7.37262 25.6843 7.80365 26.6956 8.55314 27.4471C9.30262 28.1985 10.3129 28.6321 11.3739 28.6578C12.4349 28.6835 13.4649 28.2992 14.2499 27.585L18.7499 23.085C18.9632 22.8863 19.2452 22.7781 19.5367 22.7832C19.8281 22.7883 20.1062 22.9064 20.3123 23.1125C20.5184 23.3187 20.6365 23.5967 20.6417 23.8882C20.6468 24.1796 20.5386 24.4617 20.3399 24.675L15.8399 29.175C15.2487 29.7654 14.5471 30.2335 13.7749 30.5527C13.0028 30.8718 12.1754 31.0357 11.3399 31.035Z"
        fill="#7B7E8C"
      />
      <path
        d="M22.7726 16.4099C23.6513 15.5313 23.6513 14.1066 22.7726 13.228C21.894 12.3493 20.4693 12.3493 19.5907 13.228L13.2267 19.5919C12.348 20.4706 12.348 21.8952 13.2267 22.7739C14.1054 23.6526 15.53 23.6526 16.4087 22.7739L22.7726 16.4099Z"
        fill="#7B7E8C"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'LinkIcon',
    props: {
      width: {
        type: Number,
        default: 25,
      },
      height: {
        type: Number,
        default: 25,
      },
    },
  };
  </script>