<template>
    <div class="container-size">
        <div class="text-center mb-10" style="margin-top:150px"><img src="/img/other/ic_coming_soon.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:2.0rem">Oops! You don't have any access to this page</span></div>
        <div class="text-center mt-2 mb-10">
            <v-btn @click="goToLoginPage">Login</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToLoginPage() {
            this.$router.push("/login")
        }
    }
}
</script>