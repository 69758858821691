<template>
  <div class="body">
    <v-row class="login">
      <v-col md="8" sm="12" class="left-section">
        <v-container>
          <div
            class="d-flex mt-2"
            style="align-items: center; justify-content: center"
          >
            <img
              src="img/logo.png"
              style="width: 170px; height: 120px; cursor: pointer"
              @click="goHome"
            />
          </div>
          <div class="title">
            <h3>
              Mulai
              <span class="blue-title"> Langkah Hebat </span>
              untuk Persiapkan Masa Depan
            </h3>
          </div>
          <div class="d-flex" style="justify-content: flex-start">
            <img
              src="img/line-blue.png"
              style="margin-left: 247px; margin-top: -10px"
              width="230px"
            />
          </div>
          <!-- Description -->
          <div class="left-section-desc">
            Siap jadi talenta profesional melalui program upskilling /
            reskilling dalam Transformasi Digital
          </div>
          <!-- Image -->
          <div class="mt-4">
            <img class="left-section-img" src="img/login-img.png" width="100%" height="100%" />
          </div>
        </v-container>
      </v-col>
      <v-col md="4" sm="12" class="right-section">
        <v-container class="px-6" style="position: relative; height: 100vh">
          <div class="login-text">
            <h3 class="font-weight-black">Masuk</h3>
            <!-- Updated font weight here -->
          </div>
          <!-- Notification if user entered incorrect email or password -->
          <v-alert
            v-if="showError"
            color="#ff5252"
            icon="$error"
            icon-color="white"
            dark
            class="custom-alert"
          >
            Email / password tidak ditemukan
          </v-alert>
          <v-alert
            v-if="showUnauthorizedError"
            color="#ff5252"
            icon="$error"
            icon-color="white"
            dark
            class="custom-alert"
          >
            Unauthorized Access
          </v-alert>
          <v-form>
            <v-text-field
              style="border-radius: 20px"
              v-model="email"
              :rules="emailRules"
              filled
              label="E-mail"
              required
              class="custom-text-field"
              placeholder="Email"
            ></v-text-field>

            <v-text-field
              style="border-radius: 20px"
              v-model="password"
              :rules="passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-2"
              filled
              label="Password"
              hint="At least 8 characters"
              class="input-group--focused"
              @click:append="showPassword = !showPassword"
              placeholder="Password"
            ></v-text-field>
            <div class="d-flex flex-column">
              <a
                href="#"
                style="
                  text-decoration: none;
                  color: #575966;
                  text-align: right;
                  font-weight: 600;
                "
                >Lupa Sandi?</a
              >
              <v-col style="padding: 0">
                <v-btn
                  style="
                    background-color: rgb(0, 0, 128);
                    color: white;
                    border-radius: 15px;
                    width: 100%;
                    height: 45px;
                    margin-top: 30px;
                  "
                  @click="login"
                  >Masuk</v-btn
                >
              </v-col>
            </div>
          </v-form>
          <p style="font-size: 11pt; text-align: center; margin-top: 15px">
            Dengan masuk ke dalam akun, kamu menyetujui
            <a
              style="
                text-decoration: none;
                color: rgb(0, 0, 128);
                font-weight: 600;
              "
              href="#"
              >Syarat & Ketentuan</a
            >
            dan
            <a
              style="
                text-decoration: none;
                color: rgb(0, 0, 128);
                font-weight: 600;
              "
              href="#"
              >Kebijakan Privasi</a
            >
            Hicolleagues.
          </p>
          <footer
            style="
              width: 100%;
              text-align: center;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, -100%);
            "
          >
            <p style="font-size: 10pt">@2023 PT Generasi Anak Muda Berkarya</p>
          </footer>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
      ],
      showPassword: false,
      showError: false,
      showUnauthorizedError: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    async login() {
      this.showError = false;
      this.showUnauthorizedError = false;
      try {
        await this.$web_http
          .post("/v1/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.data.data.user.user_type.id == 2) {
              this.showUnauthorizedError = true;
            } else {
              localStorage.setItem("user", response.data.data.user.token);
              this.$router.push("/home");
            }
          });
      } catch (e) {
        this.showErrorAlert();
        // alert("harap isi semua field yang tersedia");
      }
    },
    showErrorAlert() {
      this.showError = true;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Poppins", sans-serif;
}

h3 {
  font-weight: bold;
}

.right-section {
  background-color: #fff;
  width: 100%;
  max-height: 100vh;
  border-radius: 80px 0px 0px 80px;
}

.blue-title {
  color: #162ca2;
  font-family: "Open Sans", sans-serif !important;
  font-weight: normal;
}

.title {
  font-weight: 700;
  font-size: 25px !important;
  line-height: 42px;
  text-align: center;
  color: #36373f;
}

.left-section {
  height: 100vh;
  text-align: center;
}

.body {
  background-color: #f1f6ff;
  height: 100%;
  max-height: 100vh;
  margin: 0;
}

.login-text {
  height: 10vh;
  margin-top: 20vh;
  text-align: right;
  margin-right: 20px;
  font-weight: bold;
  font-size: 25px;
}

.left-section-desc {
  color: #575966;
  padding-left: 30vh;
  padding-right: 30vh;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.left-section-img {
  width: 700px;
  height: 400px;
}

.custom-alert {
  display: flex;
  height: 10vh;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 20px;
}

::v-deep .v-input__slot::before,
::v-deep .v-input__slot::after {
  border-style: none !important;
}

::v-deep .v-input__slot {
  background-color: #f1f6ff !important;
}

p {
  font-family: "Open Sans", sans-serif !important;
  color: #575966;
}
</style>
