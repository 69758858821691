<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33325 7.66658C6.33325 7.48977 6.40349 7.3202 6.52851 7.19518C6.65354 7.07016 6.82311 6.99992 6.99992 6.99992C7.17673 6.99992 7.3463 7.07016 7.47132 7.19518C7.59635 7.3202 7.66658 7.48977 7.66658 7.66658V9.66658C7.66658 9.8434 7.59635 10.013 7.47132 10.138C7.3463 10.263 7.17673 10.3333 6.99992 10.3333C6.82311 10.3333 6.65354 10.263 6.52851 10.138C6.40349 10.013 6.33325 9.8434 6.33325 9.66658V7.66658ZM6.99992 3.99992C6.7347 3.99992 6.48035 4.10528 6.29281 4.29281C6.10528 4.48035 5.99992 4.7347 5.99992 4.99992C5.99992 5.26513 6.10528 5.51949 6.29281 5.70703C6.48035 5.89456 6.7347 5.99992 6.99992 5.99992C7.26513 5.99992 7.51949 5.89456 7.70703 5.70703C7.89456 5.51949 7.99992 5.26513 7.99992 4.99992C7.99992 4.7347 7.89456 4.48035 7.70703 4.29281C7.51949 4.10528 7.26513 3.99992 6.99992 3.99992ZM0.333252 6.99992C0.333252 5.23181 1.03563 3.53612 2.28587 2.28587C3.53612 1.03563 5.23181 0.333252 6.99992 0.333252C8.76803 0.333252 10.4637 1.03563 11.714 2.28587C12.9642 3.53612 13.6666 5.23181 13.6666 6.99992C13.6666 8.76803 12.9642 10.4637 11.714 11.714C10.4637 12.9642 8.76803 13.6666 6.99992 13.6666C5.23181 13.6666 3.53612 12.9642 2.28587 11.714C1.03563 10.4637 0.333252 8.76803 0.333252 6.99992ZM6.99992 1.66659C5.58543 1.66659 4.22888 2.22849 3.22868 3.22868C2.22849 4.22888 1.66659 5.58543 1.66659 6.99992C1.66659 8.41441 2.22849 9.77096 3.22868 10.7712C4.22888 11.7713 5.58543 12.3333 6.99992 12.3333C8.41441 12.3333 9.77096 11.7713 10.7712 10.7712C11.7713 9.77096 12.3333 8.41441 12.3333 6.99992C12.3333 5.58543 11.7713 4.22888 10.7712 3.22868C9.77096 2.22849 8.41441 1.66659 6.99992 1.66659Z"
      fill="#ACAFBF"
    />
  </svg>
</template>

<script>
  export default {
    name: "info-icon",
    props: {
      width: {
        type: Number,
        default: 14,
      },
      height: {
        type: Number,
        default: 14,
      },
    },
  };
</script>
