import { render, staticRenderFns } from "./Testimoni.vue?vue&type=template&id=4b395aff&scoped=true"
import script from "./Testimoni.vue?vue&type=script&lang=js"
export * from "./Testimoni.vue?vue&type=script&lang=js"
import style0 from "./Testimoni.vue?vue&type=style&index=0&id=4b395aff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b395aff",
  null
  
)

export default component.exports