<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus Corporate Training ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <!-- <div class="container-box">
          <h1>FAQ</h1>
          <div class="action-head">
            <div class="f-search">
              <div class="f-search-ic">
                <img
                  src="/img/icons/search.png"
                  style="width: 100%"
                  alt="Search Ic"
                />
              </div>
              <input v-model="search" type="text" class="f-input" placeholder="Cari" @keyup="getFaq" />
            </div>
            <Button icon="+" name="Buat FAQ" @click="() => $router.push('/faq/new')" />
          </div>
        </div> -->
    <div class="top-container-box">
      <h1>Corporate Training</h1>
      <div>
        <Button
          icon="+"
          outline
          name="Create CT"
          style="background-color: #3b54d9; color: #ffffff; width: 160px"
          @click="() => $router.push('/corporate-training/new')"
        />
      </div>
    </div>
    <!-- <div class="container-box">
        <v-row>
          <v-col>
            Kategori
            <v-select
              style="width: 100%; height: 7%; margin-top: 5px"
              label="Pilih kategori"
              v-model="filter.category"
              :items="categories"
              :item-text="'name'"
              :item-value="'value'"
              class="form-input"
              solo
              @input="updateIsFilterActive"
            ></v-select>
          </v-col>
          <v-col>
            Urutkan
            <v-select
              style="width: 70%; height: 7%; margin-top: 5px"
              label="Urutkan dari"
              v-model="filter.sort_by"
              :items="sort_by"
              :item-text="'name'"
              :item-value="'value'"
              class="form-input"
              solo
              @input="updateIsFilterActive"
            ></v-select>
          </v-col>
          <v-col
            style="
              display: flex;
              justify-content: flex-end;
              gap: 10px;
              margin-top: 25px;
            "
          >
            <Button
              name="RESET FILTER"
              outline
              @click="clearFilter"
              v-if="isFilterActive"
            />
            <Button name="TERAPKAN FILTER" @click="onFilter" width="190px" />
          </v-col>
        </v-row>
      </div> -->
    <v-row>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            margin-top: 7px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="corporate_trainings"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <span
          :style="`color: ${item.is_active === 'Aktif' ? 'green' : 'red'}`"
          >{{ item.is_active }}</span
        >
      </template>
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getCT"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="totalData" />

    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{
          Math.floor((currentPage - 1) * 10 + corporate_trainings.length)
        }}
        dari {{ totalData }}</b
      >
      Corporate Training yang tertampil
    </p>
  </div>
</template>
  
  <script>
import Button from "../../../components/Button.vue";
import NewModal from "@/components/NewModal.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      isFilterActive: false,
      message: "",
      isPopup: false,
      categories: [
        { name: "General", value: "GENERAL" },
        { name: "Certified", value: "CERTIFIED" },
      ],
      filter: {
        sort_by: "",
        category: "",
      },
      sort_by: [
        {
          name: "Terlama",
          value: "asc",
        },
        {
          name: "Terbaru",
          value: "desc",
        },
      ],
      showFilterModal: false,
      filterModal: [
        {
          title: "Kategori",
          name: "category",
          items: [
            { name: "General", value: "GENERAL" },
            { name: "Certified", value: "CERTIFIED" },
          ],
        },
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
      headers: [
        {
          text: "NO",
          value: "id",
        },
        { text: "Title", value: "title", width: "200" },
        { text: "Category", value: "category" },
        { text: "Created At", value: "created_at" },
        { text: "AKSI", value: "actions", sortable: false, width: "132px" },
      ],
      corporate_trainings: [],
    };
  },
  components: {
    Button,
    NewModal,
    Loading,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    FilterIcon,
    FilterModal,
    Popup
  },
  methods: {
    applyFilters() {
      this.getCT();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getCT() {
      await this.$crm_http
        .get(
          `/v1/corporate-training?sort_by=${this.filter.sort_by}&category=${
            this.filter.category || ""
          }&page=${this.currentPage}&per_page=10`
        )
        .then((response) => {
          if (!response.data.data.corporate_trainings) {
            this.corporate_trainings = [];
            return;
          }
          this.corporate_trainings = response.data.data.corporate_trainings.map(
            (element) => {
              return {
                id: element.id,
                title: element.title,
                description: element.description,
                category: element.category,
                scope_learning: element.scope_learning,
                created_at: this.format_date(element.created_at),
              };
            }
          );
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    watchItem(item) {
      this.$router.push("/corporate-training/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/corporate-training/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete("/v1/corporate-training/" + this.id);
        this.getCT();
        this.isModal = false;
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;

      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    onFilter() {
      this.currentPage = 1;
      this.isFilterActive = true;
      this.getCT();
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        sort_by: "",
        category: "",
      };
      this.getCT();
    },
    handleClearButtonClick() {
      this.filter = {
        sort_by: this.filter.sort_by,
        category: "",
      };
      this.updateIsFilterActive();
    },
    updateIsFilterActive() {
      this.isFilterActive = !(
        !this.filter.category && this.filter.sort_by == "desc"
      );
      // this.isFilterActive = true;
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getCT();
  },
};
</script>
  
  <style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
</style>
  