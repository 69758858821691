<template>
    <div class="container-custom">
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <div class="list-scrumb">
        <div
          v-for="(item, idx) in items"
          :key="idx"
          class="list-scrumb ml-4 cursor-pointer"
          @click="$router.go(-1)"
        >
          <span>{{ item.text }}</span>
          <arrow strokeColor="#7B7E8C" direct="left" />
        </div>
        <span class="title-scrumb">Detail </span>
      </div>
  
      <div class="d-flex" style="margin-top: 45px; margin-left: 10px">
        <div>
          <img
            :src="assesment.image_url"
            width="100px"
            height="100px"
            style="border-radius: 20%"
          />
        </div>
  
        <div style="margin-left: 30px">
          <div class="badges">
            <div class="badge" v-if="assesment.category === 4">
              <img src="/img/other/ic_prakerja_new.png" width="69" height="22" />
            </div>
            <div class="badge" v-if="assesment.category === 4"><span>Webinar</span></div>
            <div class="button-light-blue" v-if="assesment.category !== 4">
            <span>Public Training</span>
          </div>
          </div>
  
          <div class="title">
            <h2>{{ assesment.name }}</h2>
          </div>
  
          <div class="d-flex" style="margin-top: 16px">
            <div class="d-flex">
              <div>
                <img src="/img/icons/ic_level.png" width="24px" height="24px" />
              </div>
              <span style="margin-left: 10px; color: #36373f">{{ assesment.level }}</span>
            </div>
  
            <div class="d-flex" style="margin-left: 20px">
              <div>
                <img src="/img/icons/ic_date.png" width="24px" height="24px" />
              </div>
              <span style="margin-left: 10px; color: #36373f"
                >{{ assesment.schedule }}, {{ assesment.hours }}</span
              >
            </div>
  
            <div class="d-flex" style="margin-left: 20px">
              <div>
                <img
                  src="/img/icons/ic_training_method.png"
                  width="24px"
                  height="24px"
                />
              </div>
              <span style="margin-left: 10px; color: #36373f"
                >{{ assesment.learning_method | formatMethode }}</span
              >
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-radius">
        <div class="d-flex">
          <h3>Nilai Pelatihan</h3>
        </div>
        <span>Nilai yang berhasil HiColers selesaikan dalam pelatihan ini</span>
  
        <div
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 50px"
        >
          <div style="width: 100%">
            <h3>PreTest</h3>
            <span>Tes kemampuan dasar sebelum mengikuti pelatihan</span>
          </div>
          <div><h3>{{ assesment.pre_test_score }}</h3></div>
          <div
            :style="getColor(assesment.pre_test_score)"
            class="green-radius"
          ></div>
        </div>
  
        <div class="bg-ghost-white" style="margin-top: 20px">
          <div class="d-flex" style="align-items: center; margin-bottom: 20px">
            <div style="width: 100%">
              <div class="d-flex" style="align-items: center">
                <h3>Quiz <span style="color: #7B7E8C; font-weigth: 400; font-size: 14px;">(Rata-rata nilai)</span></h3>
              </div>
              <span
                >Tes untuk mengukur kemampuan & kepahaman terhadap materi
                pelatihan</span
              >
            </div>
            <div><h3>{{ assesment.quiz_score }}</h3></div>
            <div :style="getColor(assesment.quiz_score)" class="green-radius"></div>
          </div>
  
          <div style="padding: 10px 50px; max-width: 96%">
            <div v-for="(item, idx) in assesment.assesment_quiz" :key="idx" >
            <div class="d-flex detail-quizz" >
              <h4 style="width: 100%">Quiz Sesi {{ item.iteration }}</h4>
              <span>{{ item.score }}</span>
            </div>
            <div class="long-line"></div>
          </div>
          </div>
        </div>
  
        <div class="bg-ghost-white" style="margin-top: 20px" v-if="assesment.category === 4">
          <div class="d-flex" style="align-items: center; margin-bottom: 20px">
            <div style="width: 100%">
              <div class="d-flex" style="align-items: center">
                <h3>Tugas Praktik Mandiri <span style="color: #7B7E8C; font-weigth: 400; font-size: 14px;">(Rata-rata nilai)</span></h3>
              </div>
              <span
                >Tes evaluasi untuk mengetahui tingkat penyerapan ilmu melalui
                tugas praktik</span
              >
            </div>
            <div><h3>{{ assesment.tpm_score}}</h3></div>
            <div :style="getColor(assesment.tpm_score)" class="green-radius"></div>
          </div>
  
          <div style="padding: 10px 50px; max-width: 96%">
            <div v-for="(item, idx) in assesment.assesment_tpm" :key="idx" >
            <div class="d-flex detail-quizz">
              <h4 style="width: 100%">Tugas Praktik Mandiri Sesi {{ item.iteration }}</h4>
              <span>{{ item.score }}</span>
            </div>
            <div class="long-line"></div>
            </div>
          </div>
        </div>
  
        <div
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 20px"
        >
          <div style="width: 100%">
            <h3>Post-Test</h3>
            <span
              >Tes evaluasi untuk mengetahui tingkat penyerapan ilmu & pemahaman
              seluruh materi pelatihan</span
            >
          </div>
          <div><h3>{{ assesment.post_test_score }}</h3></div>
          <div :style="getColor(assesment.post_test_score)" class="green-radius"></div>
        </div>
  
        <div
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 20px"
        >
          <div style="width: 100%">
            <h3>Unjuk Keterampilan</h3>
            <span
              >Evaluasi performa HiColers melalui karya Unjuk Keterampilan sebagai
              pendukung kompetensi pelatihan</span
            >
          </div>
          <div><h3>{{assesment.final_project_score}}</h3></div>
          <div :style="getColor(assesment.final_project_score)" class="green-radius"></div>
        </div>
  
        <div class="bg-ghost-white" style="margin-top: 20px">
          <div style="width: 100%">
            <h3>Catatan</h3>
            <span v-if="!assesment.notes">-</span>
            <span v-html="assesment.notes"></span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Arrow from "@/components/icons/Arrow.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    components: {
      Arrow,
      Popup
    },
    data() {
      return {
        items: [
          {
            text: "Penilaian",
            disabled: false,
            href: "/assesment",
          },
        ],
        message: "",
        isPopup: false,
        assesment: {},
      };
    },
    methods: {
      getColor(e) {
        if (e <= 50) return "background:#B80F0A";
        else if (e >= 70 && e <= 79) return "background:#E5AF0B";
        else return "background:#01A368";
      },
      async getDetailAssesment() {
        await this.$lms_http
          .get("/v1/user-assesment/" + this.$route.params.id)
          .then((response) => {
            const data = response.data.data.assesment
            this.assesment = data;
            if (!this.assesment.assesment_tpm) {
              this.assesment.assesment_tpm = [];
            }
          })
          .catch((err) => {
            this.message = err.response.data.data
            this.isPopup = true
          });
      },
    },
    mounted() {
      this.getDetailAssesment();
      this.getDetailClass()
    },
  };
  </script>
  
  <style scoped>
  .green-radius {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #01a368;
    margin-left: 20px;
  }
  
  .detail-quizz {
    padding: 10px 0px;
    align-items: center;
  }
  
  .badges {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .badge {
    display: flex;
    background-color: #f5f6f9;
    border-radius: 20px;
    height: 30px;
    padding: 0px 16px;
    align-items: center;
  }
  
  .badge span {
    color: #162ca2;
  }
  
  .list-scrumb {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: bold;
    color: #162ca2;
    font-size: 18px;
  }
  
  .title-scrumb {
    color: #7b7e8c;
    font-weight: 400;
  }
  
  .download {
    margin-left: 48px;
  }
  
.bg-ghost-white {
  background: #f7f9fd;
  border-radius: 24px;
  padding: 28px 50px;
}

.container-radius {
  margin-top: 35px;
  padding: 40px;
  background: #ffffff;
  border-radius: 24px;
}
  </style>
  