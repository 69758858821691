<template>
    <div class="ma-10">
      <h1>Attandence</h1>
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <div class="container-box mt-10 filter-box">
        <div class="input-group">
          <span class="label">Cari</span>
  
          <div class="action-head">
            <div class="f-search">
              <div class="f-search-ic">
                <img
                  src="/img/icons/search.png"
                  style="width: 100%"
                  alt="Search Ic"
                />
              </div>
              <input
                v-model="filter.email"
                type="text"
                class="f-input"
                placeholder="Cari berdasarkan email"
              />
            </div>
          </div>
        </div>
  
        <div class="input-group btn">
          <Button name="Terapkan Filter" width="200px" @click="getAttandence" />
        </div>
      </div>
  
      <v-data-table
        :headers="headers"
        :items="attandence"
        :search="search"
        class="elevation-1 mt-10 table"
        :items-per-page="-1"
        hide-default-footer
        disable-pagination
      >
      </v-data-table>
  
      <div class="data-count">
        <span
          ><b
            >{{ pageNo(attandence[attandence.length - 1]) || 0 }} dari
            {{ totalData }} </b
          >data yang tertampil</span
        >
      </div>
  
      <v-row justify="center">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              total-visible="8"
              :length="total"
              @input="getAttandence"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import Button from "@/components/Button.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    components: {
      Button,
      Popup
    },
    data() {
      return {
        search: "",
        email: "",
        modal: false,
        currentPage: 1,
        perPage: 10,
        totalPage: 0,
        value: "email",
        message: "",
        isPopup: false,
        total: 0,
        dialog: false,
        filter: {
          email: "",
          sortBy: "desc",
        },
        sortByList: [
          {name: "Terbaru", value: "desc"},
          {name: "Terlama", value: "asc"},
        ],
        headers: [
          {
            text: "No",
            value: "no",
          },
          {text: "Nama Kelas", value: "class_name"},
          {text: "Nama User", value: "user_name"},
          {text: "Jam Masuk", value: "start_date"},
          {text: "Jam Keluar", value: "end_date"},
        ],
        attandence: [],
      };
    },
    methods: {
      async getAttandence() {
        await this.$lms_http
          .get(
            "/v1/attendance?page=" +
              this.currentPage +
              "&limit=30&email=" +
              this.email
          )
          .then(async (response) => {
            this.attandence = [];
            var id = 1;
            const list_data = response.data.data.attendances
            for (let i = 0; i < list_data.length; i++) {
              const data = list_data[i];
              const classes = await this.getClass(data.class_id)
              const user = await this.getUser(data.user_id)
              this.attandence.push({
                id: id++,
                class_name: classes,
                user_name: user,
                ...data
              }); 
            }
            const t = parseInt(response.data.data.total);
            this.total = Math.floor(t / 30 + (t % 30 === 0 ? 0 : 1));
            this.totalData = response.data.data.total;
            let no = (this.currentPage - 1) * this.perPage + 1;
            this.attandence = this.attandence.map((item) => {
              item.no = no++;
              return item;
            });
          })
          .catch((err) => {
            this.message = err.response.data.data
            this.isPopup = true
          });
      },
      async getClass(id) {
      return await this.$crm_http
        .get(`/v1/class/${id}`)
        .then((response) => {
          return response.data.data.class.name;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
          return ""
        });
      },
      async getUser(id) {
        try {
          const response = await this.$crm_http.get(`/v1/user/${id}`);
          return response.data.data.user.full_name;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          return ""
        }
      },
      pageNo(item) {
        if (item) {
          return item.no;
        }
        return;
      }
    },
    mounted() {
      this.getAttandence();
    },
  };
  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .filter-box.input * {
    width: 100%;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .input-group .label {
    font-size: larger;
    color: #000000;
  }
  
  .input-group.btn {
    margin-left: auto;
    justify-content: flex-end;
  }
  
  .dropdown {
    width: 70%;
    height: 7%;
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  
  .action-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .f-search {
    border: 1px solid #d3d6e1;
    width: 350px;
    height: 45px;
    gap: 10px;
    display: flex;
    border-radius: 14px;
  }
  
  .f-search-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin-left: 10px;
  }
  
  .f-input {
    width: 86%;
    background: transparent;
    border: none;
  }
  
  .f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
  }
  
  .data-count {
    height: 0;
    position: relative;
    overflow-y: visible;
    display: flex;
  }
  
  .data-count span {
    position: absolute;
    font-size: medium;
    top: 35px;
  }
  </style>
  