<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.68 16.96L18.55 9.65001C17.49 7.17001 15.54 7.07001 14.23 9.43001L12.34 12.84C11.38 14.57 9.58999 14.72 8.34999 13.17L8.12999 12.89C6.83999 11.27 5.01999 11.47 4.08999 13.32L2.36999 16.77C1.15999 19.17 2.90999 22 5.58999 22H18.35C20.95 22 22.7 19.35 21.68 16.96Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.97 8C8.62686 8 9.97 6.65685 9.97 5C9.97 3.34315 8.62686 2 6.97 2C5.31315 2 3.97 3.34315 3.97 5C3.97 6.65685 5.31315 8 6.97 8Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "HitBanner",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
