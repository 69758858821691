var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-menu"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"left-sec"},[_vm._m(0),_c('div',{staticClass:"menu-box"},_vm._l((_vm.sidebarParent),function(item,index){return _c('div',{key:index,class:`navigate-data ${
            _vm.sidebar === item.name ? 'navigate-active' : ''
          }`,on:{"click":function($event){item.disable ? null : _vm.toglerData(item.name)}}},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"center"}},[_c(item.component,{tag:"component"})],1),_c('p',{staticStyle:{"font-size":"9px"}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"garis"},[_c('div',{staticClass:"navigate-3"},[_c('div',{staticClass:"d-flex ml-2"},[_c('garis')],1)])]),_c('div',{staticClass:"profile profile-margin-right flex-column",staticStyle:{"position":"absolute","bottom":"10px","margin-left":"10px"}},[_c('v-row',{staticClass:"profile-hover"},[_c('div',{staticClass:"logout-box",staticStyle:{"margin-left":"-10px","margin-top":"10px","padding":"5px"},on:{"click":_vm.logout}},[_c('LogOutIcon',{staticClass:"logout-icon",staticStyle:{"width":"10px","height":"10px"}}),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Logout")])],1)]),_vm._m(1)],1)])]),(_vm.isSidebarActive && _vm.sidebar != 'Beranda')?_c('v-container',{staticClass:"right-sec"},[_c('div',{staticClass:"side-data"},[_c('h2',[_vm._v(_vm._s(_vm.sidebar))]),_vm._l((_vm.sidebarParent.find(
            (item) => item.name === _vm.sidebar
          ).submenu),function(data,idx){return _c('div',{key:idx,staticClass:"program"},[_c('span',[_vm._v(_vm._s(data.name))]),_vm._l((data.children),function(data2,idx2){return _c('div',{key:idx2,staticClass:"navigate-3 test",class:{ actived: _vm.layout === data2.name },on:{"click":function($event){return _vm.toglerSubMenu(data2.name, data2.path)}}},[_c('div',{staticClass:"navigate-item d-flex ml-2"},[_c(data2.component,{tag:"component"})],1),_c('div',{staticClass:"ml-2",staticStyle:{"font-size":"15px"}},[_c('p',[_vm._v(_vm._s(data2.name))])])])})],2)})],2)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-img"},[_c('img',{staticStyle:{"height":"40px"},attrs:{"src":"/img/site_logo.png","alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"20px","margin-top":"15px"}},[_c('div',{staticClass:"profile-img",staticStyle:{"font-size":"15px","padding":"2px 0","margin-top":"5px"}},[_vm._v(" AD ")])])
}]

export { render, staticRenderFns }