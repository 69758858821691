<template>
    <div class="ma-10">
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <Loading v-if="isLoading" />
      <div style="display: flex; justify-content: space-between;">
          <div>
              <div class="head-ctn">
                <h1>Assessment review LMS</h1>
              </div>
          </div>
          <h3 style="cursor: pointer;" @click="$router.go(-1)">Kembali</h3>
      </div>
      
      <hr class="line-strip" />
      <div v-for="(item, idx) in classes" :key="idx" class="box-category">
        <div style="width: 97%" @click="toChoice(item)">
          <h2>{{ item.name }}</h2>
          <div class="d-flex mt-3" style="gap: 20px;">
                  <span>Hari: {{ item.days }}</span>
                  <span>Jam: {{ item.hours }}</span>
                  <span><b>Category: {{ $helpers.categoryClass(item.category) }}</b></span>
              </div>
        </div>
          <ArrowIcon direct="left" />
      </div>
    </div>
  </template>
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import getAuthentification from "@/utils/badrequest.js";
  import ArrowIcon from "@/components/icons/Arrow.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    name: "ClassCategory",
    data() {
      return {
        classes: [],
        message: "",
        isPopup: false,
        isLoading: false,
      };
    },
    components: {
      Loading,
      ArrowIcon,
      Popup
    },
    methods: {
      async getClasses() {
          try {
              const resp = await this.$lms_http.get("/v1/class");
              this.classes = resp.data.data.classes
          } catch (err) {
            this.message = err.response.data.data
            this.isPopup = true
          }
      },
      toChoice(item) {
        this.$router.push(
          `/assessment-review/${item.class_id}`
        );
      },
    },
    beforeCreate() {
      getAuthentification();
    },
    mounted() {
      this.getClasses();
    },
  };
  </script>
  <style scoped>
  .head-ctn {
    display: flex;
    justify-content: space-between;
  }
  .line-strip {
    margin: 30px 0;
  }
  .box-category {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 30px 50px;
    background-color: #ffffff;
    border-radius: 20px;
  }
  .menu-act {
    width: 3%;
    position: relative;
    cursor: pointer;
  }
  .menu-act:hover .menu-act-item {
    display: flex;
  }
  .menu-act-item {
    display: none;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    height: 40px;
    width: 150px;
    border-radius: 12px;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.2);
    padding: 2px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translate(-80%, 70%);
  }
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    text-align: left;
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .action-menu {
    display: flex;
    gap: 20px;
    color: #acafbf;
  }
  .action-riwayat {
    border: 1px solid;
    border-radius: 16px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  </style>
  