<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0002 22.75C11.3302 22.75 10.6502 22.58 10.0502 22.23L4.11016 18.8C2.91016 18.1 2.16016 16.81 2.16016 15.42V8.57999C2.16016 7.18999 2.91016 5.89999 4.11016 5.19999L10.0502 1.77C11.2502 1.07 12.7402 1.07 13.9502 1.77L19.8902 5.19999C21.0902 5.89999 21.8402 7.18999 21.8402 8.57999V15.42C21.8402 16.81 21.0902 18.1 19.8902 18.8L13.9502 22.23C13.3502 22.58 12.6702 22.75 12.0002 22.75ZM12.0002 2.74998C11.5902 2.74998 11.1702 2.85998 10.8002 3.06998L4.86016 6.49998C4.12016 6.92998 3.66016 7.71999 3.66016 8.57999V15.42C3.66016 16.27 4.12016 17.07 4.86016 17.5L10.8002 20.93C11.5402 21.36 12.4602 21.36 13.2002 20.93L19.1402 17.5C19.8802 17.07 20.3402 16.28 20.3402 15.42V8.57999C20.3402 7.72999 19.8802 6.92998 19.1402 6.49998L13.2002 3.06998C12.8302 2.85998 12.4102 2.74998 12.0002 2.74998Z" fill="currentColor"/>
    <path d="M11.9999 11.7498C10.2999 11.7498 8.91992 10.3698 8.91992 8.6698C8.91992 6.9698 10.2999 5.58984 11.9999 5.58984C13.6999 5.58984 15.0799 6.9698 15.0799 8.6698C15.0799 10.3698 13.6999 11.7498 11.9999 11.7498ZM11.9999 7.08984C11.1299 7.08984 10.4199 7.7998 10.4199 8.6698C10.4199 9.5398 11.1299 10.2498 11.9999 10.2498C12.8699 10.2498 13.5799 9.5398 13.5799 8.6698C13.5799 7.7998 12.8699 7.08984 11.9999 7.08984Z" fill="currentColor"/>
    <path d="M16 17.4103C15.59 17.4103 15.25 17.0703 15.25 16.6603C15.25 15.2803 13.79 14.1504 12 14.1504C10.21 14.1504 8.75 15.2803 8.75 16.6603C8.75 17.0703 8.41 17.4103 8 17.4103C7.59 17.4103 7.25 17.0703 7.25 16.6603C7.25 14.4503 9.38 12.6504 12 12.6504C14.62 12.6504 16.75 14.4503 16.75 16.6603C16.75 17.0703 16.41 17.4103 16 17.4103Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "KelasLMSIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
