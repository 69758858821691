<template>
    <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2399 33.405C16.0844 33.3993 13.9539 32.9426 11.9854 32.0643C10.0169 31.1859 8.25393 29.9054 6.80992 28.305C6.61444 28.0807 6.51443 27.7888 6.53125 27.4917C6.54807 27.1946 6.68037 26.9159 6.89992 26.715C7.01016 26.6164 7.13878 26.5405 7.2784 26.4916C7.41801 26.4427 7.5659 26.4219 7.71358 26.4303C7.86127 26.4386 8.00586 26.476 8.13907 26.5403C8.27228 26.6046 8.39151 26.6946 8.48992 26.805C9.96782 28.4426 11.8342 29.6818 13.9171 30.4084C15.9999 31.1349 18.2321 31.3254 20.4079 30.9622C22.5837 30.5991 24.6331 29.6941 26.3671 28.3305C28.1011 26.967 29.4639 25.1888 30.3299 23.16C31.6799 19.9648 31.7086 16.3648 30.4097 13.1485C29.1108 9.93213 26.5902 7.36165 23.3999 6C20.4855 4.75896 17.2211 4.61124 14.2064 5.58397C11.1918 6.5567 8.62936 8.58456 6.98992 11.295C6.92186 11.4358 6.82519 11.5609 6.70609 11.6622C6.58698 11.7635 6.44806 11.8389 6.29818 11.8836C6.14831 11.9282 5.99077 11.9411 5.83562 11.9215C5.68048 11.9018 5.53114 11.85 5.39714 11.7694C5.26313 11.6888 5.1474 11.5811 5.05734 11.4533C4.96728 11.3254 4.90486 11.1802 4.87407 11.0269C4.84328 10.8736 4.8448 10.7155 4.87852 10.5628C4.91224 10.4101 4.97743 10.2661 5.06992 10.14C6.98126 6.95682 9.97796 4.57105 13.5085 3.42176C17.0391 2.27246 20.866 2.43697 24.2849 3.885C27.5433 5.27304 30.2225 7.74341 31.8698 10.8786C33.5172 14.0139 34.0316 17.6217 33.3262 21.0924C32.6207 24.5631 30.7387 27.6839 27.9982 29.9272C25.2576 32.1706 21.8266 33.3991 18.2849 33.405H18.2399Z"
        fill="currentColor"
      />
      <path d="M18 9.03003C18.8284 9.03003 19.5 8.35846 19.5 7.53003C19.5 6.7016 18.8284 6.03003 18 6.03003C17.1716 6.03003 16.5 6.7016 16.5 7.53003C16.5 8.35846 17.1716 9.03003 18 9.03003Z" fill="currentColor" />
      <path d="M18.0752 29.985C18.9036 29.985 19.5752 29.3134 19.5752 28.485C19.5752 27.6566 18.9036 26.985 18.0752 26.985C17.2468 26.985 16.5752 27.6566 16.5752 28.485C16.5752 29.3134 17.2468 29.985 18.0752 29.985Z" fill="currentColor" />
      <path d="M28.5151 19.47C29.3436 19.47 30.0151 18.7984 30.0151 17.97C30.0151 17.1415 29.3436 16.47 28.5151 16.47C27.6867 16.47 27.0151 17.1415 27.0151 17.97C27.0151 18.7984 27.6867 19.47 28.5151 19.47Z" fill="currentColor" />
      <path d="M7.56006 19.545C8.38849 19.545 9.06006 18.8735 9.06006 18.045C9.06006 17.2166 8.38849 16.545 7.56006 16.545C6.73163 16.545 6.06006 17.2166 6.06006 18.045C6.06006 18.8735 6.73163 19.545 7.56006 19.545Z" fill="currentColor" />
      <path
        d="M21.0002 22.17C20.7093 22.1698 20.4297 22.0569 20.2202 21.855L17.1002 18.855C16.9929 18.7416 16.9107 18.6068 16.859 18.4595C16.8073 18.3122 16.7873 18.1556 16.8002 18V11.475C16.8002 11.1766 16.9187 10.8905 17.1297 10.6795C17.3407 10.4685 17.6268 10.35 17.9252 10.35C18.2236 10.35 18.5097 10.4685 18.7207 10.6795C18.9317 10.8905 19.0502 11.1766 19.0502 11.475V17.475L21.8252 20.175C22.0359 20.3859 22.1542 20.6718 22.1542 20.97C22.1542 21.2681 22.0359 21.554 21.8252 21.765C21.7233 21.886 21.5974 21.9846 21.4553 22.0543C21.3133 22.124 21.1583 22.1634 21.0002 22.17Z"
        fill="currentColor"
      />
      <path
        d="M8.38482 28.38L6.38982 29.685C6.28588 29.7542 6.16543 29.7946 6.04078 29.8019C5.91613 29.8093 5.79175 29.7835 5.68038 29.727C5.569 29.6706 5.47461 29.5855 5.40687 29.4806C5.33912 29.3757 5.30046 29.2547 5.29482 29.13L5.17482 26.775L5.05482 24.39C5.04967 24.2674 5.07661 24.1456 5.13297 24.0366C5.18934 23.9276 5.27318 23.8352 5.37621 23.7685C5.47923 23.7018 5.59787 23.6632 5.7204 23.6565C5.84293 23.6497 5.96509 23.6751 6.07482 23.73L8.20482 24.78L10.3198 25.845C10.4256 25.9073 10.5132 25.9961 10.5741 26.1026C10.6351 26.2092 10.6671 26.3298 10.6671 26.4525C10.6671 26.5752 10.6351 26.6958 10.5741 26.8024C10.5132 26.9089 10.4256 26.9977 10.3198 27.06L8.38482 28.38Z"
        fill="currentColor"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'ClockIcon',
    props: {
      width: {
        type: Number,
        default: 25,
      },
      height: {
        type: Number,
        default: 25,
      },
    },
  };
  </script>