<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="top-container-head">
      <div class="second">
        <div>
          <h1>Reconciliation</h1>
        </div>
        <div>
          <span class="">Kategori</span>
          <v-select placeholder="Kategori" v-model="category" :items="list_category" :item-text="'name'"
            :item-value="'value'" class="form-input" outlined dense @change="categorySelected"></v-select>
        </div>
      </div>
      <div>
        <input type="file" style="display: none;" accept=".xls, .xlsx" @change="selecitFile" ref="select_file">
        <Button name="Import rekonsiliasi" outline @click="$refs.select_file.click()" width="180px" />
      </div>
    </div>
    <v-tabs v-model="tab" color="#162CA2" align-tabs="start">
      <v-tab v-for="item in titleTabs" :key="item" :value="item" @click="chooseTab(item)">
        {{ item }}
      </v-tab>
    </v-tabs>
    <div v-if="tabName == 'Completion'">
      <v-data-table :headers="headers" :items="reportingCompletion" class="elevation-1 mt-10 table" hide-default-footer>
        <template v-slot:item.actions="{ item }">
          <button @click="watchItem(item, 'c')" class="me-2">
            <WatchTable :height="28" :width="28" />
          </button>
     
        </template>
      </v-data-table>

      <!-- Display when no data is available -->
      <NoDataAlert :totalData="completionTotalData" />

      <v-row justify="center" v-if="completionTotal >= 1">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination v-if="completionTotalData != 0" v-model="completionCurrentPage" class="my-4"
              :length="completionTotal" @input="getReportingCompletion"></v-pagination>
          </v-container>
        </v-col>
      </v-row>
      <p :class="[completionTotal >= 1 ? 'count-data' : 'no-pagination']" v-if="completionTotalData"
        style="color: #515151">
        Menampilkan
        {{
      Math.floor(
        (completionCurrentPage - 1) * 10 + reportingCompletion.length
      )
    }}
        dari
        {{ completionTotalData }}
        data
      </p>
    </div>
    <div v-else>
      <v-data-table :headers="headers" :items="reportingredemption" class="elevation-1 mt-10 table" hide-default-footer>
        <template v-slot:item.actions="{ item }">
          <button @click="watchItem(item, 'r')" class="me-2">
            <WatchTable :height="28" :width="28" />
          </button>

        </template>
      </v-data-table>

      {{ reportingredemption }}

      <!-- Display when no data is available -->
      <NoDataAlert :totalData="redemptionTotalData" />

      <v-row justify="center" v-if="redemptionTotal >= 1">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination v-if="redemptionTotalData != 0" v-model="redemptionCurrentPage" class="my-4"
              :length="redemptionTotal" @input="getReportingRedemption"></v-pagination>
          </v-container>
        </v-col>
      </v-row>
      <p :class="[redemptionTotal >= 1 ? 'count-data' : 'no-pagination']" v-if="redemptionTotalData"
        style="color: #515151">
        Menampilkan
        {{
      Math.floor(
        (redemptionCurrentPage - 1) * 10 + reportingredemption.length
      )
    }}
        dari
        {{ redemptionTotalData }}
        data
      </p>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Button from '@/components/Button.vue';
import WatchTable from "@/components/icons/WatchTable.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      completionCurrentPage: 1,
      completionTotal: 0,
      completionTotalData: 0,
      isLoading: false,
      message: "",
      isPopup: false,
      titleTabs: ["Completion", "Redemption"],
      tabName: "Completion",
      headers: [
        { text: "NO", value: "no" },
        { text: "EXISTING", value: "existing" },
        { text: "DP", value: "dp" },
        { text: "ACTION", value: "actions", sortable: false },
      ],
      reportingCompletion: [],
      reportingRedemption: [],
      category: "",
      list_category: [
        {
          name: "",
          value: ""
        }
      ],
      redemptionCurrentPage: 1,
      redemptionTotal: 0,
      redemptionTotalData: 0,
    };
  },
  methods: {
    chooseTab(item) {
      this.tabName = item;
    },
    capitalize(str) {
      return str.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    async getReportingCompletion() {
      await this.$crm_http
        .get(
          `v1/reconciliation-completion-report?category=${this.category
          }&page=${this.completionCurrentPage
          }&per_page=10`
        )
        .then((response) => {
          this.reportingCompletion = [];
          response.data.data.reconciliation_completion_reports.forEach(
            (element, i) => {
              this.reportingCompletion.push({
                no: i + 1,
                existing: element.voucher_code_exist,
                dp: element.voucher_code_dp,
              });
            }
          );
          const t = parseInt(response.data.data.total_data);
          this.completionTotal = Math.ceil(t / 10);
          this.completionTotalData = response.data.data.total;
        }).catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },

    completionApplyFilters() {
      this.getReportingCompletion();
      this.completionShowFilterModal = false;
    },
    completionToggleFilterModal() {
      this.completionShowFilterModal = !this.completionShowFilterModal;
    },
    completionUpdateFilters(value) {
      this.completionFilter[value.name] = value.value;
    },
    watchItem(item, type) {
      if (type === "c") {
        this.$router.push(`/reconciliation/completion/${item.existing}?watch=true`);
      } else {
        this.$router.push(`/reconciliation/redemption/${item.existing}?watch=true`);
      }
    },
    async getReportingRedemption() {
      await this.$crm_http
        .get(
          `v1/reconciliation-redemption-report?category=${this.category
          }&page=${this.redemptionCurrentPage
          }&per_page=10`
        )
        .then((response) => {
          this.reportingredemption = [];
          response.data.data.reconciliation_redemption_reports.forEach(
            (element, i) => {
              this.reportingredemption.push({
                no: i + 1,
                existing: element.voucher_code_exist,
                dp: element.voucher_code_dp,
              });
            }
          );
          const t = parseInt(response.data.data.total);
          this.redemptionTotal = Math.ceil(t / 10);
          this.redemptionTotalData = response.data.data.total;
        }).catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    categorySelected() {
      this.getReportingCompletion()
      this.getReportingRedemption()
    },
    async selecitFile(e) {
      this.isLoading = true
      try {
        const formData = new FormData();
        formData.append("file", e.target.files[e.target.files.length-1])
        await this.$crm_http.post(
          "/v1/reconciliation-completion-report/import",
          formData,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        await this.$crm_http.post(
          "/v1/reconciliation-redemption-report/import",
          formData,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        this.isLoading = false
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false
      } finally{
        this.getReportingCompletion();
        this.getReportingRedemption();
      }
    },
    async getCategoryredemption() {
      await this.$crm_http
        .get(`/v1/redemption-report/existing-dp`)
        .then((response) => {
          for (const key in response.data.data) {
            this.list_category.push({
              name: this.capitalize(response.data.data[key]),
              value: response.data.data[key],
            });
          }
          this.getReportingRedemption();
        });
    },
    redemptionApplyFilters() {
      this.getReportingRedemption();
      this.redemptionShowFilterModal = false;
    },
    redemptionToggleFilterModal() {
      this.redemptionShowFilterModal = !this.redemptionShowFilterModal;
    },
    redemptionUpdateFilters(value) {
      this.redemptionFilter[value.name] = value.value;
    },
  },
  components: {
    Button,
    Loading,
    NoDataAlert,
    WatchTable,
    Popup
  },
  mounted() {
    this.getReportingCompletion();
    this.getCategoryredemption();
  },
};
</script>

<style scoped>
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}

.top-container-head {
  display: flex;
  justify-content: space-between;
}

.top-container-head .second {
  display: flex;
  gap: 20px;
  align-items: center;
}
</style>
