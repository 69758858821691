<template>
  <div class="ma-10">
    <!-- modal showing detail -->
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="40%"
    >
      <p class="modal-title">Detail Data User</p>

      <div class="modal-body">
        <div class="data-list grid-container">
          <div class="detail-data-box">
            <p class="detail-label">Title</p>
            <p class="detail-value">{{userDetail.gender}}</p>
          </div>
          <div class="detail-data-box">
            <p class="detail-label">Nama Lengkap</p>
            <p class="detail-value">{{userDetail.full_name}}</p>
          </div>
          <div class="detail-data-box">
            <p class="detail-label">No. Handphone</p>
            <p class="detail-value">{{userDetail.phone_number}}</p>
          </div>
          <div class="detail-data-box">
            <p class="detail-label">Email</p>
            <p class="detail-value">{{userDetail.email}}</p>
          </div>
          <div class="detail-data-box">
            <p class="detail-label">Tanggal Lahir</p>
            <p class="detail-value">{{userDetail.birthdate}}</p>
          </div>
          <div class="detail-data-box">
            <p class="detail-label">Roles</p>
            <p class="detail-value">{{userDetail.role}}</p>
          </div>
          <div class="detail-data-box full-width">
            <p class="detail-label">Alasan</p>
            <p class="detail-value"> </p>
          </div>
        </div>
      </div>
    </Modal>

    <!-- user on active -->
    <Loading v-if="isLoading" />
    <div class="container-box" style="margin-bottom: 20px">
      <h1>User Tidak Aktif</h1>
    </div>
    <!-- Filter -->
    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1 mt-10 pa-5"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-5">
          <v-col class="d-flex align-center">
            <div class="ctn">
              <div class="f-search">
                <!-- Search Field -->
                <v-text-field
                  v-model="filter.search"
                  placeholder="Cari akun..."
                  clearable
                  dense
                  flat
                  solo
                  prepend-inner-icon="mdi-magnify"
                  style="
                    border-radius: 14px;
                    max-width: 448px;
                    margin-right: 20px;
                  "
                ></v-text-field>
              </div>
              <div class="btn-filter-horizontal">
                <Button
                  name="TERAPKAN"
                  @click="applyFilters"
                />
              </div>
              <div  v-if="isFilterActive" class="btn-filter-horizontal">
                <Button class="reset-filter" @click="clearFilter()" name="RESET"
                />
              </div>
            </div>
          </v-col>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="actions" style="display:flex;">
          <button small @click="editItem(item)" class="me-2 small-button">EDIT</button>
          <button
            small
            @click="watchItem(item)"
            class="me-2 small-button"
            style="color: blue"
          >
            LIHAT
          </button>
        </div>
      </template>
    </v-data-table>
    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getUserNonActive"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Loading from '@/components/ilustration/Loading.vue';
import moment from 'moment';
import NoDataAlert from '@/components/NoDataAlert.vue';
import Modal from '@/components/Modal.vue';

export default {
  data() {
    return {
      watchIndex: 0,
      isModal: false,
      isModalDetail: false,
      // isModalReActive: false,
      isFilterActive: false,
      currentPage: 1,
      total: 0,
      search: '',
      isLoading: false,
      id: 0,
      // totalData: 0,
      filter: {
        search: '',
      },
      headers: [
        {
          text: 'NO',
          value: 'no',
          width: '55',
        },
        { text: 'TITLE', value: 'gender', width: '20' },
        { text: 'NAMA', value: 'full_name', width: '100' },
        { text: 'NO.TELPON', value: 'phone_number', width: '80' },
        { text: 'EMAIL', value: 'email', width: '80' },
        { text: 'TGL LAHIR', value: 'birthdate', width: '100' },
        { text: 'ROLES', value: 'role', width: '80' },
        { text: 'ACTION', value: 'actions', sortable: false, width: '100' },
      ],
      users: [],
      // UserNonActive: [],
      userDetail: {},
      // UserNonActiveLog: {},
      // showFilterModal: false,
    };
  },

  components: {
    Button,
    Loading,
    NoDataAlert,
    Modal,
  },
  methods: {
    async getUserNonActive() {
      await this.$web_http
        .get(`v1/user/${this.$route.params.id}`)
        .then((response) => {
          const data = response.data.data.user

            this.formData = {
              ...data,
              birthdate: moment(data.birthdate).format("YYYY-MM-DD"),
              role: data.user_type.id
            }
        })
        .catch((err) => console.error(err));
    },

    watchItem(item) {
      this.watchIndex = this.users.indexOf(item);
      console.log(this.users.indexOf(item));
      this.userDetail = Object.assign({}, item);
      this.isModalDetail = true;
    },

    editItem(item) {
      this.$router.push('/user-non-active/form/' + item.id);
    },

    applyFilters() {
      this.currentPage = 1;
      this.getUserNonActive();
      this.isFilterActive = true;
    },
    // checkFilter() {
    //   if (this.filter.search) {
    //     this.isFilterActive = true;
    //   }
    // },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        search: "",
      };
      this.getUserNonActive();
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD');
      }
    },
  },
  mounted() {
    this.getUserNonActive();
    // this.applyFilters()
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.detail-data-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-left: 10px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border-radius: 8px; /* Membuat sudut membulat */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Menambahkan sedikit bayangan */
}
.detail-label {
  font-weight: normal; /* Mengubah dari bold menjadi normal */
  color: gray;
  margin-bottom: 5px;
}
.detail-value {
  color: black;
  margin-bottom: 10px;
}
.full-width {
  grid-column: span 2;
}

/*1*/
.ctn {
  display: flex;
  align-items: center;
}

.f-search {
  margin-right: 20px;
}

.f-date {
  margin-right: 26px;
}

.btn-filter-horizontal {
  margin-right: 0px;
}

.reset-filter {
  cursor: pointer;
  color: #ACAFBF!important;
  background-color: white;
  border: 1px solid #ACAFBF;
}
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 30px;
  margin-left: 5px;
}
.filter-ctn {
  display: flex;
  flex-wrap: wrap;
}

.filter-input {
  margin-right: 20px;
}

.filter-btn {
  margin-top: 10px;
  margin-right: 20px;
}
.filter-label {
  color: #36373f;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.reset-filter {
  margin-left: 30px;
}

.adjust-left {
  margin-right: -8px;
}
.small-text {
  font-size: 12px;
}

.extra-small-text {
  font-size: 10px;
}
.elevation-1 {
  margin-top: -10px !important;
}
.custom-table .v-data-table__wrapper tbody td {
  font-size: 9px !important;
}
</style>
