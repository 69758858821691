<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="back-icon" @click="$router.push('/blog')">
      <round-arrow-back />
    </div>
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Date</p>
        <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="pt-2"> Mulai </v-col>
                <v-col cols="8">
                  <v-text-field
                    outlined
                    type="date"
                    
                    v-model="form.start_date"
                    class="form-input"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5 ml-5">
              <v-row no-gutters>
                <v-col cols="3" class="pt-2"> Selesai </v-col>
                <v-col cols="9">
                  <v-text-field
                    outlined
                    type="date"
                    v-model="form.end_date"
                    class="form-input"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
      </div>
      <div class="submit-box">
        <Button name="PUBLISH" width="260px" @click="onSumbmit" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import moment from 'moment';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormUserSchedule",
  data() {
    return {
      name: "",
      isLoading: false,
      message: "",
        isPopup: false,
      form: {
        dates: "",
        start_date: "",
        end_date: "",
        class_id: 0,
        user_id: 0,
      },
    };
  },
  components: {
    Button,
    Loading,
    Popup
  },

  methods: {
    checkingType() {
        this.name = this.$route.meta.name
        this.form.class_id = Number(this.$route.query.class_id);
        this.form.user_id = Number(this.$route.query.user_id);
        this.getDataSchedule();
    },
    async getDataSchedule() {
      this.isLoading = true;
      try {
        const resp = await this.$lms_http.get(`v1/user-schedule/user/${this.form.user_id}/class/${this.form.class_id}`);
        this.form.dates = resp.data.data.schedule.dates
        const date =  this.form.dates.split(" - ");
        this.form.start_date = moment(new Date(date[0])).format("YYYY-MM-DD")
        this.form.end_date = moment(new Date(date[1])).format("YYYY-MM-DD")
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
          await this.$lms_http.put("/v1/user-schedule/date", {
            ...this.form,
            date: moment(new Date(this.form.start_date)).format("DD MMM YYYY") + " - " + moment(new Date(this.form.end_date)).format("DD MMM YYYY")
          });
        setTimeout(() => {
          this.$router.push("/user-schedule");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding-bottom: 30px;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.session-nb-3 {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
