<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.125 5.75C20.4702 5.75 20.75 6.02982 20.75 6.375V17.625C20.75 17.9702 20.4702 18.25 20.125 18.25H3.875C3.52982 18.25 3.25 17.9702 3.25 17.625V6.375C3.25 6.02982 3.52982 5.75 3.875 5.75H20.125ZM3.875 4.5C2.83947 4.5 2 5.33947 2 6.375V17.625C2 18.6605 2.83947 19.5 3.875 19.5H20.125C21.1605 19.5 22 18.6605 22 17.625V6.375C22 5.33947 21.1605 4.5 20.125 4.5H3.875Z"
      fill="currentColor"
    />
    <path d="M10.75 8.875C10.75 8.52982 11.0298 8.25 11.375 8.25H17.625C17.9702 8.25 18.25 8.52982 18.25 8.875C18.25 9.22018 17.9702 9.5 17.625 9.5H11.375C11.0298 9.5 10.75 9.22018 10.75 8.875Z" 
    fill="currentColor"/>
    <path
      d="M8.87944 7.80806C9.12352 8.05214 9.12352 8.44786 8.87944 8.69194L7.00444 10.5669C6.76037 10.811 6.36464 10.811 6.12056 10.5669L5.49556 9.94194C5.25148 9.69786 5.25148 9.30214 5.49556 9.05806C5.73964 8.81398 6.13537 8.81398 6.37944 9.05806L6.5625 9.24112L7.99556 7.80806C8.23964 7.56398 8.63537 7.56398 8.87944 7.80806Z"
      fill="currentColor"
    />
    <path d="M10.75 13.875C10.75 13.5298 11.0298 13.25 11.375 13.25H17.625C17.9702 13.25 18.25 13.5298 18.25 13.875C18.25 14.2202 17.9702 14.5 17.625 14.5H11.375C11.0298 14.5 10.75 14.2202 10.75 13.875Z" 
    fill="currentColor" />
    <path
      d="M8.87944 12.8081C9.12352 13.0521 9.12352 13.4479 8.87944 13.6919L7.00444 15.5669C6.76037 15.811 6.36464 15.811 6.12056 15.5669L5.49556 14.9419C5.25148 14.6979 5.25148 14.3021 5.49556 14.0581C5.73964 13.814 6.13537 13.814 6.37944 14.0581L6.5625 14.2411L7.99556 12.8081C8.23964 12.564 8.63537 12.564 8.87944 12.8081Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'UserScheduleIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
