<template>
  <div class="modal" @click.self="$emit('close')">
    <!-- Cancel Button -->
    <div class="modal-cancel" @click="$emit('close')">
      <Close :width="22" :height="22" />
    </div>
    <!-- Content -->
    <div class="modal-content" 
         :style="`width: ${width ? width : 'auto'};
                  height: ${height ? height : 'auto'}`">
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .modal {
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(12, 11, 11, 0.2);
    width: 100vw;
    height: 100vh;
  }
  .modal-cancel {
    background-color: #FFFFFF;
    position: fixed;
    top: 23%;
    right: 30%;
    display: flex;
    justify-content: center;
    padding: 6px;
    border-radius: 20px;
    cursor: pointer;
    color: #7B7E8C;
  }
  .modal-content {
    background-color: white;
    border-radius: 20px;
    padding: 25px;
    text-align: center;
  }
</style>

<script>
  import Close from "@/components/icons/Close.vue"
  export default {
    name: "NewModal",
    components: {
      Close,
    },
    props: {
      width: {
        type: String,
        default: ""
      },
      height: {
        type: String,
        default: ""
      }
    }
  }
</script>