<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
      <div class="cart-form">
        <h1>{{ name }}</h1>
        <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs">
        </v-breadcrumbs>
  
        <!-- Form Watch -->
        <div v-if="isWatch">
          <div class="content-form">
            <p>Title</p>
            <v-text-field
              outlined
              disabled
              v-model="form.title"
              class="form-input"
              placeholder="Title Project Portfolio"
              dense
              clearable
            ></v-text-field>
          </div>
  
          <div class="content-form">
            <p>Label</p>
            <v-text-field
              outlined
              disabled
              v-model="form.label"
              class="form-input"
              placeholder="Label Project Portfolio"
              dense
              clearable
            ></v-text-field>
          </div>
  
          <div class="content-form">
            <p>Program</p>
            <v-text-field
              outlined
              disabled
              v-model="form.program"
              class="form-input"
              placeholder="Program Project Portfolio"
              dense
              clearable
            ></v-text-field>
          </div>
  
          <div class="content-form">
            <p>Description Singkat</p>
            <v-textarea
              placeholder="Description Singkat ..."
              disabled
              outlined
              rows="3"
              v-model="form.description"
              clearable
              class="form-input"
            ></v-textarea>
          </div>
  
          <div class="content-form">
            <p>Category</p>
            <v-select
              disabled
              class="form-select"
              :items="categories"
              :item-text="'name'"
              :item-value="'value'"
              v-model="form.category"
              outlined
            >
            </v-select>
          </div>
          <div class="content-form">
            <p>Image</p>
            <ImageModal
              :src="form.image_url"
              width="120"
              height="100"
              radius="3"
            />
            <span>{{ img_web_name }}</span>
          </div>
        </div>
  
        <!-- Create Form -->
        <div v-if="!isWatch">
          <div class="content-form">
            <p>Title</p>
            <v-text-field
              outlined
              v-model="form.title"
              class="form-input"
              placeholder="Title Project Portfolio"
              dense
              clearable
            ></v-text-field>
          </div>
  
          <div class="content-form">
            <p>Label</p>
            <v-text-field
              outlined
              v-model="form.label"
              class="form-input"
              placeholder="Label Project Portfolio"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Deskripsi Singkat</p>
            <v-textarea placeholder="Caption" :disabled="isWatch" outlined rows="4" v-model="form.description"
            clearable class="form-input"></v-textarea>
          </div>
          <div class="content-form">
            <p>Program</p>
            <v-text-field
              outlined
              v-model="form.program"
              class="form-input"
              placeholder="Program Project Portfolio"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Category</p>
            <v-select
              class="form-select"
              :items="categories"
              :item-text="'name'"
              :item-value="'value'"
              v-model="form.category"
              outlined
            >
            </v-select>
          </div>
          <div class="content-form">
            <p>Image</p>
            <v-file-input
              v-model="img_portfolio"
              accept="image/*"
              placeholder="Unggah Berkas Gambar"
              class="form-upload"
              prepend-icon=""
              outlined
            >
              <template
                v-slot:prepend-inner
                v-if="isEdit && img_portfolio == null"
              >
                <ImageModal
                  :src="form.image_url"
                  width="76.31"
                  height="37"
                  radius="3"
                />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
            <p class="note-file">
              *Rekomendasi dimensi 327 x 462 px. ukuran file maks 1 MB
            </p>
          </div>
          <div class="content-form">
            <p>Penjelasan projek</p>
            <vue-editor
              v-model="form.body"
              :editorToolbar="$helpers.customToolBar()"
              class="form-input"
              v-if="!isWatch"
            ></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="form.body"></div>
            </div>
          </div>
          <div class="content-form mt-5">
            <p>Active Program</p>
            <div class="parent-list">
              <div>
                <h4>IT Consulting</h4>
                <div class="spacing-switch">
                  <v-switch v-model="form.scope_project[0].val" color="primary" hide-details style="margin-top: -5px"></v-switch>
                  <div>
                    <Chip :active="form.scope_project[0].val" />
                  </div>
                </div>
              </div>
              <div>
                <h4>IT Sourcing</h4>
                <div class="spacing-switch">
                  <v-switch v-model="form.scope_project[1].val" color="primary" hide-details style="margin-top: -5px"></v-switch>
                  <div>
                    <Chip :active="form.scope_project[1].val" />
                  </div>
                </div>
              </div>
              <div>
                <h4>IT Implementation</h4>
                <div class="spacing-switch">
                  <v-switch v-model="form.scope_project[2].val" color="primary" hide-details style="margin-top: -5px"></v-switch>
                  <div>
                    <Chip :active="form.scope_project[2].val" />
                  </div>
                </div>
              </div>
              <div>
                <h4>IT Support</h4>
                <div class="spacing-switch">
                  <v-switch v-model="form.scope_project[3].val" color="primary" hide-details style="margin-top: -5px"></v-switch>
                  <div>
                    <Chip :active="form.scope_project[3].val" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-form mt-5">
            <p>Key Feature</p>
            <div class="list-event mt-2" v-for="(item, idx) in form.key_feature" :key="idx">
              <div class="icon-plus-bc" @click="form.key_feature.splice(idx, 1)">
                <trash-icon :width="25" :height="25" />
              </div>
              <b>Ke {{ idx + 1 }}</b>
              <v-text-field :disabled="isWatch" outlined label="Link gambar" v-model="form.key_feature[idx].img"
                class="form-input no-space" dense></v-text-field>
                <v-text-field :disabled="isWatch" outlined label="Title" v-model="form.key_feature[idx].title"
                class="form-input no-space" dense></v-text-field>
              <vue-editor v-model="form.key_feature[idx].description" placeholder="Deskripsi" :editorToolbar="$helpers.customToolBar()"
                class="form-input" v-if="!isWatch"></vue-editor>
            </div>
            <div class="add-button" @click="addKeyFeature" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Key Feature</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button
            name="Batalkan"
            outline
            class="me-3"
            @click="$router.push('/project-portfolio')"
          />
          <Button
            :name="isEdit ? 'Simpan' : 'Simpan & Publish'"
            :width="isEdit ? '156px' : '200px'"
            @click="onSubmit"
            :disabled="!validData"
            style="background-color: #3b54d9; color: #ffffff"
          />
        </div>
      </div>
    </div>
  </template>
  
    <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import Button from "../../../components/Button.vue";
  import ImageModal from "../../../components/ImageModal.vue";
  import Popup from "@/components/others/Popup.vue";
  import {VueEditor} from "vue2-editor";
  import TrashIcon from "@/components/icons/Trash.vue";
  import Chip from "@/components/Chip.vue";

  export default {
    name: "FormProjectPortfolio",
    computed: {
      validData() {
        return (
          this.form.title &&
          this.form.label &&
          this.form.program &&
          this.form.description &&
          this.form.category
        );
      },
    },
    data() {
      return {
        id: null,
        modal: false,
        startPicker: false,
        endPicker: false,
        name: "",
        isLoading: false,
        isWatch: false,
        isEdit: false,
        tags: [],
        message: "",
        isPopup: false,
        categories: [
          { name: "Collaboration", value: "COLLABORATION" },
          { name: "Training", value: "TRAINING" },
          { name: "Talent Service", value: "TALENT_SERVICE" },
          { name: "Services", value: "SERVICES" },
        ],
        form: {
          title: "",
          label: "",
          program: "",
          description: "",
          category: "",
          image_url: "",
          key_feature: [],
          scope_project: [
            {val: false},
            {val: false},
            {val: false},
            {val: false},
          ],
          preview: "",
          link_web: "",
          body: ""
        },
        img_portfolio: null,
        img_web_name: "",
        breadcrumbs: [
          { text: "Master Data", disabled: false, href: "/project-portfolio" },
          {
            text: "Project Portfolio",
            disabled: false,
            href: "/project-portfolio",
          },
        ],
      };
    },
    components: {
      Button,
      Loading,
      ImageModal,
      TrashIcon,
      Chip,
      Popup,
      VueEditor
    },
    methods: {
      checkingType() {
        this.name = this.$route.meta.name;
        this.breadcrumbs.push({ text: this.$route.meta.name, disabled: false });
        this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
        this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getDataProjectPortfolio();
      },
      addKeyFeature() {
        this.form.key_feature.push({
          img: "",
          title: "",
          description: ""
        })
      },
      async getDataProjectPortfolio() {
        this.isLoading = true;
        try {
          const resp = await this.$crm_http.get(
            "/v1/project-portfolio/" + this.id
          );
          this.form = resp.data.data.project_portfolio;
          this.img_web_name = this.form.image_url
            .split("/")
            .pop()
            .replace("?view=open", "")
            .replaceAll("%20", " ");

          if (!this.form.scope_project.length) {
            this.form.scope_project = [
              {val: false},
              {val: false},
              {val: false},
              {val: false},
            ]
          }
          this.isLoading = false;
          } catch (err) {
            this.message = err.response.data.data
            this.isPopup = true
            this.isLoading = false;
          }
      },
      async onSubmit() {
        this.isLoading = true;
        try {
          if (this.isEdit) {
            if (this.img_portfolio) {
              const imageForm = new FormData();
              imageForm.append("attachment", this.img_portfolio);
              const webRes = await this.$crm_http.post(
                "/v1/upload-file",
                imageForm
              );
              this.form.image_url =
                webRes.data.data.upload_image_response.url_w_query;
              imageForm.delete("attachment");
            }
  
            await this.$crm_http.put(
              "/v1/project-portfolio/" + this.form.id,
              this.form
            );
          } else {
            const imageForm = new FormData();
            imageForm.append("attachment", this.img_portfolio);
            const webRes = await this.$crm_http.post(
              "/v1/upload-file",
              imageForm
            );
            this.form.image_url =
              webRes.data.data.upload_image_response.url_w_query;
  
            console.log(this.form);
            await this.$crm_http.post("/v1/project-portfolio", this.form);
            imageForm.delete("attachment");
          }
          setTimeout(() => {
            this.$router.push("/project-portfolio");
          }, 2000);
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          this.isLoading = false;
        }
      },
      copyLink() {
        const input = document.createElement("input");
        input.style.position = "fixed";
        input.style.opacity = 0;
        input.value = this.form.link_url;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        alert("Link copied to clipboard");
      },
    },
    mounted() {
      this.checkingType();
    },
  };
  </script>
    
    <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f9fd;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  .cart-form {
    position: relative;
    width: 50%;
    border-radius: 30px;
    padding: 30px 40px;
    border: 1px solid #d6d6d6;
    background-color: #ffffff;
  }
  .sub-form {
    margin-top: 20px;
    background-color: #f5f6fa;
    padding: 26px 32px;
    border-radius: 16px;
  }
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  .form-input {
    border-radius: 10px !important;
  }
  
  ::v-deep .form-input .v-input__slot {
    margin-bottom: 20px;
    background-color: #ffffff !important;
  }
  
  ::v-deep .form-input .v-text-field__details {
    display: none;
  }
  
  ::v-deep .form-upload .v-input__slot {
    height: 62px !important;
    background-color: #ffffff !important;
  }
  
  ::v-deep .form-upload .v-text-field__details {
    display: none;
  }
  
  ::v-deep .form-select .v-input__slot {
    height: 56 !important;
    margin-bottom: 20px;
    background-color: #ffffff;
  }
  
  ::v-deep .form-select .v-text-field__details {
    display: none;
  }
  
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    font-size: 14px;
    margin: 3px 0;
    font-weight: 400;
  }
  
  .set-time {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .banner-berkas {
    font-size: 18px;
    margin: 20px 0;
    font-weight: bold;
  }
  .watch-ctn {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid gray;
  }
  .submit-box {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
  .alert-info {
    font-size: 14px;
    color: #7b7e8c;
  }
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  .note-file {
    font-weight: 400;
    font-size: 12px;
    color: #b80f0a;
    margin-bottom: 20px !important;
  }
  
  .breadcrumbs {
    padding: 0px;
    margin-left: 0px;
    margin-bottom: 35px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  
  ::v-deep .v-breadcrumbs__divider {
    color: #162ca2 !important;
  }
  
  ::v-deep .v-breadcrumbs__item {
    color: #162ca2;
  }
  
  ::v-deep div.v-breadcrumbs__item {
    color: #7b7e8c !important;
  }
  
  .small-switch {
    transform: scale(0.75);
  }
  
  .pill {
    background-color: #f9f9f9;
    border-color: #d6d6d6;
    border: 1px solid;
    border-radius: 100px;
    padding: 4px 12px 4px 12px;
    color: #7b7e8c;
    text-transform: capitalize;
  }
  
  .pill-alert {
    background-color: #f7e7e6;
    border: 0px;
    color: #b80f0a;
    text-transform: none;
  }
  
  .watch-content {
    background-color: #ffffff;
    border: 0.5px solid #d6d6d6;
    border-radius: 10px;
  }
  
  .watch-content a {
    text-decoration: none !important;
    color: #2d46cf;
    font-weight: 400;
  }

  
.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}

.icon-plus {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
}

.icon-plus-bc {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f6fa;
}

.parent-list {
  display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; margin-top: 20px;
}

.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}

.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
  </style>