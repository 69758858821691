<template>
<svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 6C18.7417 6 19.4667 6.21993 20.0834 6.63199C20.7001 7.04404 21.1807 7.62971 21.4646 8.31494C21.7484 9.00016 21.8226 9.75416 21.6779 10.4816C21.5333 11.209 21.1761 11.8772 20.6517 12.4017C20.1272 12.9261 19.459 13.2833 18.7316 13.4279C18.0042 13.5726 17.2502 13.4984 16.5649 13.2145C15.8797 12.9307 15.294 12.4501 14.882 11.8334C14.4699 11.2167 14.25 10.4917 14.25 9.75C14.25 8.75544 14.6451 7.80161 15.3484 7.09835C16.0516 6.39509 17.0054 6 18 6ZM18 3.75C16.8133 3.75 15.6533 4.10189 14.6666 4.76118C13.6799 5.42047 12.9109 6.35754 12.4567 7.4539C12.0026 8.55026 11.8838 9.75666 12.1153 10.9205C12.3468 12.0844 12.9182 13.1535 13.7574 13.9926C14.5965 14.8318 15.6656 15.4032 16.8295 15.6347C17.9933 15.8662 19.1997 15.7474 20.2961 15.2933C21.3925 14.8392 22.3295 14.0701 22.9888 13.0834C23.6481 12.0967 24 10.9367 24 9.75C24 8.1587 23.3679 6.63258 22.2426 5.50736C21.1174 4.38214 19.5913 3.75 18 3.75Z" fill="#7B7E8C"/>
<path d="M32.25 32.625C31.9528 32.6211 31.6689 32.5013 31.4588 32.2912C31.2487 32.0811 31.1289 31.7972 31.125 31.5V28.5C31.121 26.4126 30.2901 24.4119 28.8141 22.9359C27.3381 21.4599 25.3374 20.629 23.25 20.625H12.75C10.6626 20.629 8.6619 21.4599 7.18591 22.9359C5.70992 24.4119 4.87896 26.4126 4.875 28.5V31.5C4.875 31.7984 4.75647 32.0845 4.5455 32.2955C4.33452 32.5065 4.04837 32.625 3.75 32.625C3.45163 32.625 3.16548 32.5065 2.9545 32.2955C2.74353 32.0845 2.625 31.7984 2.625 31.5V28.5C2.62897 25.8159 3.69698 23.2429 5.59493 21.3449C7.49287 19.447 10.0659 18.379 12.75 18.375H23.25C25.9341 18.379 28.5071 19.447 30.4051 21.3449C32.303 23.2429 33.371 25.8159 33.375 28.5V31.5C33.3711 31.7972 33.2513 32.0811 33.0412 32.2912C32.8311 32.5013 32.5472 32.6211 32.25 32.625Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
  name: "PeopleIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>