<template>
  <div>
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="container-box title-box">
      <h1>OVERVIEW</h1>
    </div>

    <div class="member-box">
      <div class="container-box total-box">
        <div class="header">
          <p>Total member</p>

          <div class="info-icon-wrapper">
            <InfoIcon class="info-icon" />
            <div class="tooltip">Total user secara keseluruhan aktif & nonaktif</div>
          </div>
        </div>
        <div class="count">
          <p>{{ member.total_user }}</p>
        </div>
        <p>(Member Aktif & Non-Aktif)</p>
      </div>

      <div class="container-box total-box">
        <div class="header">
          <p>Member baru</p>
          <div class="info-icon-wrapper">
            <InfoIcon class="info-icon" />
            <div class="tooltip">Jumlah member baru dalam 90 hari terakhir</div>
          </div>
        </div>
        <div class="count">
          <p>{{ member.new_user }}</p>
        </div>
        <p>30 hari terakhir ({{ thirtyDaysAgo }} - {{ currentDate }})</p>
      </div>
    </div>

    <!-- Popup -->
    <div id="popup" class="popup">
      <p id="popup-text"></p>
    </div>

    <div class="container-box diagram">
      <div class="up-bar">
        <div class="user-label">
          <svg style="margin-top: 3px" width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.237793" width="17.6596" height="16.5246" rx="8.26229" fill="#34B3F1" />
          </svg>
          <p>User Aktif</p>
          <svg style="margin-top: 3px" width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.306641" y="0.237793" width="17.6596" height="16.5246" rx="8.26229" fill="#41B57A" />
          </svg>
          <p>User Tidak Aktif</p>
        </div>
        <div class="filter-box">
          <v-select @change="handleFilter" label="filter" v-model="filter.category" :items="categories" :item-text="'name'" :item-value="'value'" hide-details="auto" class="select-chart" solo style="width: 200px"></v-select>
        </div>
      </div>
      <div id="chart">
        <apexchart ref="realtimeChart" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import InfoIcon from '@/components/icons/InfoIcon.vue';
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      currentDate: '',
      thirtyDaysAgo: '',
      member: {},
      dataChart: [],
      message: "",
      isPopup: false,
      filter: {
        category: 'MONTH',
      },
      categories: [
        {
          name: 'Daily',
          value: 'WEEK',
        },
        {
          name: 'Monthly',
          value: 'MONTH',
        },
        {
          name: 'Yearly',
          value: 'YEAR',
        },
      ],
      series: [
        {
          name: 'User Aktif',
          data: [],
        },
        {
          name: 'User Tidak Aktif',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          style: {
            fontSize: '12px',
          },
          // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          //   // Akses informasi dari variabel w
          //   // const seriesName = w.globals.seriesNames[seriesIndex];
          //   // this.$refs.realtimeChart.updateOptions(this.chartOptions);
          //   const pointData = series[seriesIndex][dataPointIndex];
          //   const xValue = w.config.xaxis.categories;
          //   const x = this.chartOptions.xaxis.categories[dataPointIndex];
          //   // this.updateLabels();

          //   console.log("Tooltip Debug:", {
          //     pointData,
          //     xValue,
          //     x,
          //   });

          //   return `<div class="tooltip-custom" style="width:150px;background-color: #0A1B49 !important;padding: 10px !important;border-radius: 10px;">
          //             <p style="color: white;margin:0;">X: ${this.chartOptions.xaxis.categories}</p><br>
          //             <p style="color: white;margin:0;">X: ${xValue}</p><br>
          //             <p style="color: white;margin:0;">Y: ${pointData}</p>
          //           </div>`;
          // },
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        colors: ['#2461C3', '#41B57A'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#2461C3', '#41B57A'], // Color at the end of the gradient
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (val) {
              return val.toFixed(0); // Menampilkan angka tanpa desimal
            },
          },
        },
      },
    };
  },
  components: {
    apexchart: VueApexCharts,
    InfoIcon,
    Popup
  },
  methods: {
    async getTotalMember() {
      try {
        const resp = await this.$crm_http.get(`/v1/user/total`);
        const data = resp.data.data;
        this.member = {
          ...data,
          total_user: data.total_user,
          sub_new_user: data.sub_new_user,
          new_user: data.new_user,
          percentage: data.percentage,
        };
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },

    handleFilter() {
      this.getChart();
    },

    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries(this.series, false, true);
    },

    updateLabels() {
      this.$refs.realtimeChart.updateOptions(this.chartOptions);
    },

    formatMonth(number) {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return monthNames[number - 1] || number;
    },
    formatWeek(number) {
      const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      return weekDays[number - 1] || number;
    },
    formatCategories(name, category) {
      if (category === 'MONTH') {
        return this.formatMonth(name);
      } else if (category === 'WEEK') {
        return this.formatWeek(name);
      } else if (category === 'YEAR') {
        return name;
      }
      return name;
    },
    async getChart() {
      try {
        const response = await this.$crm_http.get(`/v1/user/chart?category=${this.filter.category}`);
        const data = response.data.data;
        this.series[0].data = [];
        this.series[1].data = [];
        this.chartOptions.xaxis.categories = [];

        data.forEach((item) => {
          this.series[0].data.push(item.user_active);
          this.series[1].data.push(item.user_Nonactive);
          const formattedCategory = this.formatCategories(item.name, this.filter.category);
          // console.log(`Formatted Category: ${formattedCategory}`);
          this.chartOptions.xaxis.categories.push(formattedCategory);
          // console.log("Formatted Categories:", this.chartOptions.xaxis.categories);
          // console.log(this.chartOptions.xaxis.categories)
        });
        // console.log("ini",this.chartOptions.xaxis.categories)
        // console.log(this.$refs.realtimeChart);
        this.updateLabels();
        this.updateSeriesLine();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    getCurrentDate() {
      const today = new Date();
      return today.toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' });
    },
    getThirtyDaysAgo() {
      const today = new Date();
      const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
      return thirtyDaysAgo.toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' });
    },
    getFormattedDate(date) {
      return date.toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' });
    },
  },
  // created() {
  //   this.getChart();
  //   console.log("jalan", this.chartOptions);
  // },
  mounted() {
    this.getChart();
    // this.handleFilter();
    this.getTotalMember();
    // console.log("jalan", this.chartOptions);
    this.currentDate = this.getCurrentDate();
    this.thirtyDaysAgo = this.getThirtyDaysAgo();
  },
};
</script>

<style scoped>
/* CSS styling */
.info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.info-icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.info-icon {
  cursor: pointer;
}

.container-box.total-box .header {
  display: flex;
  align-items: center;
}

.container-box.total-box .header p {
  margin: 0;
  margin-right: 5px; /* Jarak antara teks dan ikon */
}

.container-box.total-box .header InfoIcon {
  display: inline-block;
}

.container-box.total-box .header {
  display: flex;
  align-items: center;
}

.container-box.total-box .header p {
  margin: 0;
  margin-right: 5px; /* Jarak antara teks dan ikon */
}

.container-box.total-box .header InfoIcon {
  display: inline-block;
}

.container-box {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}

.title-box {
  margin: 30px;
}

.member-box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 30px;
}

.total-box {
  width: 600px;
  height: auto;
}

.total-box p {
  margin: 0;
}

.count {
  display: flex;
  gap: 10px;
}

.count p {
  font-size: 40pt;
  font-weight: 800;
  margin: 0;
}

.green-count {
  display: flex;
  align-self: flex-end;
  background-color: #e2fcf3;
  height: fit-content;
  margin-bottom: 15px;
  padding: 3px;
  padding-inline: 10px;
  border-radius: 8px;
}

.green-count p {
  font-size: 10pt;
  color: #0ea976;
  font-weight: 100;
}

.diagram {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.filter-box {
  width: 130px;
  display: flex;
  gap: 380px;
  margin-bottom: 20px;
  align-self: flex-end;
}

.user-label {
  display: flex;
  gap: 5px;
}

.user-label p {
  color: #7b7e8c;
  margin-right: 20px;
}

.select-chart {
  align-items: flex-end;
  border-radius: 10px !important;
  border: 0.8px solid #d9d9d9 !important;
}

.up-bar {
  display: flex;
  justify-content: space-between;
  /* gap: 31rem; */
}

/* .tooltip-custom {
  background-color: #0a1b49 !important;
  padding: 30px !important;
  border-radius: 10px;
} */
</style>
