<template>
  <!-- <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.25 7.5H14.75C16.25 7.5 17 6.75 17 5.25V3.75C17 2.25 16.25 1.5 14.75 1.5H13.25C11.75 1.5 11 2.25 11 3.75V5.25C11 6.75 11.75 7.5 13.25 7.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.25 16.5H5.75C7.25 16.5 8 15.75 8 14.25V12.75C8 11.25 7.25 10.5 5.75 10.5H4.25C2.75 10.5 2 11.25 2 12.75V14.25C2 15.75 2.75 16.5 4.25 16.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 7.5C6.65685 7.5 8 6.15685 8 4.5C8 2.84315 6.65685 1.5 5 1.5C3.34315 1.5 2 2.84315 2 4.5C2 6.15685 3.34315 7.5 5 7.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 16.5C15.6569 16.5 17 15.1569 17 13.5C17 11.8431 15.6569 10.5 14 10.5C12.3431 10.5 11 11.8431 11 13.5C11 15.1569 12.3431 16.5 14 16.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.79 22.75H6.21C3.47 22.75 1.25 20.52 1.25 17.78V10.37C1.25 9.01 2.09 7.3 3.17 6.46L8.56 2.26C10.18 1 12.77 0.940005 14.45 2.12L20.63 6.45C21.82 7.28 22.75 9.06001 22.75 10.51V17.79C22.75 20.52 20.53 22.75 17.79 22.75ZM9.48 3.44L4.09 7.64C3.38 8.2 2.75 9.47001 2.75 10.37V17.78C2.75 19.69 4.3 21.25 6.21 21.25H17.79C19.7 21.25 21.25 19.7 21.25 17.79V10.51C21.25 9.55 20.56 8.22 19.77 7.68L13.59 3.35C12.45 2.55 10.57 2.59 9.48 3.44Z"
      fill="currentColor"
    />
    <path
      d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: "HomeIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
