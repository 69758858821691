<template>
    
<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.11008 5.08039C9.98008 4.82039 10.9401 4.65039 12.0001 4.65039C16.7901 4.65039 20.6701 8.53039 20.6701 13.3204C20.6701 18.1104 16.7901 21.9904 12.0001 21.9904C7.21008 21.9904 3.33008 18.1104 3.33008 13.3204C3.33008 11.5404 3.87008 9.88039 4.79008 8.50039" stroke="#575966" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.87012 5.32L10.7601 2" stroke="#575966" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.87012 5.32031L11.2401 7.78031" stroke="#575966" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>
<script>
export default {
  name: 'ReverseIcon',
  props: {
    width: {
      type: String,
      default: "25",
    },
    height: {
      type: String,
      default: "25",
    },
  },
};
</script>