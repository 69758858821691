<template>
    <div class="ma-10">
      <Loading v-if="isLoading" />
      <Popup
        :message="message"
        v-if="isPopup"
        @close="() => (isPopup = false)"
      />
      <NewModal v-if="isModal" @close="isModal = false" width="26%">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
          "
        >
          <!-- Image  -->
          <div>
            <img
              src="/img/other/delete-popup.png"
              alt="Delete Data"
              style="width: 200px"
            />
          </div>
          <!-- Detail -->
          <div
            style="
              display: flex;
              flex-direction: column;
              padding: 0px 40px;
              letter-spacing: 0.3px;
              gap: 6px;
            "
          >
            <!-- Title -->
            <div style="color: #36373f; font-size: 21px; font-weight: 700">
              Konfirmasi Hapus
            </div>
            <!-- Description -->
            <div style="color: #535353; font-size: 13px">
              Apakah kamu yakin ingin menghapus project portfolio ini?
            </div>
          </div>
          <!-- Button -->
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-top: 30px;
              gap: 16px;
            "
          >
            <!-- Cancel -->
            <Button
              name="Batalkan"
              @click="() => (isModal = false)"
              width="120px"
              height="40px"
              style="
                font-size: 14px;
                font-weight: 600;
                color: #7b7e8c;
                background-color: #ffffff;
                border: 1px solid #7b7e8c;
              "
            />
            <!-- Delete -->
            <Button
              name="Ya, Hapus"
              @click="onDelete"
              width="120px"
              height="40px"
              style="
                font-size: 14px;
                font-weight: 600;
                background-color: #b80f0a;
                color: white;
              "
            />
          </div>
        </div>
      </NewModal>
      <div class="top-container-box">
        <h1>Project Portfolio</h1>
        <div>
          <Button
            icon="+"
            outline
            name="Create Project Portfolio"
            style="background-color: #3b54d9; color: #ffffff; width: 270px"
            @click="() => $router.push('/project-portfolio/new')"
          />
        </div>
      </div>
      <v-row>
        <!-- Filter -->
        <v-col style="position: relative">
          <!-- Button -->
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 8px;
              color: #7b7e8c;
              font-weight: 500;
              justify-content: center;
              align-items: center;
              background-color: white;
              padding: 10px;
              border: 1px solid #d3d6e1;
              border-radius: 10px;
              width: 100px;
              margin-top: 7px;
              cursor: pointer;
            "
            @click="toggleFilterModal"
          >
            <FilterIcon width="24" height="24" />
            <div>Filter</div>
          </div>
          <!-- Modal -->
          <FilterModal
            :show="showFilterModal"
            @apply="applyFilters"
            :filterDatas="filterModal"
            :filters="filter"
            @updateFilter="updateFilters"
          />
        </v-col>
        <v-col style="display: flex; justify-content: flex-end; gap: 10px">
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="projectPortfolios"
        sort-by="calories"
        class="elevation-1 mt-10 table"
        hide-default-footer
      >
        <template v-slot:item.is_active="{ item }">
          <span
            :style="`color: ${item.is_active === 'Aktif' ? 'green' : 'red'}`"
            >{{ item.is_active }}</span
          >
        </template>
        <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
        <template v-slot:item.actions="{ item }">
          <v-row>
            <button @click="watchItem(item)" class="me-2">
              <WatchTable height="28" width="28" />
            </button>
            <button @click="editItem(item)" class="me-2">
              <EditTable height="28" width="28" />
            </button>
            <button @click="deleteItem(item)">
              <TrashTable height="28" width="28" />
            </button>
          </v-row>
        </template>
      </v-data-table>
  
      <!-- Display when no data is available -->
      <NoDataAlert v-if="projectPortfolios.length === 0" :totalData="totalData" />
  
      <v-row justify="center" v-if="total >= 1">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getProjectPortfolios"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
  
      <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
        <b
          >{{
            Math.floor((currentPage - 1) * 10 + projectPortfolios.length)
          }}
          dari {{ totalData }}</b
        >
        project portfolio yang tertampil
      </p>
    </div>
  </template>
  
  <script>
  import Button from "../../../components/Button.vue";
  import NewModal from "@/components/NewModal.vue";
  import Loading from "@/components/ilustration/Loading.vue";
  import moment from "moment";
  import TrashTable from "@/components/icons/TrashTable.vue";
  import EditTable from "../../../components/icons/EditTable.vue";
  import WatchTable from "../../../components/icons/WatchTable.vue";
  import NoDataAlert from "@/components/NoDataAlert.vue";
  import FilterIcon from "@/components/icons/Filter.vue";
  import FilterModal from "@/components/FilterModal.vue";
  import Popup from "@/components/others/Popup.vue";

  export default {
    name: "ProjectPortfolio",
    data() {
      return {
        isModal: false,
        currentPage: 1,
        total: 0,
        perPage: 10,
        isLoading: false,
        id: 0,
        totalData: 0,
        message: "",
        isPopup: false,
        filter: {
          category: "",
          sort_by: "",
        },
        headers: [
          {
            text: "NO",
            value: "no",
            width: "1",
          },
          { text: "JUDUL", value: "title", width: "120" },
          { text: "LABEL ", value: "label", width: "190" },
          { text: "CATEGORY ", value: "category", width: "76" },
          { text: "TANGGAL DIBUAT", value: "created_at", width: "120" },
          { text: "AKSI", value: "actions", sortable: false, width: "132" },
        ],
        projectPortfolios: [],
        showFilterModal: false,
        filterModal: [
          {
            title: "Kategori",
            name: "category",
            items: [
              {
                name: "Collaboration",
                value: "COLLABORATION",
              },
              {
                name: "Training",
                value: "TRAINING",
              },
              {
                name: "Talent Service",
                value: "TALENT_SERVICE",
              },
              {
                name: "Services",
                value: "SERVICES",
              },
            ],
          },
          {
            title: "Urutkan",
            name: "sort_by",
            items: [
              {
                name: "Terlama",
                value: "asc",
              },
              {
                name: "Terbaru",
                value: "desc",
              },
            ],
          },
        ],
      };
    },
    components: {
      Button,
      Loading,
      TrashTable,
      EditTable,
      WatchTable,
      NoDataAlert,
      NewModal,
      FilterIcon,
      FilterModal,
      Popup
    },
    methods: {
      applyFilters() {
        this.getProjectPortfolios();
        this.showFilterModal = false;
      },
      toggleFilterModal() {
        this.showFilterModal = !this.showFilterModal;
      },
      updateFilters(value) {
        this.filter[value.name] = value.value;
      },
      async getProjectPortfolios() {
        this.projectPortfolios = [];
        await this.$crm_http
          .get(
            `/v1/project-portfolio?page=${this.currentPage}&per_page=${
              this.perPage
            }&sort_by=${this.filter.sort_by}&category=${
              this.filter.category || ""
            }`
          )
          .then((response) => {
            let i = 1;
            response.data.data.project_portfolios.forEach((element) => {
              this.projectPortfolios.push({
                no: i,
                id: element.id,
                title: element.title,
                label: element.label,
                category: this.capitalizeText(element.category),
                created_at: this.format_date(element.created_at),
              });
              const t = parseInt(response.data.data.total);
              this.total = Math.ceil(t / 10);
              this.totalData = response.data.data.total;
              i++;
            });
            console.log(this.currentPage);
            console.log(this.total);
            console.log(this.perPage);
          })
          .catch((err) => {
            this.message = err.response.data.data
            this.isPopup = true
          });
      },
      pageNo(item) {
        if (item) {
          return item.no;
        }
        return;
      },
      capitalizeText(text) {
        if (!text) return "";
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      },
      watchItem(item) {
        this.$router.push("/project-portfolio/watch/" + item.id);
      },
      editItem(item) {
        this.$router.push("/project-portfolio/edit/" + item.id);
      },
      deleteItem(item) {
        this.isModal = true;
        this.id = item.id;
      },
      async onDelete() {
        this.isLoading = true;
        try {
          await this.$crm_http.delete("/v1/project-portfolio/" + this.id);
          this.isModal = false;
          this.getProjectPortfolios();
          this.isLoading = false;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true 
          this.isLoading = false;
        }
      },
      format_date(value) {
        if (value) {
          return moment(String(value)).format("YYYY-MM-DD");
        }
      },
      clearFilter() {
        this.filter = {
          category: "",
          sort_by: "",
        };
        this.getProjectPortfolios();
      },
      handleClearButtonClick() {
        this.filter = {
          category: "",
          sort_by: "",
        };
      },
    },
    mounted() {
      this.getProjectPortfolios();
    },
  };
  </script>
  
  <style scoped>
  .action-head {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-title {
    font-weight: bold;
    font-size: 19px;
  }
  .f-search {
    border: 1px solid #d3d6e1;
    width: 350px;
    height: 45px;
    gap: 10px;
    display: flex;
    border-radius: 14px;
  }
  .f-search-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin-left: 10px;
  }
  .f-input {
    width: 86%;
    background: transparent;
    border: none;
  }
  .f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
  }
  .top-container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .count-data {
    position: relative;
    top: -60px;
    width: 30%;
  }
  
  .no-pagination {
    margin-top: 20px;
    margin-left: 5px;
  }
  </style>