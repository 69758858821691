<template>
    <div>
        <!-- <div class="back-icon" @click="$router.go(-1)">
          <round-arrow-back />
        </div> -->
        <router-view />
    </div>
</template>

<script>
// import RoundArrowBack from "@/components/icons/RoundArrowBack.vue";
export default {
    name: "FormTemplate",
    // components: {RoundArrowBack}
}
</script>

<style scoped>
.back-icon {
  position: fixed;
  z-index: 999;
  top: 30px;
  left: 30px;
  cursor: pointer;
  background-color: white;
  color: rgb(198, 198, 198);
}
</style>