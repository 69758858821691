<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="6" fill="#FFEBB9" />
    <path
      d="M15.5 13C15.5 14.3807 14.3807 15.5 13 15.5C11.6193 15.5 10.5 14.3807 10.5 13C10.5 11.6193 11.6193 10.5 13 10.5C14.3807 10.5 15.5 11.6193 15.5 13Z"
      fill="#F3BF39"
    />
    <path
      d="M5 13C5 13 8 7.5 13 7.5C18 7.5 21 13 21 13C21 13 18 18.5 13 18.5C8 18.5 5 13 5 13ZM13 16.5C14.933 16.5 16.5 14.933 16.5 13C16.5 11.067 14.933 9.5 13 9.5C11.067 9.5 9.5 11.067 9.5 13C9.5 14.933 11.067 16.5 13 16.5Z"
      fill="#F3BF39"
    />
  </svg>
</template>

<script>
export default {
  name: "WatchTable",
  props: {
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
};
</script>
