<template>
    <span :class="`chip ${active ? 'active' : 'non-active'}`">{{ active ? 'Aktif' : 'Tidak Aktif' }}</span>
</template>
<script>
export default {
    name: "ChipComponent",
    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
.chip {
    padding: 5px 20px;
    border-radius: 24px;
    font-size: 15px;
    font-weight: bolder;
}
.non-active {
    background-color: #F7E7E6;
    color: #B80F0A;
}
.active {
    background-color: #E7E9F5;
    color: #162CA2;
}
</style>