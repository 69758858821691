<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
        {{ name }}
      </h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Kategori</p>
          <v-select
            :disabled="true"
            class="form-input"
            :items="listCategory"
            v-model="survey.category"
            solo
            :item-text="'name'"
            :item-value="'value'"
            placeholder="Pilih Kategori"
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Nama Kelas</p>
          <v-text-field
            outlined
            readonly
            class="form-input mt-2"
            v-model="dataClass.name"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Nama Reviewer</p>
          <v-text-field
            outlined
            readonly
            class="form-input mt-2"
            v-model="dataUser.full_name"
            dense
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Jadwal kelas Kelas</p>
          <v-text-field
            outlined
            readonly
            class="form-input mt-2"
            v-model="survey.date"
            dense
          ></v-text-field>
        </div>
        <h3 class="mb-4">Jawaban Peserta</h3>
        <div class="content-form mb-6" v-for="(item, idx) in survey.answers" :key="idx">
          <p>{{ item.question }}</p>
          <div class="watch-ctn">
            <span>{{ item.answer }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormSurvey",
  data() {
    return {
      id: null,
      name: "",
      message: "",
      isPopup: false,
      isLoading: false,
      survey: {answers:[]},
      dataClass: {},
      datauser: {},
      listCategory: [
        {name: "Kampus Merdeka", value: "KAMPUS_MERDEKA"},      
        {name: "Prakerja", value: "PRAKERJA"},      
      ],
    };
  },
  components: {
    Loading,
    Popup
  },
  methods: {
    async getDataSurvey() {
      this.name = this.$route.meta.name
      this.isLoading = true;
      try {
        const resp = await this.$web_http.get("/v1/survey/" + this.$route.params.id);
        this.survey = resp.data.data.survey;
        this.getUserId(this.survey.user_id)
        this.getClassId(this.survey.class_id)
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        
        this.isLoading = false;
      }
    },
    async getUserId(id) {
      try {
        const response = await this.$crm_http.get("/v1/user/" + id);
        this.dataUser = response.data.data.user;
      } catch (err) {
        this.dataUser =  {
          full_name: ""
        }
      }
    },
    async getClassId(id) {
      try {
        const resp = await this.$crm_http.get("/v1/class/" + id);
        this.dataClass = resp.data.data.class;
      } catch (err) {
        this.dataClass = {
          name: ""
        }
      }
    },
  },
  mounted() {
    this.getDataSurvey();
  },
};
</script>
<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.no-space {
  margin-bottom: -20px;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  color: #7b7e8c;
}
.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}
.icon-plus {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
}
.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}
.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}
.label-info {
  color: gray;
  font-weight: normal;
  font-size: 15px;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
