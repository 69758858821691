<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus voucher ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <div class="action-head">
      <h1>Voucher</h1>
      <Button
        icon="+"
        outline
        name="Create Voucher"
        @click="() => $router.push('/voucher/new')"
        style="background-color: #3b54d9; color: #ffffff; width: 190px"
      />
    </div>

    <div class="container-box mt-10 filter-box">
      <div class="d-flex flex-row">
        <v-file-input
          v-model="file"
          show-size
          label="File input"
          class="mr-5"
        ></v-file-input>
        <v-btn
          :disabled="!file"
          color="success"
          @click="importVoucher"
          class="ml-5 mt-3"
          >Submit</v-btn
        >
      </div>
    </div>

    <div class="container-box mt-10 filter-box">
      <v-row>
        <v-col>
          <span>Kode Voucher</span>
          <v-text-field
            class="mt-2"
            outlined
            v-model="filter.voucherCode"
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <span>Type</span>
          <v-select
            class="mt-2"
            :items="voucher_type"
            :item-text="'name'"
            :item-value="'value'"
            v-model="filter.isActive"
            solo
          >
          </v-select>
        </v-col>
        <v-col>
          <p>Tanggal mulai</p>
          <v-text-field
            outlined
            v-model="filter.startDate"
            type="date"
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <p>Tanggal selesai</p>
          <v-text-field
            outlined
            v-model="filter.endDate"
            type="date"
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="btn-filter">
          <Button v-if="activeBtn" name="clear" outline @click="clearFilter" />
          <Button name="Apply" @click="getVouchers" />
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :headers="headers"
      :items="vouchers"
      class="elevation-1 mt-10 table"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <v-chip :color="item.is_active ? 'green' : 'red'" dark>
          {{ item.is_active ? "Aktif" : "Non Aktif" }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <div class="data-count" v-if="total">
      <span
        ><b
          >{{ pageNo(vouchers[vouchers.length - 1]) || 0 }} dari {{ total }} </b
        >data yang tertampil</span
      >
    </div>
    <v-row justify="center">
      <v-col cols="4">
        <v-container class="max-width" v-if="total">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            total-visible="8"
            :length="totalPage"
            @input="getVouchers"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import getAuthentification from "@/utils/badrequest.js";
import Button from "../../../components/Button.vue";
import NewModal from "@/components/NewModal.vue";
import Loading from "@/components/ilustration/Loading.vue";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      search: "",
      value: "voucher_code",
      file: [],
      isModal: false,
      currentPage: 1,
      perPage: 15,
      totalPage: 0,
      isLoading: false,
      id: 0,
      total: 0,
      dialog: false,
      dialogDelete: false,
      message: "",
      isPopup: false,
      filter: {
        isActive: "",
        startDate: "",
        endDate: "",
        voucherCode: "",
      },
      headers: [
        {
          text: "NO",
          value: "no",
        },
        {
          text: "ID",
          value: "id",
        },
        { text: "NAME", value: "name", width: "350" },
        { text: "KODE VOUCHER", value: "voucher_code", sortable: false },
        { text: "NOMINAL DISKON", value: "discount", sortable: false },
        { text: "DURASI", value: "duration", sortable: false, width: "215px"},
        { text: "ACTIVE", value: "is_active", sortable: false },
        { text: "STOCK", value: "stock", sortable: false },
        { text: "Partner Code", value: "partner_code" },
        { text: "Actions", value: "actions", sortable: false, width: "132px" },
      ],
      voucher_type: [
        {
          name: "Semua",
          value: "",
        },
        {
          name: "Aktif",
          value: "true",
        },
        {
          name: "Non Aktif",
          value: "false",
        },
      ],
      vouchers: [],
    };
  },
  components: {
    NewModal,
    Loading,
    Button,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    Popup
  },
  computed: {
    activeBtn() {
      return (
        this.filter.isActive ||
        this.filter.startDate ||
        this.filter.endDate ||
        this.filter.voucherCode
      );
    },
  },
  methods: {
    async uploadImage() {
      // this.spin_load.val = true
      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      formData.append("file", this.file);

      await this.$http
        .post("sgp1.digitaloceanspaces.com", {
          image: event,
        })
        .then((response) => {
          console.log("aaa : ", response);
        })
        .catch((err) => {
          this.message = err.response.data.data
        this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    clearFilter() {
      this.filter = {
        isActive: "",
        startDate: "",
        endDate: "",
        voucherCode: "",
      };
      this.getVouchers();
    },
    async getVouchers() {
      // this.spin_load.val = true
      await this.$crm_http
        .get(
          `/v1/voucher?is_active=${this.filter.isActive}&start_date=${
            this.filter.startDate ? this.filter.startDate : ""
          }&end_date=${
            this.filter.endDate ? this.filter.endDate : ""
          }&voucher_code=${this.filter.voucherCode}&page=${
            this.currentPage
          }&per_page=${this.perPage}`
        )
        .then((response) => {
          let no = (this.currentPage - 1) * this.perPage + 1;

          this.vouchers = [];
          const t = parseInt(response.data.data.total);

          this.total = t;
          this.totalPage = Math.ceil(t / this.perPage);

          response.data.data.vouchers.forEach((item) => {
            this.vouchers.push({
              ...item,
              no: no++,
              discount: this.toCurrency(item.discount),
              duration:
                this.formatDateSecond(item.start_date) +
                " - " +
                this.formatDateSecond(item.end_date),
            });
          });

          if (this.currentPage > this.totalPage) {
            this.currentPage = this.totalPage;
            this.getVouchers();
          }
        })
        .catch((err) => {
          this.message = err.response.data.data
        this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    pageNo(item) {
      if (item) {
        return item.no;
      }
      return;
    },
    watchItem(item) {
      this.$router.push("/voucher/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/voucher/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      try {
        await this.$crm_http.delete("/v1/voucher/" + this.id);
        this.isModal = false;
        this.getVouchers();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async importVoucher() {
      // this.spin_load.val = true
      let formData = new FormData();
      // files
      formData.append("file", this.file);
      try {
        await this.$web_http.post("/v1/voucher/import", formData);
        alert("Data berhasil di import");
        this.getVouchers();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }

      // .finally(() => this.spin_load.val = false)
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
      });
      return formatter.format(value);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    formatDateSecond(value) {
      return moment(String(value)).format("DD MMM YYYY");
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getVouchers();
  },
};
</script>

<style scoped>
.action-head {
  margin: 40px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-filter {
  display: flex;
  justify-content: flex-end;
  margin-top: 37px;
  gap: 10px;
}

.data-count {
  height: 0;
  position: relative;
  overflow-y: visible;
  display: flex;
}

.data-count span {
  position: absolute;
  font-size: medium;
  top: 35px;
}
</style>
