<template>
    
<svg  :width="width"
    :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 8C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9L17 18.9V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V18.9L6 9C6 8.73478 6.10536 8.48043 6.29289 8.29289C6.48043 8.10536 6.73478 8 7 8H17ZM17 7H7C6.46957 7 5.96086 7.21071 5.58579 7.58579C5.21071 7.96086 5 8.46957 5 9L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 9C19 8.46957 18.7893 7.96086 18.4142 7.58579C18.0391 7.21071 17.5304 7 17 7Z" fill="currentColor"/>
<path d="M20 4.5H15.93C15.8194 4.07176 15.57 3.69225 15.2208 3.42084C14.8716 3.14944 14.4423 3.00144 14 3H10C9.55772 3.00144 9.12839 3.14944 8.77918 3.42084C8.42997 3.69225 8.18057 4.07176 8.07 4.5H4C3.86739 4.5 3.74021 4.55268 3.64645 4.64645C3.55268 4.74021 3.5 4.86739 3.5 5C3.5 5.13261 3.55268 5.25979 3.64645 5.35355C3.74021 5.44732 3.86739 5.5 4 5.5H20C20.1326 5.5 20.2598 5.44732 20.3536 5.35355C20.4473 5.25979 20.5 5.13261 20.5 5C20.5 4.86739 20.4473 4.74021 20.3536 4.64645C20.2598 4.55268 20.1326 4.5 20 4.5Z" fill="currentColor"/>
</svg>
</template>
<script>
export default {
  name: "TrashIcon",
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
};
</script>
