<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      :isError="isError"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div style="display: flex; justify-content: space-between">
      <div>
        <div class="head-ctn">
          <h1>{{ dataClass.name }}</h1>
        </div>
        <div class="d-flex mt-3" style="gap: 20px">
          <span>Hari: {{ dataClass.days }}</span>
          <span>Jam: {{ dataClass.hours }}</span>
          <span>Total quiz: {{ dataClass.total_quiz }}</span>
        </div>
      </div>
      <h3 style="cursor: pointer" @click="$router.go(-1)">Kembali</h3>
    </div>
    <hr class="line-strip my-6" />
    <div class="container-box mb-4" v-if="mappingExcelData.length">
      <v-row>
        <v-col>
          <vue-excel-xlsx
            class="button-3"
            :data="mappingExcelData"
            :columns="headerExcelData"
            :file-name="filename"
            :file-type="'xlsx'"
          >
            Download Data
          </vue-excel-xlsx>
        </v-col>

        <v-col>
          <div class="d-flex flex-row">
            <v-file-input
              v-model="fileAssessment"
              show-size
              accept=".csv"
              label="Masukan nilai (.csv)"
              class="mr-5"
            ></v-file-input>

            <v-btn
              color="success"
              @click="importAssessmentReview"
              class="ml-5 mt-3"
              >Submit</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="container-box">
      <v-row>
        <v-col cols="4">
          Pilih durasi tanggal kelas
          <v-select
            style="width: 90% !important; margin-top: 5px"
            v-model="filter.dates"
            :items="sheduleDateOption"
            placeholder="Pilih durasi"
            :item-text="'dates'"
            :item-value="'dates'"
            class="form-input"
            solo
          ></v-select>
        </v-col>
        <v-col>
          Status
          <v-select
            style="width: 70%; margin-top: 5px"
            v-model="filter.is_complete"
            class="form-input"
            :items="status"
            :item-text="'name'"
            :item-value="'value'"
            solo
          ></v-select>
        </v-col>
        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 25px;
          "
        >
          <Button name="RESET FILTER" outline @click="clearFilter" />
          <Button name="TERAPKAN FILTER" @click="onFilter" width="190px" />
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :headers="headers"
      :items="assesments"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.is_completed="{ item }">
        <span :style="`color: ${item.is_completed ? 'green' : 'red'}`">{{
          item.is_completed ? "Done" : "In progress"
        }}</span>
      </template>
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            total-visible="8"
            :length="totalPage"
            @input="getAssessment"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import Popup from "@/components/others/Popup.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import WatchTable from "@/components/icons/WatchTable.vue";

export default {
  data() {
    return {
      isLoading: false,
      isFilterActive: false,
      total: 0,
      perPage: 10,
      totalPage: 0,
      currentPage: 1,
      filename: "Assessment",
      fileAssessment: null,
      dataClass: {},
      classId: this.$route.params.class_id,
      message: "",
      isError: false,
      isPopup: false,
      filter: {
        dates: "",
        is_complete: "",
      },
      headerExcelData: [
        {
          label: "Nama Kelas",
          field: "nama_kelas",
        },
        {
          label: "Jadwal",
          field: "jadwal",
        },
        {
          label: "Nama",
          field: "nama",
        },

        {
          label: "Redeem Code",
          field: "redeem_code",
        },
        {
          label: "Assessment ID",
          field: "assessment_id",
        },
        {
          label: "Key",
          field: "key",
        },
        {
          label: "Sequence",
          field: "sequence",
        },
        {
          label: "Score",
          field: "score",
        },
        {
          label: "Note",
          field: "note",
        },
        {
          label: "Task",
          field: "task",
        },
        {
          label: "Status File",
          field: "status",
        },
      ],
      mappingExcelData: [],
      sheduleDateOption: [],
      status: [
        {
          name: "All",
          value: "",
        },
        {
          name: "Done",
          value: "true",
        },
        {
          name: "In progress",
          value: "false",
        },
      ],
      headers: [
        { text: "NO", value: "id" },
        { text: "Name", value: "user_name", width: "250" },
        { text: "Pre Test Score", value: "pre_test_score" },
        { text: "Quiz Score", value: "quiz_score" },
        { text: "Tpm Score", value: "tpm_score" },
        { text: "Post Test Score", value: "post_test_score" },
        { text: "Uji Keterampian", value: "final_project_score" },
        { text: "Status", value: "is_completed" },
        { text: "Actions", value: "actions" },
      ],
      assesments: [],
    };
  },
  components: {
    Button,
    Loading,
    Popup,
    NoDataAlert,
    WatchTable,
  },
  methods: {
    async getAssessment() {
      this.isLoading = true;
      try {
        this.assesments = [];
        const assesmentRes = await this.$lms_http.get(
          `/v1/user-assesment/mentor/${this.classId}?date=${this.filter.dates}&is_complete=${this.filter.is_complete}&page=${this.currentPage}&per_page=${this.perPage}`
        );
        const resp = assesmentRes.data.data;
        for (let i = 0; i < resp.assesments.length; i++) {
          const item = resp.assesments[i];
          await this.getUser(item.user_id)
            .then((res) => {
              this.assesments.push({
                ...item,
                user_name: res.full_name,
              });
            })
            .catch(() => {
              this.assesments.push({
                ...item,
                user_name: "",
              });
            });
        }
        const t = parseInt(resp.total);
        this.total = t;
        this.totalPage = Math.ceil(t / this.perPage);
        if (this.currentPage > this.totalPage) {
          this.currentPage = this.totalPage;
          this.getAssessment();
        }
        this.mappingDataExemappingExcelDatal();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.message = err.response.data.data;
        this.isError = true;
        this.isPopup = true;
      }
    },
    async getUser(id) {
      try {
        const response = await this.$crm_http.get("/v1/user/" + id);
        return response.data.data.user;
      } catch (err) {
        this.message = err.response.data.data;
        this.isError = true;
        this.isPopup = true;
      }
    },
    async getClassId() {
      try {
        const resp = await this.$lms_http.get("/v1/class/" + this.classId);
        this.dataClass = resp.data.data.class;
      } catch (err) {
        this.message = err.response.data.data;
        this.isError = true;
        this.isPopup = true;
      }
    },
    async getScheduleDate() {
      try {
        const resp = await this.$lms_http.get(
          "/v1/user-schedule/option-date?class_id=" + this.classId
        );
        this.sheduleDateOption = resp.data.data.schedule_option;
      } catch (err) {
        this.message = err.response.data.data;
        this.isError = true;
        this.isPopup = true;
      }
    },
    mappingDataExemappingExcelDatal() {
      this.mappingExcelData = [];
      for (let i = 0; i < this.assesments.length; i++) {
        const item = this.assesments[i];
        this.mappingExcelData.push({
          key: "UK",
          score: item.final_project_score,
          note: item.notes,
          assessment_id: item.id,
          redeem_code: item.redeem_code,
          nama: item.name,
          nama_kelas: item.class_name,
          jadwal: item.date,
          sequence: 0,
          task: item.final_project_url,
          status: item.status_file,
        });
        item.tpms.forEach((element) => {
          this.mappingExcelData.push({
            key: "TPM",
            score: element.score,
            note: element.note,
            assessment_id: item.id,
            redeem_code: item.redeem_code,
            nama: item.name,
            nama_kelas: item.class_name,
            jadwal: item.date,
            sequence: element.iteration,
            task: element.attachment_url,
            status: element.status,
          });
        });
      }
    },
    async importAssessmentReview() {
      this.isLoading = true;
      try {
        var formData = new FormData();
        formData.append("file", this.fileAssessment);
        await this.$lms_http.post(
          "/v1/import-assessment-review/tpm-uk",
          formData
        );
        this.isLoading = false;
        this.message = "Data berhasil di unggah";
        this.isError = false;
        this.isPopup = true;
        this.fileAssessment = null;
        this.getAssessment();
      } catch (err) {
        this.message = err.response.data.data;
        this.isError = true;
        this.isPopup = true;
        this.isLoading = false;
      }
    },
    clearFilter() {
      this.filter = {
        dates: "",
        is_complete: "",
      };
      this.getAssessment();
    },
    watchItem(item) {
      this.$router.push(
        `/assessment-review/${item.id}/detail/${item.class_id}`
      );
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    onFilter() {
      this.currentPage = 1;
      this.isFilterActive = true;
      this.getAssessment();
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getClassId();
    this.getScheduleDate();
    this.getAssessment();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}

/* CSS */
.button-3 {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 50%;
  height: 50px;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}
</style>
